import '../App.css'

import { Routes, Route } from 'react-router-dom';

import Edit from './Edit'
import Home from './Home'
import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import ShareBillPage from './ShareBillPage';
import { V2Example } from './shopping_v2/V2Example';
import { PayV2 } from './PayV2';

declare global {
    interface Window { recaptchaVerifier: RecaptchaVerifier; confirmationResult: ConfirmationResult }
}

const Main = () => {

    return (
        <Routes> {/* The Switch decides which component to show based on the current URL.*/}
            <Route path='/' element={<Home />}></Route> {/* Homepage */}
            <Route path='/edit' element={<Edit />}></Route> {/* Upload/verify/share bill */}
            <Route path='/share' element={<ShareBillPage />}></Route> {/* Upload/verify/share bill */}
            <Route path='/payv2/:billId' element={<PayV2 />} ></Route> {/* to V2 pay page*/}
            <Route path='/sharev2' element={<V2Example />} ></Route> {/* to V2 pay page*/}

        </Routes >
    );
}

export default Main;