import { SxProps, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

// TODO: Refactor this into some style constant file
const INPUT_BACKGROUND_COLOR = '#f7f7f7';

export function NumDinersInput(props: { numDiners: number | undefined, setNumDiners: (numDiners: React.SetStateAction<number | undefined>) => void, sxProps?: SxProps, onKeyDown?: (e: React.KeyboardEvent) => void }): [React.ReactNode, React.ReactNode] {
    const [value, setValue] = useState(String(props.numDiners));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        props.setNumDiners(Number(event.target.value));
    };

    return [
        <Typography>Number of diners</Typography>
        ,
        <div>
            <TextField
                size='small'
                sx={{ backgroundColor: INPUT_BACKGROUND_COLOR }}
                value={value}
                error={value === '' || value === undefined || value === null || Number(value) < 1 || Number(value) > 100}
                onChange={handleChange}
                onKeyDown={props.onKeyDown}
                type="number"
                inputProps={{
                    min: 1,
                    max: 100,
                    step: 1,
                    style: { textAlign: 'right' },
                }}
            />
        </div>
    ];
}

export function StatefulNumDiners(props: { defaultNumDiners: number | undefined, persistNumDiners: (numDiners: number | undefined) => void, sxProps?: SxProps, onKeyDown?: (e: React.KeyboardEvent) => void }) {
    const [numDiners, setNumDiners] = useState(props.defaultNumDiners);
    useEffect(() => {
        props.persistNumDiners(numDiners);
    }, [numDiners]);
    return NumDinersInput({ numDiners, setNumDiners, sxProps: props.sxProps, onKeyDown: props.onKeyDown });
}
