const colorOptions = {
    cadet_blue: '#729EA1',
    sage: '#B5BD89',
    light_coral: '#EC9192',
    tan: '#DFBE99',
    mint: '#A1E8CC',
    max_blue_purple: '#BEB7DF',
    glossy_grape: '#AE8CA3',
    brick_red: '#C24253',
}


const colors = Object.values(colorOptions);

// Colorizes avatar characters
export function colorize(aviChars: string) {
    aviChars = aviChars.toUpperCase().replace(/[^A-Z]/gi, '').substring(0, 5);
    let modArraySum = 0;
    for (const char of aviChars) {
        modArraySum = (modArraySum + char.charCodeAt(0)) % colors.length
    }
    return colors[modArraySum];
}

export function colorize_infinite(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}