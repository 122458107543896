import { Box, Divider, SxProps, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fmtPriceForDisplay } from "../../business_component_shared/helpers";
import { PresentedBillV2, PresentedBillV2Item } from "../../business_component_shared/Types";
import { birthdayTotal, cartSubtotal, cartTaxOrTipAmount, cartTotal, everyoneElseSubtotal, everyoneElseTaxOrTipAmount, everyoneElseTotal, ItemsView } from "../../business_logic/pay_bill_v2_actions";
import BirthdayModeToggle from "./BirthdayModeToggle";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export function Header(props: { bill: PresentedBillV2, sx?: SxProps }) {
    const restaurantName = props.bill.restaurantName ?? 'Banana Split'
    const date = new Date(props.bill.timestamp).toLocaleString();

    return <Grid container xs={12} alignItems={'center'}>
        <Grid xs={2}>
            <></>
        </Grid>
        <Grid xs>
            <Box flexDirection={'column'} alignItems='center' justifyContent='center' textAlign={'center'} paddingBottom={1} sx={props.sx}>
                <Typography>{restaurantName}</Typography>
                <Typography>{new Date(date).toLocaleDateString()}</Typography>
                <Typography>Cardholder: {props.bill.ownerUid ?? 'You!'}</Typography>
                <Typography>Number of diners: {props.bill.numDiners ?? 'undefined'}</Typography>
            </Box>
        </Grid>
        <Grid xs={2}>
        </Grid>
    </Grid>
}

export function DashedDivider(props: { sx?: SxProps }) {
    return <Grid xs={12} sx={props.sx}>
        <Divider sx={{ borderBottomWidth: 0.5, borderStyle: 'dashed', borderColor: 'black' }} />
    </Grid>;
}

export function DashedDivierWithMargin(props: { sx?: SxProps }) {
    return (
        <Grid xs={12} sx={props.sx}>
            <Divider sx={{ marginX: 2, marginTop: 1, borderBottomWidth: 0.5, borderStyle: 'dashed', borderColor: 'black' }} />
        </Grid>
    )
}

export function BillSubtitleText(props: { children: string | number }) {
    return <Box sx={{ fontWeight: '500' }}>
        <Typography fontWeight={'600'} fontSize={14}>{props.children}</Typography>
    </Box >
}

export function __Deprecated__SolidDividerForSmallThreeColRow() {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
        >
            <Grid xs={11} sm={9}>
                <Divider sx={{ marginX: 2, marginTop: 1, borderBottomWidth: 0.5, borderStyle: 'solid', borderColor: 'black' }} />
            </Grid>
        </Grid>
    )
}

export function __Deprecated__DashedDividerForSmallThreeColRow() {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
        >
            <Grid xs={11} sm={9}>
                <DashedDivierWithMargin></DashedDivierWithMargin>
            </Grid>
        </Grid>
    )
}


/**
 * e.g. tax / tip / total
 */
export function __Deprecated__SmallThreeColRow<T>(props: { name: string, value: T, formatValue: (a: T) => string, underline?: boolean, sx?: SxProps }) {
    return (
        <Grid
            container
            xs={12}
            sx={props.sx}
            style={{ minHeight: '30px' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid xs={4} sm={3}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'left'
                }}>
                    <Typography sx={{ textDecoration: props.underline ? 'underline' : 'none' }}>{props.name.toUpperCase()}</Typography>
                </div>
            </Grid>
            <Grid xs={1} sm={1}>{' '}</Grid>
            <Grid xs={4} sm={3}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right'
                }}>

                    <Typography>{props.formatValue(props.value)}</Typography>
                </div>

            </Grid>
        </Grid>
    )
}


export type AggregatesData = {
    subtotal: number,
    taxAmount: number,
    tipAmount: number,
    total: number,
    birthdayAmount: number,
}
function cartAggregates(bill: PresentedBillV2, cartItems: ItemsView): AggregatesData {
    return {
        subtotal: cartSubtotal(bill, cartItems),
        taxAmount: cartTaxOrTipAmount(bill, cartItems, 'tax'),
        tipAmount: cartTaxOrTipAmount(bill, cartItems, 'tip'),
        total: cartTotal(bill, cartItems),
        birthdayAmount: birthdayTotal(bill, true),
    }
}

function everyoneElseAggregates(bill: PresentedBillV2, everyoneElseItems: ItemsView): AggregatesData {
    return {
        subtotal: everyoneElseSubtotal(bill, everyoneElseItems),
        taxAmount: everyoneElseTaxOrTipAmount(bill, everyoneElseItems, 'tax'),
        tipAmount: everyoneElseTaxOrTipAmount(bill, everyoneElseItems, 'tip'),
        total: everyoneElseTotal(bill, everyoneElseItems), // there's some bug here lol
        birthdayAmount: birthdayTotal(bill, false),
    }
}

export function Aggregates(props: { bill: PresentedBillV2, itemsView: ItemsView, type: 'notcart' | 'cart', solidDividers?: boolean }) {
    // const [aggregates, setAggregates] = useState<AggregatesData>(everyoneElseAggregates(props.bill, props.itemsView));

    const [aggregates, setAggregates] = useState<AggregatesData>();
    const _type = props.type;

    useEffect(() => {
        switch (_type) {
            case 'notcart':
                setAggregates(everyoneElseAggregates(props.bill, props.itemsView));
                break;
            case 'cart':
                setAggregates(cartAggregates(props.bill, props.itemsView));
                break;
            default:
                throw Error('Unknown type for aggregates display ' + props.type);
        }
    }, [_type]);

    if (!aggregates) {
        return <></>
    }

    return <React.Fragment>
        {!(props.solidDividers) && <__Deprecated__DashedDividerForSmallThreeColRow />}
        {(props.solidDividers) && <__Deprecated__SolidDividerForSmallThreeColRow />}
        <__Deprecated__SmallThreeColRow name='Subtotal' value={aggregates.subtotal} formatValue={(x) => '$' + fmtPriceForDisplay(x)}></__Deprecated__SmallThreeColRow>
        <__Deprecated__SmallThreeColRow name='Tax amount' value={aggregates.taxAmount} formatValue={(x) => '$' + fmtPriceForDisplay(x)}></__Deprecated__SmallThreeColRow>
        <__Deprecated__SmallThreeColRow name='Tip amount' value={aggregates.tipAmount} formatValue={(x) => '$' + fmtPriceForDisplay(x)}></__Deprecated__SmallThreeColRow>

        {!(props.solidDividers) && <__Deprecated__DashedDividerForSmallThreeColRow />}
        {(props.solidDividers) && <__Deprecated__SolidDividerForSmallThreeColRow />}

        {
            aggregates.birthdayAmount > 0 && <>
                <__Deprecated__SmallThreeColRow name='Birthday contribution' value={aggregates.birthdayAmount} formatValue={(x) => '$' + fmtPriceForDisplay(x)}></__Deprecated__SmallThreeColRow>


                {!(props.solidDividers) && <__Deprecated__DashedDividerForSmallThreeColRow />}
                {(props.solidDividers) && <__Deprecated__SolidDividerForSmallThreeColRow />}
            </>
        }


        <__Deprecated__SmallThreeColRow name='Total' value={aggregates.tipAmount + aggregates.subtotal + aggregates.taxAmount + aggregates.birthdayAmount} formatValue={(x) => '$' + fmtPriceForDisplay(x)}></__Deprecated__SmallThreeColRow>
    </React.Fragment>
}