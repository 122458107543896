export function as<T>(a: T) { return a; }

export function mergeMaps<T>(maps: { [x: string]: T; }[]): { [x: string]: T } {
    let res: { [x: string]: T } = {};
    for (const map of maps) {
        const [x, v] = Object.entries(map)[0];
        res[x] = v;
    }
    return res;
}


/**
 * Copy an index signature (basically a key-value mapping) to a new index signature with the same keys and values 
 */
export function copyIndexSignature<T>(a: { [key: string]: T }): { [key: string]: T } {
    const b: { [key: string]: T } = {};
    for (const [key, val] of Object.entries(a)) {
        b[key] = val;
    }
    return b;
}

/**
 * DOES NOT WORK WITH FUNCTIONS
 */
export function deepCopy<T>(obj: T): T {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy as T;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy as T;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            // @ts-expect-error
            if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy as T;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function unpartial<T>(a: Partial<T>, fieldsOfA: (keyof T)[]): T | { errorMsg: string, fieldUndefined: keyof T } {
    for (const field of fieldsOfA) {
        if (!(field in a) || a[field] === undefined) {
            return { errorMsg: 'One or more fields are undefined', fieldUndefined: field };
        }
    }
    return a as T;
}