// Generic helpers that are useful across the codebase
import { useState, useEffect } from 'react';

/**
 * Copy an index signature (basically a key-value mapping) to a new index signature with the same keys and values 
 */
export function copyIndexSignature<T>(a: { [key: string]: T }): { [key: string]: T } {
    const b: { [key: string]: T } = {};
    for (const [key, val] of Object.entries(a)) {
        b[key] = val;
    }
    return b;
}

export function fmtPriceForDisplay(price: number) {
    return price.toFixed(2);
}

export function fmtDecimalToPercent(decimal: number) {
    return (decimal * 100).toFixed(2) + '%'
}

export function getOnKeyDownEnter<T>(executeWhenEnterPressed: () => unknown): (event: React.KeyboardEvent<T>) => void {
    return (event: React.KeyboardEvent<T>) => {
        if (event.key === 'Enter') {
            executeWhenEnterPressed();
        }
    }
}

export function validateKeyPressForMoneyFields<T>(event: React.KeyboardEvent<T>) {
    if (!(event?.key.match(/[0-9.]/))) {
        event.preventDefault();
    }
}

export function validateKeyPressForPositiveQuantityFields<T>(event: React.KeyboardEvent<T>) {
    if (!(event?.key.match(/[0-9]/))) {
        event.preventDefault();
    }
}

export function displayNameToAvatar(displayName: string | null) {
    if (!displayName) return null;
    return displayName?.split(' ').map(x => x.substring(0, 1)).join('').toUpperCase();
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


export function toFraction(value: number, _maxdenom: number) {
    let maxdenom = Math.min(_maxdenom, 99)
    let best = { numerator: 1, denominator: 1, error: Math.abs(value - 1) }
    for (let denominator = 1; best.error > 0 && denominator <= maxdenom; denominator++) {
        let numerator = Math.round(value * denominator);
        let error = Math.abs(value - numerator / denominator);
        if (error >= best.error) continue;
        best.numerator = numerator;
        best.denominator = denominator;
        best.error = error;
    }
    return best;
}
