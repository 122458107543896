import { uuidv4 } from '@firebase/util';
import { PlaylistAdd, HideImage, Image as ShowImage } from '@mui/icons-material';
import { Box, Button, Divider, Fab, Switch, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { EditableItem, INPUT_BACKGROUND_COLOR, isLineItemInvalid } from './EditableBillItem';
import useWindowDimensions, { validateKeyPressForMoneyFields, copyIndexSignature } from '../business_component_shared/helpers';
import { BillEditable } from '../business_component_shared/Types';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { compressUrlState } from '../business_logic/backend/url_state';
import { EditableTaxTipAmount } from './editbill/EditableTipTaxAmount';
import { SubtotalChecksum, TotalChecksum } from './editbill/Checksum';
import { DashedDivider } from './PayBillComponents';
import { ABArrangementBase } from './grid/GridArrangement';
import { ChooseAppsStage } from './editbill/ChooseAppsStep';
import { EditableBillBase } from './editbill/EditableBillBase';
import { QuestionAnswers } from './uploadbill/LoadingQuestions';

function Image(props: { image: File | undefined }) {
    const [imageHidden, setImageHidden] = useState(true);
    const { image } = props;
    const { width } = useWindowDimensions();
    function getHeight() { return !imageHidden ? '250px' : '25px'; }

    return <>{image && <Box
        width='100%'
        position='fixed'
        top='10px'
        left='0%'
        zIndex={102}
    >
        <Box
            marginX='10%'
        >
            <Box
                // display={'flex'} justifyContent={'center'}
                sx={{
                    height: getHeight(),
                    overflow: !imageHidden ? 'scroll' : 'hidden',
                    borderRadius: 4,
                    zIndex: 99,
                    backgroundColor: imageHidden ? '#aaaaaa' : '#dddddd',
                    boxShadow: '2px 2px 5px #333333',
                }}
            >
                <Box
                    sx={{
                        opacity: imageHidden ? '0.5' : '1',
                        marginY: '4px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {
                        image && <img object-fit="scale-down"
                            draggable={false}
                            style={{ maxWidth: '100%' }}
                            src={URL.createObjectURL(image)}
                            alt={'the uploaded receipt'}
                        />
                    }
                </Box>
            </Box>
            <Button
                variant='contained'
                size='small'
                // @ts-ignore
                color='neutral'
                onClick={() => {
                    setImageHidden(!imageHidden);
                }}
                sx={{
                    position: 'absolute',
                    top: '0px',
                    height: '25px',
                    left: '10%',
                    zIndex: 101,
                    borderRadius: 8,
                }}
            >
                {!imageHidden ? <HideImage fontSize='small' /> : <ShowImage fontSize='small' />}
            </Button>
        </Box>
    </Box>
    }</>
}


/**
 * Editing bill, calls `finalize()` when done
 */
export function EditBill(props: { bill: BillEditable, image: File | undefined, onShare: () => void }) {
    // const [stage, setStage] = useState<'edit' | 'chooseApps'>('edit');

    window.scrollTo(0, 0); 

    
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {/* {stage === 'edit' && */}
        <React.Fragment>
            <div id='image'>
                <Image image={props.image}></Image>
            </div>
            <EditableBillBase bill={props.bill} onDone={() => props.onShare()} />
        </React.Fragment>
        {/* } */}
        {/* {stage === 'chooseApps' && <ChooseAppsStage bill={props.bill} onReturn={() => setStage('edit')} onComplete={() => props.onShare()} />} */}
    </div >
}
