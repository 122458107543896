import { ArrowCircleUp, DotsSix, PaperPlaneRight as Send, ShoppingCart, Pencil } from 'phosphor-react';
import { Box, Button, Container, Dialog, DialogTitle, Fab, IconButton, ListSubheader, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import assert from "assert";
import React, { useEffect, useState, useRef, ReactNode, ReactElement } from "react";
import Sheet from 'react-modal-sheet';
import { Diff, PresentedBillV2, User } from "../../business_component_shared/Types";
import { addItemToBirthdayItems, cartToItemGroupIds, cartTotal, cartToUnpaid, initBirthdayItemsView, initCartItemsView, initPaidItemsView, initUnpaidItemsView, ItemsView, postRawDiffsToBillForUndo, postShoppingCartV2ToDb, quantityInCart, quantityUnpaid, removeItemFromBirthdayItems, unpaidToCart } from "../../business_logic/pay_bill_v2_actions";
import { billToPresentedBill, postShoppingCartToDb } from "../../business_logic/pay_side_actions";
import { ConfirmCart, ConfirmCartDialog, ConfirmPaid } from "./ConfirmCart";
import { BirthdayItemRow, CartItemRow, UnpaidOrPaidItemRow } from "./item/ItemRow";

import useWindowDimensions, { fmtPriceForDisplay } from '../../business_component_shared/helpers'
import { DashedDivider, SolidDivider } from '../PayBillComponents';
import { BillSubtitleText, DashedDivierWithMargin, __Deprecated__SmallThreeColRow, __Deprecated__DashedDividerForSmallThreeColRow, Header, Aggregates } from './Components';
import { SnackbarItemChange, SnackbarValue } from './UpdateSnackbars';
import { PreShareDialog } from './PreShare';
import { shareBillV2 } from '../../business_logic/share_side_actions';
import { useParams } from 'react-router-dom';
import { generateSuggestedAppetizerContributions } from './item/suggested_app_contributions';
import { snackbarMessage } from './snackbar_msg';
import { postDiffsToBill } from '../../business_logic/backend/db_access';
import BirthdayModeToggleWrapped from './BirthdayModeToggle';
import { BIRTHDAY_UID } from './item/ItemAvatars';
import { FirstLoadShare } from './FirstLoadShare';
import { DescribeBirthday } from './DescribeBirthdayDialog';
import { ReceiptImageInPay } from '../ReceiptImageInPay';

import Confetti from 'react-confetti'
import { openVenmoLink } from './venmo_note';

function CartIcon(props: { type: 'pay' | 'share' }) {
    switch (props.type) {
        case 'pay':
            return <ShoppingCart size={'40'}></ShoppingCart>
        case 'share':
            return <ArrowCircleUp size={'40'} />
    }
}

function unpaidText(type: 'pay' | 'share') {
    switch (type) {
        case 'pay':
            return 'Items'
        case 'share':
            return 'Everyone'
    }
}

function payItemsText(type: 'pay' | 'share') {
    switch (type) {
        case 'pay':
            return 'Pay for items'
        case 'share':
            return 'Share'
    }
}





function getUserInfoFromUid(bill: PresentedBillV2, uid: string | undefined): User | undefined {
    if (!uid) return undefined;

    if (bill.users !== undefined && Object.keys(bill.users).includes(uid)) {
        return bill.users[uid];
    } else if (uid) {
        return { name: uid };
    }
    else {
        return undefined;
    }
}

// Will need to use Grid for this stuff probably
export function PresentedBillV2UnpaidOrPaidItemsView(props: {
    bill: PresentedBillV2,
    allItems: ItemsView,
    addQuantityOfItemToShoppingCart: (itemGroupId: string, q: number) => void,
    removeQuantityOfItemFromShoppingCart: (itemGroupId: string, q: number) => void,
    removeQuantityOfItemFromBirthday: (itemGroupId: string, q: number) => void,
    uid: string | undefined,
    isBirthdayMode: boolean,
    isRemoveUsersMode?: boolean,
    isShareMode?: boolean,
}) {
    const allItems = Array.from(props.allItems);
    const _submitterUid = props.uid;

    const { billId } = useParams();

    const unpaidItemsContributions = generateSuggestedAppetizerContributions(props.bill);

    if (allItems.length === 0) {
        return <Box>
            <Typography>
                Nothing left unpaid! Maybe somethings on your tab? 🥳
            </Typography>
        </Box>
    }

    function uidToUser(uid: string): User {
        // NOTE(James): This used to fall back on uid for name
        // if props.bill does not contain the uid. Sometimes if the
        // request is slow, I believe the bill will not have the user info
        // yet but will have the uid. It creates a weird render 
        // bug where the user name will be the uid for
        // a second and then change to the name.
        //
        // I'm not sure tho :hmm:
        if (uid === BIRTHDAY_UID) {
            return { name: 'birthday boy' };
        }
        return getUserInfoFromUid(props.bill, uid) ?? { name: uid };
    }


    return (
        <Box>
            {
                allItems.map(
                    itemGroupId => (
                        <UnpaidOrPaidItemRow
                            key={itemGroupId}
                            item={props.bill.items[itemGroupId]}
                            addQuantityOfItemToShoppingCart={
                                (q) => props.addQuantityOfItemToShoppingCart(itemGroupId, q)
                            }
                            removeItemFromShoppingCart={(uid) => {
                                if (uid === BIRTHDAY_UID) {
                                    props.removeQuantityOfItemFromBirthday(itemGroupId, props.bill.items[itemGroupId].birthdayQuantity ?? 1)
                                }
                                else {
                                    props.removeQuantityOfItemFromShoppingCart(itemGroupId, props.bill.items[itemGroupId].quantityInCart ?? 1);
                                }
                            }}
                            uid={props.uid}
                            payContext={unpaidItemsContributions[itemGroupId]}
                            uidToUser={uidToUser}
                            isRemoveUsersMode={props.isRemoveUsersMode}
                            isBirthdayMode={props.isBirthdayMode}
                            isShareMode={props.isShareMode}
                            numDiners={props.bill.numDiners}
                            onRemoveUserFromItem={
                                (uid) => {
                                    const _itemGroupId = itemGroupId;
                                    const _billId = billId;
                                    const _name = uidToUser(uid).name;
                                    const _desc = props.bill.items[_itemGroupId].desc;

                                    if (window.confirm(`Would you like to remove ${_name} from ${_desc}? Caution: this cannot be undone.`)) {
                                        postDiffsToBill(
                                            _billId!,
                                            [
                                                {
                                                    action: 'removeItemForUid',
                                                    itemGroupId: _itemGroupId,
                                                    uid: uid,
                                                    submitterUid: _submitterUid ?? 'unknown',
                                                    timestamp: Date.now(),
                                                }
                                            ],
                                            (failedItems) => {
                                                if (failedItems.length > 0) {
                                                    alert('Failed to remove user from item. Try again!');
                                                }
                                                else {
                                                    alert(`Removed ${_name} from ${_desc}!`);
                                                }
                                            }
                                        );
                                    }

                                }
                            }
                        />
                    )
                )
            }
        </Box>
    )
}

// export function PresentedBillV2PaidItemsView(props: {
//     bill: PresentedBillV2,
//     paidItems: ItemsView
// }) {
//     // NOTE: This falls back on uid if props.bill does not contain the uid
//     // A warning or error should be logged in that case 
//     function uidToUser(uid: string): User {
//         return getUserInfoFromUid(props.bill, uid) ?? { name: uid };
//     }

//     const paidItems = Array.from(props.paidItems);

//     if (paidItems.length === 0) {
//         return <Box>
//             <Typography>
//                 Nothing paid yet...
//             </Typography>
//         </Box>
//     }
//     return (
//         <Box>
//             {
//                 paidItems.map(
//                     itemGroupId => (
//                         <Grid
//                             container
//                             // alignItems='center'
//                             xs={12}
//                             marginBottom={'10px'}
//                         >
//                             <PaidItemRow
//                                 item={props.bill.items[itemGroupId]}
//                                 uidToUser={uidToUser}
//                             ></PaidItemRow>
//                         </Grid>
//                     )
//                 )
//             }
//         </Box>
//     )
// }

export function PresentedBillV2BirthdayItemsView(props: {
    bill: PresentedBillV2,
    birthdayItems: ItemsView,
    removeQuantityOfItemFromBirthday: (itemGroupId: string, q: number) => void,
}) {
    const birthdayItems = Array.from(props.birthdayItems);

    if (birthdayItems.length === 0) {
        return <Box>
            <Typography>
                Nothing's on the birthday tab!
            </Typography>
        </Box>
    }

    return (
        <Box>
            {
                birthdayItems.map(
                    itemGroupId => (
                        <Grid
                            container
                            xs={12}
                            marginBottom={'10px'}
                        // alignItems='center'
                        >
                            <BirthdayItemRow
                                item={props.bill.items[itemGroupId]}
                                removeQuantityOfItemToBirthday={(q: number) => props.removeQuantityOfItemFromBirthday(itemGroupId, q)}
                            ></BirthdayItemRow>
                        </Grid>
                    )
                )
            }
        </Box>
    )
}

export function PresentedBillV2CartItemsView(props: {
    bill: PresentedBillV2,
    cartItems: ItemsView,
    removeQuantityOfItemFromShoppingCart: (itemGroupId: string, q: number) => void,
}) {
    const cartItems = Array.from(props.cartItems);

    // @ts-expect-error global variable on the window
    window.numDiners = props.bill.numDiners;

    if (cartItems.length === 0) {
        return <Box>
            <Typography>There are no items in your tab. Add items to get started.</Typography>
        </Box>
    }

    return (
        <Box>
            {
                cartItems.map(
                    itemGroupId => (
                        <Grid
                            container
                            xs={12}
                            marginBottom={'10px'}
                        // alignItems='center'
                        >
                            <CartItemRow
                                item={props.bill.items[itemGroupId]}
                                removeQuantityOfItemToShoppingCart={
                                    q => props.removeQuantityOfItemFromShoppingCart(
                                        itemGroupId,
                                        q
                                    )
                                }
                            ></CartItemRow>
                        </Grid>
                    )
                )
            }
            {
                // turn off aggregates here: they are cluttered
            }
            {/* <Aggregates bill={props.bill} type='cart' itemsView={props.cartItems} /> */}
        </Box>
    )
}


function usePrevious<T>(value: T): T {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref: any = useRef<T>();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

/**
 * TODO: This is too big! Refactor into two components based on type
 */
export function PresentedBillV2View(props: {
    bill: PresentedBillV2,
    setBill: (a: PresentedBillV2) => void,
    currentUid: string | undefined,
    type: 'share' | 'pay',
    onComplete?: (url: string) => void,
    isRemoveUsersMode?: boolean,
    setIsRemoveUsersMode?: (a: boolean) => void
    imageUrl?: string,
}) {
    const { bill, setBill } = props;

    const { billId } = useParams();

    const billItems = props.bill.items;

    const paidItems = initPaidItemsView(billItems);
    const _unpaidItems = initUnpaidItemsView(billItems);

    const [unpaidItems, setUnpaidItems] = useState(_unpaidItems);
    const [cartItems, setCartItems] = useState(initCartItemsView(billItems));
    const [birthdayItems, setBirthdayItems] = useState(initBirthdayItemsView(billItems));


    const prevBill = usePrevious(bill);
    let itemGroupIdsCart: string[] | undefined = undefined;
    if (prevBill) {
        itemGroupIdsCart = cartToItemGroupIds(prevBill.items);
    }
    useEffect(() => {
        if (itemGroupIdsCart) {
            for (const itemGroupId of itemGroupIdsCart) {
                const item = billItems[itemGroupId];
                if (!item.quantityInCart) {
                    item.quantityInCart = 0;
                }
                // not sure what this did lol
                // if (quantityUnpaid(item) > item.quantityInCart)
                //     item.quantityInCart += 1;
            }
        }

        setUnpaidItems(initUnpaidItemsView(billItems));
    }, [bill]);

    const { width, height } = useWindowDimensions();

    const [cartOpen, setCartOpen] = useState(false);

    const [snackbarValue, setSnackbarValue] = useState<SnackbarValue>()

    const [birthdayMode, setBirthdayMode] = useState(props.type === 'share');
    const [birthdayModeCount, setBirthdayModeCount] = useState(0);
    const [describeBirthdayDialog, setDescribeBirthdayDialog] = useState(false);
    const [firstLoadDialog, setFirstLoadDialog] = useState(true);

    // useEffect(() => {
    //     if (birthdayMode) {
    //         setDescribeBirthdayDialog(birthdayModeCount === 0);
    //         setBirthdayModeCount(birthdayModeCount + 1);
    //     }
    // }, [birthdayMode])

    function addItemToCart(itemGroupId: string, quantity: number, isBirthdayMode?: boolean) {
        if (birthdayMode || isBirthdayMode) {
            setSnackbarValue(undefined);

            assert(
                unpaidItems.has(itemGroupId) && quantityUnpaid(billItems[itemGroupId]) > 0,
                'This item must be unpaid'
            )

            const { birthdayItemsView: newBirthdayItems, unpaidItemsView: newUnpaidItems } = addItemToBirthdayItems(billItems, itemGroupId, quantity, unpaidItems, birthdayItems);

            setBirthdayItems(new Set(newBirthdayItems)); setUnpaidItems(new Set(newUnpaidItems));

            setSnackbarValue({ msg: snackbarMessage(quantity, billItems[itemGroupId], 'add', 'birthday'), actionForUndo: () => _removeItemFromBirthdayItems(itemGroupId, quantity, true) });
        }
        else {
            setSnackbarValue(undefined);

            assert(
                unpaidItems.has(itemGroupId) && quantityUnpaid(billItems[itemGroupId]) > 0,
                'This item must be unpaid'
            );

            const { cartItemsView: newCartItems, unpaidItemsView: newUnpaidItems } = unpaidToCart(billItems, itemGroupId, quantity, unpaidItems, cartItems);

            // NOTE: There's a better way to do this, but state hooks only update if the reference changes, not just the value
            setCartItems(new Set(newCartItems)); setUnpaidItems(new Set(newUnpaidItems));

            setSnackbarValue({ msg: snackbarMessage(quantity, billItems[itemGroupId], 'add'), actionForUndo: () => removeItemFromCart(itemGroupId, quantity, true) });
        }

    }

    function _removeItemFromBirthdayItems(itemGroupId: string, quantity: number, skipDialog?: boolean) {
        setSnackbarValue(undefined);

        assert(
            birthdayItems.has(itemGroupId) && (billItems[itemGroupId].birthdayQuantity ?? 0) > 0,
            'This item must be in the birthday items'
        );

        const { birthdayItemsView: newBirthdayItems, unpaidItemsView: newUnpaidItems } = removeItemFromBirthdayItems(billItems, itemGroupId, quantity, unpaidItems, birthdayItems);

        setBirthdayItems(new Set(newBirthdayItems)); setUnpaidItems(new Set(newUnpaidItems));

        if (!skipDialog)
            setSnackbarValue({ msg: snackbarMessage(quantity, billItems[itemGroupId], 'remove', 'birthday'), actionForUndo: () => addItemToCart(itemGroupId, quantity, true) });



    }

    function removeItemFromCart(itemGroupId: string, quantity: number, skipDialog?: boolean) {
        // PRE: Assume it's not birthday mode here
        setSnackbarValue(undefined);

        assert(
            cartItems.has(itemGroupId) && quantityInCart(billItems[itemGroupId]) > 0,
            'This item must be in the cart'
        );

        const { cartItemsView: newCartItems, unpaidItemsView: newUnpaidItems } = cartToUnpaid(billItems, itemGroupId, quantity, unpaidItems, cartItems);

        setCartItems(new Set(newCartItems)); setUnpaidItems(new Set(newUnpaidItems));

        if (!skipDialog)
            setSnackbarValue({ msg: snackbarMessage(quantity, billItems[itemGroupId], 'remove'), actionForUndo: () => addItemToCart(itemGroupId, quantity) });
    }

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shareInfoDialogOpen, setShareInfoDialogOpen] = useState(false);
    const [confirmPaidOpen, setConfirmPaidOpen] = useState(false);

    // still need to negate everything in this cart to undo it with remove flag
    const [cartForUndo, setCartForUndo] = useState<Diff[] | undefined>(undefined);

    function postOnOpenVenmo() {
        if (!props.currentUid) {
            console.error('There should probably be a uid when posting a bill!');
            props.currentUid = 'This was set in error'
        }

        postShoppingCartV2ToDb(
            billId!,
            bill,
            props.currentUid,
            (cartToPostForUndo) => {
                setCartForUndo(cartToPostForUndo);
                setConfirmPaidOpen(true); setConfirmOpen(false);
            },
            (msg) => { alert('Failure with msg ' + msg); window.location.reload(); },
            props.currentUid,
            false
        )
    }

    return <>
        {
            birthdayMode && props.type === 'share' && <Box>
                <Confetti width={width} height={height} style={{ zIndex: -1 }} opacity={0.6}></Confetti>
            </Box>
        }
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' mt={0.5}>
            {
                <Box display='flex' flexDirection='row' ml={1} padding={0.5} bgcolor={'#f1dbff'} borderRadius={3} boxShadow={'1px 1px 5px rgb(161, 165, 219)'}>
                    <Box>
                        <IconButton onClick={() => {
                            if (props.type === 'share') {
                                window.history.back()
                            }
                            else if (props.type === 'pay') {
                                props.setIsRemoveUsersMode?.(
                                    true
                                    // window.confirm('Would you like to edit the users assigned?')
                                )
                            }
                        }}>
                            <Pencil size={18} color='#222' />
                        </IconButton>
                    </Box>
                    {props.imageUrl && <Box>
                        <ReceiptImageInPay url={props.imageUrl} />
                    </Box>}
                </Box>
            }

            {
                props.type === 'share' &&
                <Box display='flex' flexDirection='row-reverse' mr={1} >
                    {/* <BirthdayModeToggleWrapped birthdayMode={birthdayMode} setBirthdayMode={setBirthdayMode} /> */}
                </Box>
            }
        </Box>

        <Box sx={{ paddingTop: props.type === 'share' ? '1vh' : '1vh' }} className='foo' >
            <Box>
                <FirstLoadShare isDialogOpen={firstLoadDialog} onClose={() => setFirstLoadDialog(false)} type={props.type} cardholder={props.bill.ownerUid ? (getUserInfoFromUid(props.bill, props.bill.ownerUid)?.name ?? 'the owner') : 'You!'} />
                <DescribeBirthday isDialogOpen={describeBirthdayDialog} onClose={(isStillBirthday) => { setBirthdayMode(isStillBirthday); setDescribeBirthdayDialog(false); }} />
                <SnackbarItemChange
                    value={snackbarValue}
                    handleClose={() => setSnackbarValue(undefined)}
                />

                <ConfirmCartDialog
                    bill={props.bill}
                    cartItems={cartItems}
                    open={confirmOpen}
                    close={() => setConfirmOpen(false)}
                    type={props.type}
                    onPay={() => {
                        // TODO: Mark as paid by default
                        switch (props.type) {
                            case 'pay':
                                if (props.bill.paymentOptions?.paymentType == 'venmo') {
                                    openVenmoLink(props.bill, cartItems);
                                    postOnOpenVenmo();
                                    break;
                                }
                                else if (props.bill.paymentOptions?.paymentType == 'cashApp') {
                                    window.open('https://cash.app/$' + bill.paymentOptions?.paymentUsername + '/' + cartTotal(props.bill, cartItems).toFixed(2));
                                    postOnOpenVenmo();
                                    break;
                                }
                                break;
                            case 'share':
                            default:
                                setShareInfoDialogOpen(true); setConfirmOpen(false);
                                break;
                        }
                    }}

                ></ConfirmCartDialog>

                {
                    // TODO: What happens if user forgets to click back to this page after paying in Venmo
                    // There should be some working timeout
                }
                <ConfirmPaid
                    bill={props.bill}
                    cartItems={cartItems}
                    open={confirmPaidOpen}
                    onCancel={() => {
                        if (!props.currentUid) {
                            console.error('There should probably be a uid when posting a bill!');
                            alert('noUID!')
                            props.currentUid = 'This was set in error'
                        }

                        postRawDiffsToBillForUndo(
                            billId!,
                            cartForUndo!,
                            () => {
                                // alert('success! posted diff to db to remove item');
                                setConfirmPaidOpen(false); setConfirmOpen(false);
                                setCartItems(new Set());
                                // state gets shitty so just reload
                                window.location.reload();
                            },
                            (msg) => { alert('Failure with msg ' + msg); window.location.reload(); },
                        )

                    }}
                    onConfirm={() => {
                        props.onComplete?.(window.location.href);
                    }
                    }
                ></ConfirmPaid>

                <PreShareDialog
                    bill={bill}
                    open={shareInfoDialogOpen}
                    onCancel={() => setShareInfoDialogOpen(false)}
                    onConfirm={(uid, user) => {
                        bill.ownerUid = uid;
                        bill.users[bill.ownerUid] = { name: user.name };

                        shareBillV2(bill, (shareUrl) => {
                            navigator.clipboard.writeText(shareUrl);
                            window.history.replaceState({}, 'BananaSplit', shareUrl);
                            props.onComplete?.(shareUrl)
                        })

                    }}
                />

                <Box width={Math.min(500, width * 0.8)}>

                    <Header bill={props.bill} />
                    <SolidDivider></SolidDivider>

                    <BillSubtitleText>
                        {unpaidText(props.type)}
                    </BillSubtitleText>

                    <SolidDivider sx={{ paddingBottom: 1 }}></SolidDivider>

                    <PresentedBillV2UnpaidOrPaidItemsView
                        bill={props.bill}
                        allItems={new Set(Object.keys(props.bill.items))}
                        addQuantityOfItemToShoppingCart={addItemToCart}
                        removeQuantityOfItemFromShoppingCart={removeItemFromCart}
                        removeQuantityOfItemFromBirthday={_removeItemFromBirthdayItems}
                        uid={props.currentUid}
                        isBirthdayMode={props.type === 'share' && birthdayMode}
                        isRemoveUsersMode={props.isRemoveUsersMode}
                        isShareMode={props.type === 'share'}
                    />
                </Box >
                {!props.isRemoveUsersMode && !cartOpen && <Button
                    sx={{
                        marginLeft: 'auto',
                        position: 'fixed',
                        right: 20,
                        bottom: 20,
                        borderRadius: 100,
                        paddingY: 2,
                        paddingX: 5,
                        zIndex: 200,
                    }}
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={() => { setCartOpen(true); }}
                >
                    <Box display='flex' alignItems={'center'} justifyContent='center'>
                        <CartIcon type={props.type} />
                    </Box>

                </Button>}
                {!props.isRemoveUsersMode && !confirmOpen && !confirmPaidOpen && !shareInfoDialogOpen && <Sheet
                    isOpen={cartOpen}
                    onClose={() => { setCartOpen(false) }}
                    onCloseEnd={() => setCartOpen(false)}
                    detent='content-height'
                    draggable={false}
                >
                    <Sheet.Container
                    >
                        <Sheet.Content>
                            <SnackbarItemChange
                                value={snackbarValue}
                                handleClose={() => setSnackbarValue(undefined)}
                            />
                            <Box marginX='10%'>
                                <Box display='flex' alignItems='center' justifyContent='center' paddingTop={0.5}>
                                    <DotsSix />
                                </Box>
                                <DashedDivider sx={{ paddingTop: 2 }}></DashedDivider>
                                { /* TODO: A soda can tab would be cool here */}
                                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                                    <BillSubtitleText>{birthdayMode ? 'Birthday tab' : 'My tab'}</BillSubtitleText>
                                    {
                                        // props.type === 'share' && <BirthdayModeToggleWrapped birthdayMode={birthdayMode} setBirthdayMode={setBirthdayMode} dense={true} />
                                    }
                                </Box>
                                <DashedDivider sx={{ paddingBottom: 1 }}></DashedDivider>

                                {birthdayMode ? <PresentedBillV2BirthdayItemsView
                                    bill={props.bill}
                                    birthdayItems={birthdayItems}
                                    removeQuantityOfItemFromBirthday={_removeItemFromBirthdayItems}
                                />
                                    : <PresentedBillV2CartItemsView
                                        bill={props.bill}
                                        cartItems={cartItems}
                                        removeQuantityOfItemFromShoppingCart={removeItemFromCart}
                                    />
                                }
                                {
                                    <Box display='flex' flexDirection={'column'} paddingTop={'10px'} paddingBottom={'20px'}
                                    >
                                        <Fab
                                            sx={{
                                                marginLeft: 'auto',
                                                zIndex: 100,
                                                minHeight: '3.5rem',
                                            }}
                                            disabled={(props.type === 'pay' && Array.from(cartItems).length === 0)}
                                            variant='extended'
                                            color='primary'
                                            onClick={() => {
                                                switch (props.type) {
                                                    case 'share':
                                                        setShareInfoDialogOpen(true);
                                                        break;
                                                    case 'pay':
                                                    default:
                                                        if (props.bill.paymentOptions?.paymentType == 'venmo') {
                                                            setConfirmOpen(true);
                                                            // postOnOpenVenmo();
                                                            // openVenmoLink(props.bill, cartItems);
                                                            break;
                                                        }
                                                        else if (props.bill.paymentOptions?.paymentType == 'cashApp') {
                                                            // window.open('https://cash.app/$' + bill.paymentOptions?.paymentUsername + '/' + cartTotal(props.bill, cartItems).toFixed(2));
                                                            // postOnOpenVenmo();
                                                            setConfirmOpen(true);
                                                            break;
                                                        }
                                                        break;
                                                }
                                            }}
                                            size='large'
                                        >
                                            <Typography
                                                textTransform={'none'}
                                                variant='button'
                                                paddingRight={'10px'}
                                                fontSize='14px'
                                            >{payItemsText(props.type)}</Typography>
                                            <Send size={16} />
                                        </Fab>
                                    </Box>

                                }
                            </Box>
                        </Sheet.Content>
                    </Sheet.Container>
                </Sheet>
                }
            </Box>
        </Box>
    </>
}