import { useState } from "react";
import { Box, Dialog, IconButton } from "@mui/material";
import { Receipt } from "phosphor-react";


export function ReceiptImageInPay(props: { url: string }) {
    const [open, setOpen] = useState(false);

    return <>
        <Box>
            <IconButton onClick={() => setOpen(true)}>
                <Receipt size={18} color='#222' />
            </IconButton>
        </Box>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <img src={props.url} />
        </Dialog>
    </>
}