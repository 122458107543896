import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getDatabase } from '@firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
    apiKey: "AIzaSyCbejzLxVMH1u-kRLcVViD2jupq3ZzfBDI",
    authDomain: "bill-split-foo.firebaseapp.com",
    databaseURL: "https://bill-split-foo-default-rtdb.firebaseio.com",
    projectId: "bill-split-foo",
    storageBucket: "bill-split-foo.appspot.com",
    messagingSenderId: "745232022887",
    appId: "1:745232022887:web:1f9a7c8000b9e2508a03c2",
    measurementId: "G-VCXMCH44E1"
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const db = getDatabase(app);
export const authentication = getAuth(app);
const perf = getPerformance(app);