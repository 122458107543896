import { List } from 'phosphor-react';
import '../App.css';
import { UploadBill } from './UploadBill';
import { Box, IconButton, Typography } from '@mui/material';

function Home() {
    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', alignItems: 'center', bgcolor: '#f9f9f9' }}>
            <Box sx={{ display: 'flex', height: '80px', minHeight: '80px' }}>
                <Box width='100vw' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography fontFamily={'Candola'} fontWeight={'bold'} fontSize={'22px'} sx={{
                        backgroundImage: 'linear-gradient(97.67deg, rgba(182, 122, 203, 1.0) 26.71%, rgba(132, 116, 199, 1.0) 46.53%, rgba(126, 131, 212, 1.0) 65.18%, rgba(135, 146, 225, 1.0) 82.66%)',
                        backgroundClip: 'text',
                        color: 'transparent',
                        ml: 4,
                    }}>Banana Split</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', flex: 1, bgcolor: '#E8EAF4', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}>
                <header className="App-header">
                    <UploadBill></UploadBill>
                </header>
            </Box>
        </Box>

    );
}

export default Home;
