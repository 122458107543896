import { BillEditable, PresentedBill, PresentedBillV2 } from "../business_component_shared/Types";

export function billSubtotal(bill: BillEditable): number {
    if (Object.values(bill.items).length >= 1) {
        return Object.values(bill.items ?? {}).filter(x => x.cost !== undefined).map(x => x.cost!).reduce((acc, v) => acc + v, 0);
    } else {
        // avoid error: "Uncaught TypeError: reduce of empty array with no initial value" 
        return 0;
    }
}

export function billTotal(bill: BillEditable): number {
    return billSubtotal(bill) + (bill.taxAmount ?? 0) + (bill.tipAmount ?? 0);
}

export function taxRate(bill: BillEditable, billSubtotal: number): number {
    return (bill.taxAmount ?? 0) / billSubtotal;
}

export function tipRate(bill: BillEditable, billSubtotal: number): number {
    return (bill.tipAmount ?? 0) / billSubtotal;
}

export function finalizeEditableBill(editableBill: BillEditable): PresentedBill {
    const bill_subtotal = billSubtotal(editableBill);
    const tax_rate = taxRate(editableBill, bill_subtotal);
    const tip_rate = tipRate(editableBill, bill_subtotal);

    const presentedBill: PresentedBill = {
        taxRate: tax_rate,
        isBirthday: editableBill.isBirthday,
        tipRate: tip_rate,
        timestamp: editableBill.timestamp,
        items: {},
        users: {}
    };

    for (const [groupId, itemGroup] of Object.entries(editableBill.items)) {
        for (let i = 0; i < (itemGroup.quantity ?? 0); i++) {
            const internalItemId = groupId + ' ' + String(i); // HACK
            presentedBill.items[internalItemId] = {
                desc: itemGroup.desc!,
                price: itemGroup.cost!,
                itemGroupId: groupId,
            }
        }
    }
    return presentedBill;
}

export function finalizeEditableBillV2(editableBill: BillEditable): PresentedBillV2 {
    const presBillV2: PresentedBillV2 = {
        items: {},
        users: {},
        taxAmount: editableBill.taxAmount ?? 0,
        tipAmount: editableBill.tipAmount ?? 0,
        timestamp: Date.now(),
        numDiners: editableBill.numDiners,
        restaurantName: editableBill.restaurantName,
    }

    for (const [itemGroupId, itemGroup] of Object.entries(editableBill.items)) {
        presBillV2.items[itemGroupId] = {
            desc: itemGroup.desc ?? '',
            price: (itemGroup.cost ?? 0) / (itemGroup.quantity ?? 1),
            quantity: itemGroup.quantity ?? 1,
            isAppetizer: !!itemGroup.isAppetizer,
        }
    }

    return presBillV2;
}
