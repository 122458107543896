import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";

function formattedCurrency(value: number) {
    return '$' + value.toFixed(2);
}

// TODO: Eventually take currency symbol and decimal seperator from global context
export function SubtotalChecksum(props: { subtotal: number }) {
    const { subtotal } = props;
    return (
        <Grid2
            container
            xs={12}
            sx={{ minHeight: '30px' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid2 xs={6} sm={5}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'left'
                }}>
                    <Typography>SUBTOTAL</Typography>
                </div>
            </Grid2>
            <Grid2 xs={1} sm={1}>{' '}</Grid2>
            <Grid2 xs={5} sm={5}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right'
                }}>
                    <Typography>{formattedCurrency(subtotal)}</Typography>
                </div>
            </Grid2>
        </Grid2>
    )
}

export function TotalChecksum(props: { total: number }) {
    const { total } = props;
    return (
        <Grid2
            container
            xs={12}
            sx={{ minHeight: '30px' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid2 xs={6} sm={5}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'left'
                }}>
                    <Typography>TOTAL</Typography>
                </div>
            </Grid2>
            <Grid2 xs={1} sm={1}>{' '}</Grid2>
            <Grid2 xs={5} sm={5}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right'
                }}>
                    <Typography>{formattedCurrency(total)}</Typography>
                </div>
            </Grid2>
        </Grid2>
    )
}