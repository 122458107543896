import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Box, Divider, SxProps, Typography, TextField, Stack, Theme, IconButton } from "@mui/material";
import { validateKeyPressForMoneyFields } from '../../business_component_shared/helpers';
import { CurrencyDollar, Hash, Percent, ShuffleSimple } from 'phosphor-react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentageIcon from '@mui/icons-material/Percent';
import { makeStyles } from '@mui/material';

// TODO: Refactor global style constants
const INPUT_BACKGROUND_COLOR = '#f7f7f7';

// TODO: Refactor regional constants (currency, decimal seperator)
const DECIMAL_SEPERATOR = '.';
const CURRENCY_SYMBOL = '$';

function cutStringTwoAfterDecimalSeperator(s: string) {
    const decimalSeperatorIndex = s.indexOf(DECIMAL_SEPERATOR);
    if (decimalSeperatorIndex === -1) {
        return s;
    }
    return s.substring(0, decimalSeperatorIndex + 3);
}

const CustomSwitch2 = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        padding: theme.spacing(0.6),
        marginTop: theme.spacing(0.8),
        backgroundColor: '#000',
        borderRadius: 40,
        '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            justifyContent: 'flex-end',
        },
        '& svg': {
            marginLeft: theme.spacing(0.05),
        },
    },
    '& .MuiSwitch-switchBase:not(.Mui-checked)': {
        padding: theme.spacing(0.6),
        marginTop: theme.spacing(0.8),
        backgroundColor: '#000',
        borderRadius: 40,
        '& + .MuiSwitch-track': {
            backgroundColor: '#000',
            justifyContent: 'flex-start',
        },
        '& svg': {
            marginLeft: theme.spacing(0.05),
        },
    },
}));

export function EditableTaxTipAmount(props: { currentValue: number | undefined, defaultValue: number | undefined, setter: (dollarValue: number, rawPercentValue?: number) => void, sxProps?: SxProps, billSubtotal?: number, label?: string, defaultToPercent?: boolean, defaultPercent?: number }): [React.ReactNode, React.ReactNode, React.ReactNode] {
    const [isPercent, setIsPercent] = React.useState(!!props.defaultToPercent);

    const [value, setValue] = React.useState(String(props.defaultValue));

    const handleToggle = () => {
        if (isPercent) {
            // percent --> dollar
            setValue(String((props.billSubtotal ?? 0) * Number(value) / 100));
        } else {
            // dollar --> percent
            setValue(String(Number(value) / (props.billSubtotal ?? 0) * 100));
        }
        setIsPercent(!isPercent);
        bubbleUpValue(value);
    };


    const bubbleUpValue = (value: string) => {
        if (isPercent) {
            props.setter(Number(value) / 100 * (props.billSubtotal ?? 0), Number(value));
        } else {
            props.setter(Number(value));
        }
    }

    return [
        <Box>
            {/* <IconButton onClick={handleToggle}>
                <ShuffleSimple />
            </IconButton> */}
            <CustomSwitch2
                sx={{ mr: 1 }}
                checked={isPercent}
                onChange={handleToggle}
                icon={<CurrencyDollar />}
                checkedIcon={<Percent color='white' />} />
        </Box>,
        <Typography>{props.label ?? 'TAX'} {isPercent ? 'PERCENT' : 'AMOUNT'}</Typography>,
        <React.Fragment>
            <TextField
                size='small'
                error={props.currentValue === undefined || Number.isNaN(props.currentValue)}
                sx={{
                    backgroundColor: INPUT_BACKGROUND_COLOR,
                    ...props.sxProps,
                }}
                value={value && value !== 'undefined' && value !== 'NaN' ? cutStringTwoAfterDecimalSeperator(value) : ''}
                // inputProps={{ readOnly: !canEdit }}
                type='number'
                // label={props.label + (isPercent ? ' (%)' : ' ($)')}
                InputProps={{
                    startAdornment: <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography>{isPercent ? '' : '$'}</Typography>
                    </Box>,
                    endAdornment: <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography>{isPercent ? '%' : ''}</Typography>
                    </Box>
                }}
                inputProps={{
                }}
                onChange={(event) => { setValue(event.target.value); bubbleUpValue(event.target.value); }}
                onKeyPress={validateKeyPressForMoneyFields}
            ></TextField>
        </React.Fragment >
    ];
}

export function EditableTaxTipAmountForEdit(props: { currentValue: number | undefined, defaultValue: number | undefined, setter: (dollarValue: React.SetStateAction<number | undefined>) => void, sxProps?: SxProps, billSubtotal?: number, label?: string, defaultToPercent?: boolean, skipConversion?: boolean },): [React.ReactNode, React.ReactNode] {
    const etta = EditableTaxTipAmount(props);
    return [
        <Box display='flex' flexDirection={'row'} alignItems='center'>
            {
                etta[0]
            }
            {
                etta[1]
            }
        </Box>,
        <>
            {etta[2]}
        </>
    ]
}