import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

export function DescribeBirthday(
    props: {
        isDialogOpen: boolean,
        onClose: (isStillBirthday: boolean) => void,
    }
) {
    return <Dialog open={props.isDialogOpen} onClose={props.onClose}
        PaperProps={{
            sx: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 'max(300px, 40%)',
            }
        }}
    >
        <DialogContent sx={{ marginBottom: -1 }}>
            <Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography fontWeight={600} fontSize={16}>Is it someone's birthday? 🥳</Typography>
                </Box>
                <Box>
                    <Typography>They eat free! Assign their items now and their bill will be split evenly among all diners.</Typography>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions sx={{ marginLeft: 'auto', marginRight: 1 }}>
            <Button variant='contained' sx={{ textTransform: 'none', marginBottom: 1 }} color='error' onClick={() => props.onClose(false)}>
                <Typography>No, take me back</Typography>
            </Button>
            <Button variant='contained' sx={{ textTransform: 'none', marginBottom: 1 }} color='success' onClick={() => props.onClose(true)}>
                <Typography>Yes, let's go!</Typography>
            </Button>
        </DialogActions>


    </Dialog>
}