import { Box, Button, Divider, IconButton, SxProps, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { copyIndexSignature } from "../../business_component_shared/helpers";
import { BillEditable } from "../../business_component_shared/Types";
import { EditableItem, isLineItemInvalid, warnQuantity } from "../EditableBillItem";
import { ABArrangementBase } from "../grid/GridArrangement";
import { DashedDivider } from "../shopping_v2/Components";
import { SubtotalChecksum, TotalChecksum } from "./Checksum";
import { EditableTaxTipAmountForEdit } from "./EditableTipTaxAmount";
import { StatefulNumDiners } from "./NumDinersInput";
import { StatefulRestaurantName } from "./RestaurantNameInput";
import { billSubtotal as editableBillSubtotal, billTotal as editableBillTotal } from "../../business_logic/edit_side_actions";
import { Question, ArrowCircleRight, Plus } from "phosphor-react";

function ABArrangementEditFooter(props: { children: [React.ReactNode, React.ReactNode], sxProps?: SxProps }) {
    return <ABArrangementBase a={6} b={5} smA={5} sxProps={props.sxProps}>{props.children}</ABArrangementBase>;
}

function areNumbersCloseEnough(num1: number, num2: number) {
    return Math.abs(num1 - num2) <= 3
}

function formattedCurrency(value: number) {
    return '$' + value.toFixed(2);
}

const asyncLocalStorage = {
    setItem: function (key: string, value: string) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key: string) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};

// window.onbeforeunload = function () { return true; }

export function EditableBillBase(props: { bill: BillEditable, onDone: () => void }) {
    const [lineItems, setLineItems] = useState(props.bill.items);
    const [taxAmt, setTaxAmt] = useState(props.bill.taxAmount);
    const [tipAmt, setTipAmt] = useState(props.bill.tipAmount);
    const [subtotal, setSubtotal] = useState<number>(editableBillSubtotal(props.bill));
    const [total, setTotal] = useState<number>(editableBillTotal(props.bill));
    const [qWarn, setQWarn] = useState<boolean>(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isBirthday, setIsBirthday] = useState<boolean>(props.bill.isBirthday);

    function updateSubtotal() {
        setSubtotal(editableBillSubtotal(props.bill));
    }

    function updateTotal() {
        setTotal(editableBillTotal(props.bill));
    }

    /**
     * Update local storage with the current bill. 
     * TODO: Verify this happens asynchonously and does not block state changes
     */
    function updateLocalStroage() {
        asyncLocalStorage.setItem('bill', JSON.stringify(props.bill));
    }

    // Update props.bill on change of it's attributes
    useEffect(() => {
        props.bill.items = lineItems;
        props.bill.taxAmount = taxAmt;
        props.bill.tipAmount = tipAmt;

        // TODO: This could be a cause of slowless. Make sure it's not!
        // lots of O(nk) operations here
        updateLocalStroage();

        updateSubtotal();

        if (props.bill.tipPercent && !props.bill.tipAmount) {
            const _tipAmt = props.bill.tipPercent / 100 * subtotal;
            setTipAmt(_tipAmt);
            props.bill.tipAmount = _tipAmt;
        }

        updateTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItems, taxAmt, tipAmt, props.bill]);

    function addLineItem() {
        // eslint-disable-next-line no-lone-blocks
        const id = Math.max(...Object.keys(lineItems).map((x, i) => { { return Number.isInteger(Number(x)) ? Number(x) : i } })) + 1;
        const newLineItems = copyIndexSignature(lineItems);

        newLineItems[id] = { quantity: 1 };

        setLineItems(newLineItems);
    }

    function removeLineItem(tmpItemId: string) {
        const newLineItems = copyIndexSignature(lineItems);

        delete newLineItems[tmpItemId];

        setLineItems(newLineItems);
    }

    return (
        <div id='parsedBillA'>
            <div id='lineItems'>
                <Box sx={{ display: 'flex', alignItems: 'center', alignText: 'center', justifyContent: 'center' }}>
                    <Typography variant='h4'>My Banana Split</Typography>
                </Box>
                <Divider sx={{ marginY: '10px', marginX: 0, borderBottomWidth: 1, borderStyle: 'dashed', borderColor: 'black' }} />
                <Grid container spacing={3} alignItems={'center'} direction='column'>
                    <Grid
                        container
                        xs={12}
                        style={{ minHeight: '30px' }}
                        justifyContent='flex-start'
                        alignItems="center"
                    >
                        <Grid xs={3} sm={2.5} md={3} >
                            <Typography ml={1} variant='body1' sx={{}}>QUANTITY</Typography>                        </Grid>
                        <Grid xs={5.5} sm={7} md={7}>
                            <Typography variant='body1' sx={{}}>ITEM</Typography>
                        </Grid>
                        <Grid xs={3} sm={2.5} md={2}>
                            <Box display='flex' flexDirection={'row'} alignItems={'center'}>
                                <Typography variant='body1' sx={{}}>PRICE</Typography><Tooltip title='Price is the total price, not per item.'>
                                    <IconButton size='small'>
                                        <Question />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        Object.entries(lineItems).map(([tmpItemId, li]) => <Grid container
                            spacing={1}
                            xs={12}
                            justifyContent="flex-start"
                            alignItems="center"> <EditableItem key={tmpItemId} tmpItemId={tmpItemId} lineItem={li} removeItem={removeLineItem} onUpdateItem={() => {
                                updateSubtotal();
                                updateTotal();
                                updateLocalStroage();
                            }}></EditableItem></Grid>)
                    }
                </Grid>
                <Grid container>

                </Grid>
                <Grid container marginTop={'20px'} alignItems={'end'} justifyContent='end' direction='row'>
                    <IconButton onClick={addLineItem} color='default' sx={{ bgcolor: '#DFE0FF' }}>
                        <Plus />
                    </IconButton>
                </Grid>
            </div>
            <Grid container alignItems={'center'} direction='column' mt={2}>
                <Grid xs={11} sm={10} md={10} justifyContent='flex-start' alignItems="center">
                    { /* Subtotal */}
                    <DashedDivider />
                    <SubtotalChecksum subtotal={subtotal} />
                    <DashedDivider />
                    { /* Tax and tip */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <ABArrangementEditFooter children={
                                EditableTaxTipAmountForEdit(
                                    {
                                        currentValue: taxAmt,
                                        defaultValue: props.bill.taxAmount,
                                        setter: setTaxAmt,
                                        sxProps: {},
                                        billSubtotal: subtotal,
                                        label: 'TAX',
                                    }
                                )} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <ABArrangementEditFooter children={
                                EditableTaxTipAmountForEdit(
                                    {
                                        currentValue: tipAmt,
                                        defaultValue: props.bill.tipPercent ?? props.bill.tipAmount,
                                        setter: setTipAmt,
                                        sxProps: {},
                                        billSubtotal: subtotal,
                                        label: 'TIP',
                                        defaultToPercent: props.bill.tipPercent !== undefined,
                                    }
                                )
                            } />
                        </Box>
                    </Box>
                    { /* Total */}
                    <DashedDivider sx={{ mt: 2 }} />
                    <TotalChecksum total={total} />
                    <DashedDivider />
                    { /* Num diners */}
                    <ABArrangementEditFooter children={
                        StatefulNumDiners({
                            defaultNumDiners: props.bill.numDiners,
                            persistNumDiners: (numDiners) => {
                                props.bill.numDiners = numDiners;
                                updateLocalStroage();
                            },
                        })
                    } sxProps={{ mt: 1 }} />

                    <ABArrangementEditFooter children={
                        StatefulRestaurantName({
                            defaultRestaurantName: props.bill.restaurantName,
                            persistRestaurantName: (restaurantName) => {
                                props.bill.restaurantName = restaurantName;
                                updateLocalStroage();
                            },
                        })
                    } sxProps={{ mt: 1 }} />

                    {
                        // Former birthday component. No longer used.
                    }
                    {/* <ABArrangementEditFooter children={
                        StatefulBirthdayInput({
                            defaultBirthday: props.bill.isBirthday,
                            persistBirthday: (birthday) => {
                                props.bill.isBirthday = birthday;
                                setIsBirthday(birthday);
                                updateLocalStroage();
                            }

                        })
                    } sxProps={{ mt: 1 }} /> */}



                </Grid>
            </Grid>

            {
                /**
                 * TODO: Add total / other summary stuff -- it's good for easy validation
                 */
            }

            <Button
                sx={{
                    marginLeft: 'auto',
                    position: 'fixed',
                    right: 20,
                    bottom: 20,
                    borderRadius: 100,
                    paddingY: 2,
                    paddingX: 5,
                    textTransform: 'none'
                }}
                variant='contained'
                size='large'
                color={isBirthday ? 'primary' : 'secondary'}
                onClick={
                    () => {
                        if (!props.bill.numDiners) {
                            alert('Please enter the number of diners in your party.');
                            return;
                        }

                        if (Object.values(lineItems).filter(x => isLineItemInvalid(x)).length > 0) {
                            const potentiallyInvalidItems = Object.values(lineItems).filter(x => isLineItemInvalid(x));
                            const itemsForMessage = potentiallyInvalidItems.map(x => x.desc).slice(0, 3).join(', ');
                            const continueAnyway = window.confirm(`We detected some possibly incorrect items: ${itemsForMessage}. Do you want to continue anyway?`)
                            if (!continueAnyway) {
                                return;
                            }
                        }
                        
                        if (qWarn && Object.values(lineItems).filter(x => warnQuantity(x)).length > 0) {
                            const potentiallyWrongItems = Object.values(lineItems).filter(x => warnQuantity(x));
                            const itemsForMessage = potentiallyWrongItems.map(x => x.desc).slice(0, 3).join(', ');
                            const continueAnyway = window.confirm(`Some of the quantities for items: ${itemsForMessage} may be incorrect. Do you want to continue anyway?`)
                            if (!continueAnyway) {
                                return;
                            } else {
                                setQWarn(false);
                            }
                        }
                        
                        if (props.bill.subtotal && subtotal !== props.bill.subtotal) {
                            const subtotalOnReceipt = props.bill.subtotal;
                            const currentSubtotal = subtotal;

                            if (!areNumbersCloseEnough(subtotalOnReceipt, currentSubtotal)) {
                                const continueAnyway = window.confirm(`The subtotal on the receipt looks like ${formattedCurrency(subtotalOnReceipt)}, but the items we have only add up to ${formattedCurrency(currentSubtotal)}. Do you want to continue anyway?`)
                                if (!continueAnyway) {
                                    return;
                                }
                            }
                        }

                        props.onDone();
                    }
                }
            >
                <Box display='flex' alignItems={'center'} justifyContent='center'>
                    <Typography fontSize='16px' mr={1}>
                        {
                            isBirthday ? 'Assign birthday items' : 'Share the bill'
                        }
                    </Typography>
                    <ArrowCircleRight size={'20px'} />

                </Box>
            </Button>
        </div>

    )

}