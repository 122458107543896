import { db } from './firebase-config'
import { ref, set, get, onValue, remove, onChildAdded, push, update } from "firebase/database";
import { uuidv4 } from "@firebase/util";
import { DBBill, DBBillAttributes, DBDiff, DBUser, Diff } from "../../business_component_shared/Types";
import { deepCopy } from './helpers';
import { BIRTHDAY_UID } from '../../components/shopping_v2/item/ItemAvatars';

export function postDiffsToBill(billId: string, diffs: Diff[], onComplete?: (failedItems: { itemGroupId: string, msg: string }[]) => void, errStack?: { itemGroupId: string, msg: string }[]) {
    const currDiff = diffs.pop();
    if (currDiff) {
        postDiffEvent(billId, currDiff,
            () => { postDiffsToBill(billId, diffs, onComplete, errStack) },
            (msg) => {
                const _errStack = (errStack ?? []).concat({ itemGroupId: currDiff.itemGroupId, msg });
                postDiffsToBill(billId, diffs, onComplete, _errStack)
            }
        )
    }
    else {
        onComplete?.(errStack ?? []);
    }
}

/**
 * In order to move back away from some of the bill being stored in the URL, we can throw it in DBBillAttrbitues and
 * store it in the database
 */
export function postBillToDb(attr: DBBillAttributes, diffs: Diff[], success: (billId: string) => void) {
    const _attr = JSON.parse(JSON.stringify(attr));
    // @ts-expect-error Hack to easily pass billId in here
    const bid = window.billId ?? uuidv4();
    set(
        ref(
            db, 'bills_v2/' + bid + '/attr'
        ), _attr
    )
        .then(() => { postDiffsToBill(bid, diffs, () => success(bid)) })
        .catch(_ => { console.error('Could not post ' + _) });
}

export function postUserToDb(uid: string, user: DBUser, success?: () => void, failure?: (msg: string) => void) {
    set(
        ref(
            db, 'users/' + uid
        ), user
    ).then(
        () => { success?.() }
    ).catch(
        () => { (failure ?? console.error)('Could not post user') }
    )
}

export function getUserFromDb(uid: string, success: (user: DBUser) => void, failure?: (msg: string) => void) {
    if (uid === BIRTHDAY_UID) {
        success({ name: 'Birthday' });
        return;
    }
    get(
        ref(
            db, 'users/' + uid
        )
    ).then(
        (snapshot) => success(snapshot.val())
    ).catch(
        () => { (failure ?? console.error)('Could not get user') }
    )
}

/**
 * If we didn't store the static non-PII bill contents in the URL, we would just retrieve them here :)
 */
export function getBillAttrFromDb(billId: string, setAttributes: (attr: DBBillAttributes) => void) {
    const attrRef = ref(db, 'bills_v2/' + billId + '/' + 'attr')
    get(attrRef).then(
        (snapshot) => {
            setAttributes(snapshot.val());
        }
    ).catch(
        err => console.error(err)
    )
}

export function listenForDiffEvents(billId: string, addDiffEvent: (timestampKey: string, diff: DBDiff) => void) {
    const diffsRef = ref(db, 'bills_v2/' + billId + '/' + 'diffs');
    onChildAdded(
        diffsRef,
        (data) => {
            if (data.key) {
                addDiffEvent(data.key, data.val());
            }
            else {
                console.warn('Key does not exist')
            }
        }
    )
}

// could eventually configure success to be called with the ref to this item
export async function postDiffEvent(billId: string, diff: Diff, success: () => void, error?: (msg: string) => void) {
    const diffsRef = ref(db, 'bills_v2/' + billId + '/' + 'diffs');
    await push(diffsRef, JSON.parse(JSON.stringify(diff))).then(
        success
    ).catch(
        // TODO: Better error messages
        () => (error ?? console.error)('Server rejected diff: ' + diff.action + ' by ' + diff.submitterUid + ' on ' + diff.itemGroupId)
    );
}

export function deleteBillFromDb(billId: string, success: () => void) {
    remove(ref(db, 'bills_v2/' + billId)).then(success).catch(() => console.error('could not delete'));
}