import { fmtPriceForDisplay } from "../../business_component_shared/helpers";
import { PresentedBillV2Item } from "../../business_component_shared/Types";

const CURRENCY_SYMBOL = '$';

export function snackbarMessage(itemQ: number, item: PresentedBillV2Item, type: 'add' | 'remove', customMode?: 'birthday') {
    const isBirthdayMode = customMode === 'birthday';
    const actionPrefix = type === 'add' ? 'Added' : 'Removed';

    const suffix = isBirthdayMode ? 'to the birthday tab' : 'to your tab';
    if (Number.isInteger(itemQ)) {
        return `${actionPrefix} ${itemQ} ${item.desc} ${suffix}`;
    }
    else {
        return `${actionPrefix} ${CURRENCY_SYMBOL}${fmtPriceForDisplay(itemQ * item.price)} of ${item.desc} to ${suffix}`;
    }
}