// note this isn't used

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const storage = getStorage();

const isDev = ['development', 'test'].includes(process.env.NODE_ENV);
const storageFolder = isDev ? 'dev_images' : 'prod_images';

export function uploadImage(image: Blob | File, id: String) {
    const storageRef = ref(storage, storageFolder + '/' + id);
    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, image).then((snapshot) => {
        console.log('Uploaded a blob or file!');
    });

}

export function getImageFromStorage(id: string, callback: (url: string) => void) {
    const storageRef = ref(storage, storageFolder + '/' + id);
    // 'file' comes from the Blob or File API
    getDownloadURL(storageRef).then((url) => {
        callback(url);
    }).catch((error) => {
        console.log(error);
    });
}