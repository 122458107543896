import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from '../../img/bsplitlogo.png';
import { EditableTaxTipAmount } from "../editbill/EditableTipTaxAmount";
import { NumDinersInput, StatefulNumDiners } from "../editbill/NumDinersInput";
import { ArrowCircleRight } from "phosphor-react";
import { getOnKeyDownEnter } from "../../business_component_shared/helpers";

export type QuestionAnswers = {
    tipAmount?: number,
    tipPercent?: number,
    numDiners?: number,
    isBirthday?: boolean,
}

function IsSomeonesBirthday(props: {
    setter: (a: boolean) => void
}) {
    return <Box>
        <Divider sx={{ marginX: 0, marginY: 1, borderBottomWidth: 1, borderColor: 'black' }} />

        <Box>
            <Box sx={{ textAlign: 'center' }}>
                <Typography fontWeight={600} fontSize={16}>Is it someone's birthday? 🥳</Typography>
            </Box>
            <Box>
                <Typography>They eat free! Their bill will be split evenly among all diners.</Typography>
            </Box>
        </Box>
        <DialogActions sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant='contained' sx={{ textTransform: 'none', marginBottom: 1 }} color='error' onClick={() => props.setter(false)}>
                <Typography>No</Typography>
            </Button>
            <Button variant='contained' sx={{ textTransform: 'none', marginBottom: 1 }} color='success' onClick={() => props.setter(true)}>
                <Typography>Yes</Typography>
            </Button>
        </DialogActions>
    </Box>

}

function TipAmountInput(props: { setter: (dollarValue?: number, rawPercentValue?: number) => void, isDoneLoading: boolean }) {
    // props.doneButton.props.onClick = () => {
    //     props.setter(0);
    // };

    const [tipAmount, setTipAmount] = useState<number | undefined>(undefined);
    const [tipPercentAmount, setTipPercentAmount] = useState<number | undefined>(undefined);

    function _setter(dollarValue: number, rawPercentValue?: number) {
        if (rawPercentValue) {
            setTipPercentAmount(rawPercentValue);
            setTipAmount(undefined);
        }
        else {
            setTipAmount(dollarValue);
            setTipPercentAmount(undefined);
        }
    }

    const doneButton = <Button
        sx={{
            borderRadius: 1,
            textTransform: 'none',
            ml: 1,
        }}
        disabled={!props.isDoneLoading || (tipAmount === undefined && tipPercentAmount === undefined)}
        variant='contained'
        size='small'
        color='secondary'
        onClick={() => {
            if (tipAmount !== undefined || tipPercentAmount !== undefined) {
                props.setter(tipAmount, tipPercentAmount);
            } else {
                alert('Please enter a valid tip amount');
            }
        }}
    >
        Next
    </Button>

    // TODO: Make pressing enter on the tip amount input box do the same thing as clicking the done button
    const etta = EditableTaxTipAmount({ currentValue: 0, defaultValue: 0, setter: _setter, sxProps: {}, billSubtotal: 100, label: 'Tip Amount', defaultToPercent: true })
    return <Box>
        <Divider sx={{ marginX: 0, marginY: 1, borderBottomWidth: 1, borderColor: 'black' }} />

        <Typography mb={1} mt={1}>How much did you tip?</Typography>
        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
            {
                etta[0]
            }
            {
                etta[2]
            }
            {
                doneButton
            }
        </Box>
    </Box>
}

function NumDinersInput2(props: {
    setter: (numDiners: number) => void,
}) {
    const [numDiners, setNumDiners] = useState<number | undefined>(undefined);
    return <Box>
        <Divider sx={{ marginX: 0, marginY: 1, borderBottomWidth: 1, borderColor: 'black' }} />

        <Typography mb={1} mt={1}>How many people should split the bill? Include yourself!</Typography>
        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
            {StatefulNumDiners({
                defaultNumDiners: numDiners,
                persistNumDiners: (numDiners) => {
                    setNumDiners(numDiners);
                },
                onKeyDown: getOnKeyDownEnter(() => {
                    if (numDiners) {
                        props.setter(numDiners);
                    }
                })
            })[1]}
            <IconButton onClick={() => {
                if (numDiners) {
                    props.setter(numDiners);
                }
                else {
                    alert('Please input how many people dined with you.');
                }
            }}>
                <ArrowCircleRight />
            </IconButton>
        </Box>
    </Box>;
}

// function NumDinersInput(props: {
//     setter: (numDiners: number) => void,
// }) {
//     return <Box>
//         <Divider sx={{ marginX: 0, marginY: 1, borderBottomWidth: 1, borderColor: 'black' }} />
//         <Typography mb={1} mt={1}>Input how diners are in your party...</Typography>
//         <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>

//             <IconButton onClick={() => props.setter(1)} />
//             {/* <Button
//                 sx={{
//                     borderRadius: 1,
//                     textTransform: 'none',
//                     ml: 1,
//                 }}
//                 disabled={props.isDoneLoading}
//                 variant='contained'
//                 size='small'
//                 color='secondary'
//             >

//             </Button> */}
//         </Box>
//     </Box>
// }

/** Dialog to display when image is being uploaded / processed */
export function LoadingQuestions(props: { isDoneLoading: boolean, onFinish: (a: QuestionAnswers) => void }) {
    const [questionNumber, setQuestionNumber] = useState(0);
    const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswers>({});

    useEffect(() => {
        if (questionNumber === 3) {
            props.onFinish(questionAnswers);
        }
    }, [questionNumber]);

    switch (questionNumber) {
        case 0:
            return <NumDinersInput2 setter={(v) => { setQuestionAnswers({ ...questionAnswers, numDiners: v }); setQuestionNumber(x => x + 1); }} />
        case 1:
            return <TipAmountInput isDoneLoading={props.isDoneLoading} setter={(v, percent) => { setQuestionAnswers({ ...questionAnswers, tipAmount: v?.valueOf(), tipPercent: percent }); setQuestionNumber(x => x + 1); }} />
        case 2:
            return <IsSomeonesBirthday setter={(isBirthday) => { setQuestionAnswers({ ...questionAnswers, isBirthday }); setQuestionNumber(x => x + 1) }}></IsSomeonesBirthday>
        default:
            return <Typography>Loading...</Typography>


    }

}