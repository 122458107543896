import { DoneAll, PrivacyTipSharp } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Pizza, Question, UserPlus, ChartPieSlice, UserCirclePlus, Confetti } from "phosphor-react";
import { useEffect, useState } from "react";
import { fmtPriceForDisplay, validateKeyPressForMoneyFields } from "../../../business_component_shared/helpers";
import { PresentedBillV2Item, User } from "../../../business_component_shared/Types";
import { quantityPaid, quantityUnpaid } from "../../../business_logic/pay_bill_v2_actions";
import { AddAppetizerDialog } from "./AddAppetizerDialog";
import { ItemPrice } from "./ItemPrice";
import { generateSuggestedAppetizerContributions, SuggestedContribution } from "./suggested_app_contributions";


export type AddItemContext = {
    item: PresentedBillV2Item,
    addQuantityOfItemToShoppingCart: (q: number) => void,
    payContext: SuggestedContribution // can have different type of pay context depending on item type, but now just appetizer
    uid?: string,
    isDisabled?: boolean,
    isBirthdayMode: boolean,
};

export type AddAppetizerContext = AddItemContext & {
    uidToUser: (uid: string) => User
    isShareMode?: boolean,
    numDiners?: number,
}

// Should this (and this file) be AddOrUpdateItem?
// Also, how should the neccesary context be passed to calculate the 
// suggested contribution if it is an appetizer?
// It could just be passed in as a prop or also passed in as a context
export type AddItemComponent = React.FC<AddItemContext>;
export type AddAppetizerComponent = React.FC<AddAppetizerContext>;


export const AddItem: AddItemComponent = (props) => {
    return (
        <Button disabled={props.isDisabled || quantityUnpaid(props.item) === 0} fullWidth variant='contained' sx={{ textTransform: 'none', borderRadius: 3 }} onClick={() => props.addQuantityOfItemToShoppingCart(1)}>
            <ItemPrice item={props.item} type={'unpaid'} />
        </Button>
        // <Button variant='contained' color='secondary' sx={{ textTransform: 'none', borderRadius: 3, margin: 0, padding: '5px', minHeight: 1, minWidth: 2 }} onClick={() => { props.addQuantityOfItemToShoppingCart(1); }}>
        //     <Pizza fontSize={'24px'} />
        // </Button>
    )
}

// const PriceInput = <TextField
// fullWidth
// size='small'
// sx={{ backgroundColor: INPUT_BACKGROUND_COLOR }}
// error={isPInvalid(p)}
// defaultValue={props.lineItem.cost}
// // type='number'
// onChange={(event) => {
//     const newP = Number(event.target.value)
//     props.lineItem.cost = newP;
//     setP(newP);
// }}
// InputProps={{
//     startAdornment: '$',
//     sx: { paddingLeft: 1.5 },
// }}
// inputProps={{
//     sx: { fontSize: TEXT_INPUT_FONT_SIZE },
// }}
// onKeyPress={validateKeyPressForMoneyFields}
// />


// This is where the split dialog is also defined, but should it be passed down as a prop?
export const AddAppetizer: AddAppetizerComponent = (props) => {
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    function addQuantityOfItemToCart(q: number) {
        setAddDialogOpen(false);
        props.addQuantityOfItemToShoppingCart(q);
    }

    const costChippedIn = quantityPaid(props.item) * props.item.price;

    // TODO: Hide cart swipe-up when this dialog is open
    return (
        <>
            <AddAppetizerDialog addQuantityOfItemToShoppingCart={addQuantityOfItemToCart} uidToUser={props.uidToUser} payContext={props.payContext} item={props.item} addDialogOpen={addDialogOpen} setAddDialogOpen={setAddDialogOpen} uid={props.uid} isBirthdayMode={props.isBirthdayMode} isShareMode={props.isShareMode} numDiners={props.numDiners} />

            <Button disabled={props.isDisabled || quantityUnpaid(props.item) === 0} fullWidth variant='contained' color={props.isBirthdayMode ? 'secondary' : 'pretty_purple'} sx={{ textTransform: 'none', borderRadius: 3, margin: 0, paddingY: 1, paddingX: 2, minHeight: 1, minWidth: 2 }} onClick={() => { setAddDialogOpen(true) }}>
                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center'>
                    <Typography mr={0.5}>Add </Typography>
                    <Box marginLeft={0} alignItems='center' justifyContent='center' display='flex'
                    // sx={{ transform: 'rotate(0.25turn)' }}
                    >
                        {
                            props.isBirthdayMode ? <Confetti fontSize={'20px'} /> : <UserCirclePlus fontSize={'20px'} />
                        }

                    </Box>
                </Box>
            </Button >
        </>
    )
}
