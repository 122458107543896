import React, { useState } from "react";
import { Box, Button, Typography, Dialog, DialogActions, DialogTitle, Fab, Grid, useTheme } from "@mui/material";
import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab';
import { PresentedBillV2, PresentedBillV2Items } from "../../business_component_shared/Types";
import { cartTotal, ItemsView, notItemsView, quantityInCart } from "../../business_logic/pay_bill_v2_actions";
import { CartPreviewItemRow } from "./item/ItemRow";
import venmoPic from "../../img/venmo_black.webp";
import cashappPic from '../../img/cashapp_logo.svg';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Aggregates, Header, DashedDivierWithMargin, DashedDivider } from "./Components";
import useWindowDimensions, { fmtPriceForDisplay } from "../../business_component_shared/helpers";
import { TabList, VariantPropOverrides } from "@mui/joy";
import ShareTabsInner from "./ShareToggle";
import { Share } from "phosphor-react";
import { isGeneratorObject } from "util/types";

function ShareTabs(props: { isMe: boolean, setIsMe: (a: boolean) => void }) {
    const [tabValue, setTabValue] = useState(Number(!props.isMe));
    const handleChange = (event: React.SyntheticEvent, newValue: string | number | boolean) => {
        setTabValue(Number(newValue));
        props.setIsMe(!Boolean(newValue));
    };

    return (
        <Box padding={1}>
            <ShareTabsInner tabValue={tabValue} handleChange={handleChange}></ShareTabsInner>
        </Box>
    )
}

function SectionHeader(props: { text: string }) {
    return (
        <Box sx={{ paddingY: 1.5 }}>
            <Box display={'flex'} flexDirection={'column'} alignItems='center'>
                <Typography variant="h6">{props.text}</Typography>
            </Box>
        </Box>
    )
}

function PayButton(props: {
    type: 'pay' | 'share',
    onPay: () => void,
    username: string | undefined,
    payType: string | undefined,
}) {
    switch (props.type) {
        //@ts-ignore
        case 'pay':
            if (props.payType == 'venmo')
                return (
                    <Fab onClick={props.onPay} variant="extended" sx={{ marginLeft: 'auto', padding: '30px', paddingTop: '35px', textTransform: 'none', flexDirection: 'column' }} color='info'>
                        <img src={venmoPic} style={{ maxWidth: '100px', filter: 'invert(1)' }}></img>
                        <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                            <Typography fontSize={'11px'} sx={{ marginLeft: 'auto', marginTop: '5px' }}>@{props.username}</Typography>
                        </Box>
                    </Fab>
                )
            if (props.payType == 'cashApp')
                return (
                    <Fab
                        onClick={props.onPay}
                        variant="extended"
                        // @ts-expect-error
                        color='cashapp'
                        sx={{ marginBottom: 1, padding: '30px', paddingTop: '35px', textTransform: 'none', flexDirection: 'row' }}
                    >
                        <img src={cashappPic} style={{ width: '30px', paddingBottom: '5px' }}></img>
                        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                            <Typography mb={1} variant="h6" style={{ paddingTop: '2px', paddingLeft: '5px' }} color='white'>Cash App</Typography>
                            <Typography fontSize={'11px'} sx={{ paddingTop: '0px' }}>@{props.username}</Typography>
                        </Box>
                    </Fab>
                )
        case 'share':
        default:
            return (
                <Fab onClick={props.onPay} variant="extended" sx={{ marginLeft: 'auto', padding: '30px', textTransform: 'none', flexDirection: 'column' }} color='secondary'>
                    <Box display={'flex'} flexDirection='row' alignItems={'center'} justifyContent='center'>
                        <Typography sx={{ paddingRight: 1 }}>Share Bill </Typography>
                        <Share size={20} />
                    </Box>
                </Fab>
            )
    }
}

function cartFromBill(bill: PresentedBillV2, cartView: ItemsView): { q: number, itemGroupId: string }[] {
    const cart: { q: number, itemGroupId: string }[] = [];

    for (const itemGroupId of Array.from(cartView)) {
        const q = quantityInCart(bill.items[itemGroupId]);
        cart.push({ q, itemGroupId });
    }

    return cart;
}

export function ConfirmCart(props: { bill: PresentedBillV2, cartView: ItemsView, type: 'cart' | 'notcart', hideHeader?: boolean }) {
    const cartForDB = cartFromBill(props.bill, props.cartView);

    const cartItems = Array.from(props.cartView);

    return (
        <Box padding={1} paddingBottom={2}>
            {!props.hideHeader && <Header bill={props.bill} sx={{ paddingBottom: 2 }} />}
            {
                cartItems.map(
                    itemGroupId => (
                        <Grid
                            container
                            style={{ gap: 15 }}
                            alignItems='center'
                            marginBottom='10px'
                            paddingX='10px'
                        >
                            <CartPreviewItemRow
                                type={props.type}
                                item={props.bill.items[itemGroupId]}
                            ></CartPreviewItemRow>
                        </Grid>
                    )
                )
            }
            {
                <Aggregates bill={props.bill} type={props.type} itemsView={props.cartView} solidDividers={true} />
            }
        </Box>
    )

}

function otherItemsView(billItems: PresentedBillV2Items) {
    return notItemsView(billItems);
}

function MyPanel(props: {
    bill: PresentedBillV2, cartItems: ItemsView, type: 'share' | 'pay',
}) {
    const { height, width } = useWindowDimensions();
    return <>
        <Box minWidth={Math.min(500, width * 0.8)}>
            <ConfirmCart bill={props.bill} cartView={props.cartItems} type='cart' hideHeader={props.type === 'share'}></ConfirmCart>
        </Box>
        <DashedDivider />
    </>

}

function OtherPanel(props: {
    bill: PresentedBillV2, cartItems: ItemsView, type: 'share' | 'pay',
}) {
    const { height, width } = useWindowDimensions();

    return <>
        <Box minWidth={Math.min(500, width * 0.8)}>
            <ConfirmCart bill={props.bill} cartView={otherItemsView(props.bill.items)} type='notcart' hideHeader={props.type === 'share'}></ConfirmCart>
        </Box>
        <DashedDivider />
    </>
}

export function ConfirmCartDialog(props: {
    bill: PresentedBillV2,
    cartItems: ItemsView,
    open: boolean,
    type: 'share' | 'pay',
    close: () => void,
    onPay: () => void,
}) {
    const [isMe, setIsMe] = useState(true);
    const paymentType = props.bill.paymentOptions?.paymentType
    console.log(paymentType)
    const username = props.bill.paymentOptions?.paymentUsername;

    return <React.Fragment><Dialog
        open={props.open}
        onClose={props.close}
        scroll="paper"
        sx={{
            "& .MuiDialog-container": {
                alignItems: "flex-start",
            },
        }}
        fullWidth
        PaperProps={{ sx: { marginTop: "max(10px, 10%)" } }}
    >

        {props.type === 'share' &&
            <ShareTabs isMe={isMe} setIsMe={setIsMe} />
        }
        {props.type === 'pay' &&
            <SectionHeader text={'My tab'} />
        }
        {(props.type === 'pay' || (props.type === 'share' && isMe)) &&
            <MyPanel bill={props.bill} cartItems={props.cartItems} type={props.type} />
        }
        {(props.type === 'share' && !isMe) &&
            <OtherPanel bill={props.bill} cartItems={props.cartItems} type={props.type} />

        }

        <Box display={'flex'} flexDirection='column' marginY='15px' alignItems='center' >
            <Box display={'flex'} flexDirection='column' alignItems={'center'} >
                <PayButton type={props.type} onPay={props.onPay} username={username} payType={paymentType} />
            </Box>
        </Box>

    </Dialog>
    </React.Fragment>

}

export function ConfirmPaid(props: {
    bill: PresentedBillV2,
    cartItems: ItemsView,
    open: boolean,
    onCancel: () => void,
    onConfirm: () => void,
}) {
    const { open } = props;
    // TODO( mkake this better, maybe clear timeout if open becomees false againi)
    // useEffect(() => {
    //     if (open)
    //         setTimeout(() => props.onConfirm(), 30000);
    // }, [open]);
    return <Dialog open={props.open} onClose={props.onCancel}>
        <DialogTitle>
            Did you pay {props.bill.ownerUid}?
        </DialogTitle>
        <DialogActions>
            <Button onClick={props.onConfirm}>Yes</Button>
            <Button onClick={props.onCancel}>No</Button>
        </DialogActions>
    </Dialog>
}
