import { BillEditableItem, as, BillEditable } from '../../business_component_shared/Types';
import { ImgData } from './orientation';

type coordinate = { x: number, y: number, h: number, message: string }
type coordinates = coordinate[];
type point = { x: number, y: number }
const dotRegex = /\.\d{2}/;
const commaRegex = /,\d{2}/;

export function structureText(detections: any[], imgD: ImgData) {
    switch (imgD.orientation) {
        case 1: {
            // alert('orientation ' + 1)
            var words: coordinates = detections.map(text => as<coordinate>({
                x: text.boundingPoly.vertices[text.boundingPoly.vertices.length - 1].x as number,
                y: text.boundingPoly.vertices[text.boundingPoly.vertices.length - 1].y as number,
                h: Math.abs(text.boundingPoly.vertices[text.boundingPoly.vertices.length - 1].y - text.boundingPoly.vertices[0].y) as number,
                message: text.description as string
            }));;
            let text = findLineBreaks(words.sort(sortByY))
            return text
        }
        case 6: {
            // alert('orientation ' + 6)
            var words: coordinates = detections.map(text => as<coordinate>({
                x: imgD.height - (text.boundingPoly.vertices[0].y as number),
                y: text.boundingPoly.vertices[0].x as number,
                h: Math.abs(text.boundingPoly.vertices[text.boundingPoly.vertices.length - 1].x - text.boundingPoly.vertices[0].x) as number,
                message: text.description as string
            }));;
            let text = findLineBreaks(words.sort(sortByY))
            return text
        }
    }
    alert('error in orientation')
    var words: coordinates = detections.map(text => as<coordinate>({
        x: text.boundingPoly.vertices[3].x as number, // bottom left 
        y: text.boundingPoly.vertices[3].y as number, // bottom left
        h: Math.abs(text.boundingPoly.vertices[text.boundingPoly.vertices.length - 1].y - text.boundingPoly.vertices[0].y) as number,
        message: text.description as string
    }));;
    console.log(words)
    let text = findLineBreaks(words.sort(sortByY))
    return text
}

function sortByY(c1: coordinate, c2: coordinate) {
    if (c1.y > c2.y) {
        return 1;
    }
    if (c1.y < c2.y) {
        return -1;
    }
    return 0;
}

// adding linear interpolation 
// when one point has been seen we do validation against height 
// after two points have been seen we check for the diffrence 
// with the predicted y based on x value
function findLineBreaks(words: coordinates) {
    var runningAverage = words[0].y;
    var leftMost = { x: words[0].x, y: words[0].y };
    var rightMost = { x: -1, y: -1 };
    var numSeen = 1;
    var lastLineBreak = 0;
    var text = []
    // console.log(words)
    for (let index = 1; index < words.length; index++) {
        // If the diff between the linear interpolation and the running average is greater then 
        // the height use the running average 
        let interpPred = linearInterpolatePrediction(leftMost, rightMost, words[index])
        let interpolateIsValid = (Math.abs(runningAverage - interpPred) < words[index].h) && numSeen >= 2
        let threshold = words[index].h
        if (interpolateIsValid && Math.abs(words[index].y - interpPred) > threshold) {
            // line break
            // sort bag of words by x and push line to text
            text.push(words.slice(lastLineBreak, index).sort(sortByX).map(c => c.message).join(' '));
            lastLineBreak = index;
            numSeen = 1;
            runningAverage = words[index].y;
            leftMost = { x: words[index].x, y: words[index].y };
            rightMost = { x: -1, y: -1 };
        }
        else if (Math.abs(words[index].y - runningAverage) > threshold) {
            // line break
            // sort bag of words by x and push line to text
            text.push(words.slice(lastLineBreak, index).sort(sortByX).map(c => c.message).join(' '));
            lastLineBreak = index;
            numSeen = 1;
            runningAverage = words[index].y;
            leftMost = { x: words[index].x, y: words[index].y };
            rightMost = { x: -1, y: -1 };
        }
        else {
            runningAverage = (words[index].y + (numSeen * runningAverage)) / (numSeen + 1);
            // update left and right most for a more reslient line predictor
            if (words[index].x > rightMost.x) {
                rightMost = { x: words[index].x, y: words[index].y };
            } else if (words[index].x < leftMost.x) {
                leftMost = { x: words[index].x, y: words[index].y };
            }
            numSeen += 1;
        }
        // console.log(words[index], runningAverage, leftMost, rightMost, interpolateIsValid.toString())
    }

    return text
}

function linearInterpolatePrediction(savedAnchor: point, lastSeen: point, newCord: coordinate) {
    let m = (savedAnchor.y - lastSeen.y) / (savedAnchor.x - lastSeen.x)
    let pred = m * (newCord.x - savedAnchor.x) + savedAnchor.y
    // console.log("pred", pred)
    return pred
}

function sortByX(c1: coordinate, c2: coordinate) {
    if (c1.x > c2.x) {
        return 1;
    }
    if (c1.x < c2.x) {
        return -1;
    }
    return 0;
}

function firstIfNonNan(list: string[]): number | undefined {
    if (list.length >= 1) {
        var quantity = getPrices(list[0]);
        if (!Number.isNaN(quantity)) {
            return quantity
        }
        else return undefined
    }
}

function getPrices(item: string): number | undefined {
    var price = Number((parseFloat(item.substring(item.lastIndexOf("$") + 1))).toFixed(2))
    // console.log("price ", price)
    if (item.lastIndexOf("$") === -1 || price === undefined) {
        const words = item.split(' ');
        console.log(words);
        price = Number(parseFloat(words.filter(word => dotRegex.test(word) || commaRegex.test(word))[0]).toFixed(2));
    }
    if (Number.isNaN(price)) return undefined
    return price
}


function parseItem(item: string): BillEditableItem {
    var desc = item;
    var words = desc.split(' ');
    // console.log(words)
    // parse first word as a number
    var quantity = parseInt(words[0], 10);
    if (isNaN(parseFloat(words[0]))) {
        // If the first word is not a number, check if there is an 'x' in the list 
        // and choose the following number as the quantity 
        var times = words.indexOf('x'); //todo error with spaced string i.e. ' x ' || 'x '
        if (times !== -1) {
            quantity = parseInt(words[times + 1], 10);
            desc = words.slice(0, times).join(" ");
        }
    } else {
        desc = words.slice(1).join(" ");
    }
    // console.log(words)

    words = desc.split(' ');
    var price = Number((parseFloat(item.substring(item.lastIndexOf("$") + 1))).toFixed(2));
    // console.log(words)
    // If there is no dollar sign and the pice is undefined look for a word including . followed by two digits
    // use this as the price 
    if (item.lastIndexOf("$") === -1 || price === undefined) {
        price = Number(parseFloat(words.slice().reverse().filter(word => dotRegex.test(word) || commaRegex.test(word))[0]).toFixed(2));
        // console.log(price)
        desc = words.filter(word => !(dotRegex.test(word) || commaRegex.test(word))).join(" ");
    } else {
        desc = words.filter(word => !word.includes("$")).filter(word => !word.includes(String(price))).join(" ");
    }

    if (isNaN(quantity) && isNaN(price)) {
        return as<BillEditableItem>({ desc: desc, cost: undefined, quantity: 1 })
    } else if (isNaN(quantity)) {
        return as<BillEditableItem>({ desc: desc, cost: price, quantity: 1 })
    } else if (isNaN(price)) {
        return as<BillEditableItem>({ desc: desc, cost: undefined, quantity: quantity })
    }
    return as<BillEditableItem>({ desc: desc, cost: price, quantity: quantity })
}

const non_item_words = [" total", "total ", " tax", "tax ", "tip ", " tip", "gratutity", "%", "15%", "18%", "20%", "25%", "amount"]

// item total comes before 

export function unstructuredReceiptLinesToBill(imageLines: string[]): BillEditable {
    // TODO: more exact 
    const hasMoney = imageLines.filter(word => word.includes("$") || dotRegex.test(word) || commaRegex.test(word));
    // console.log(hasMoney)

    const resturantName = imageLines[0]

    // eslint-disable-next-line
    const subtotalList = hasMoney.filter(word => word.toLowerCase().includes("subtotal"));

    const taxList = hasMoney.filter(word => word.toLowerCase().includes("tax"));

    var lineItems: BillEditableItem[] = [];
    for (var i = 0; i < hasMoney.length; i++) {
        if (!non_item_words.every(word => !hasMoney[i].toLowerCase().includes(word))) {
            break;
        }
        lineItems.push(parseItem(hasMoney[i]))
    }
    console.log(lineItems)

    const lineItemsWithId: { [itemId: string]: BillEditableItem } = {}
    var i = 1;
    for (const li of lineItems) {
        const tmpItemId = i;
        lineItemsWithId[tmpItemId] = li;
        i++;
    }

    // @ts-expect-error assign isBirthday later
    return {
        items: lineItemsWithId,
        taxAmount: firstIfNonNan(taxList),
        tipAmount: undefined,
        timestamp: Date.now(),
        restaurantName: resturantName,
        subtotal: firstIfNonNan(subtotalList),
    };
}
