type Event = {
    name: string;
    timestamp: number;
    tags: { [key: string]: string };
}

let events: Array<Event> = [];


function addEvent(name: string, tags?: { [key: string]: any }) {
    events.push({ name, timestamp: Date.now(), tags: tags ?? {} });
}

function flushEvents() {
    // Send events to the server
    // Generate a session id here and flush it too
    const startTimestamp = events[0].timestamp;
    console.log('Flushing events: ');
    const thingsToAlert: Array<string> = [];
    for (const event of events) {
        const timestamp = event.timestamp - startTimestamp;
        const msg = `${timestamp}ms: ${event.name} ${JSON.stringify(event.tags)}`;
        console.log(msg);
        thingsToAlert.push(msg);
    }
    // alert(thingsToAlert.join('\n'));
    events = [];
}

export { addEvent, flushEvents };