import { fmtPriceForDisplay } from "../../../business_component_shared/helpers";
import { PresentedBillV2, PresentedBillV2Item } from "../../../business_component_shared/Types";
import { billSubtotal, quantityUnpaid } from "../../../business_logic/pay_bill_v2_actions";

// TODO: Make message a react component to add more styling for particular examples
export type SuggestedContribution = { message: string[], amount: number }[];

function generateSuggestedContribution(item: PresentedBillV2Item, numDiners: number): SuggestedContribution {
    const qUnpaid = quantityUnpaid(item);
    const remainingPayment = qUnpaid * item.price;

    const totalPrice = item.price * item.quantity;
    const unitPayment = totalPrice / numDiners;

    // let suggested = [unitPayment, unitPayment * 2];
    // suggested = suggested.filter(x => x < remainingPayment);
    // suggested.push(remainingPayment);

    // return suggested.map(x => ({ message: `$${x.toFixed(2)}`, amount: x }));

    let suggested2: SuggestedContribution = [{ message: [`1/${numDiners}`, `($${fmtPriceForDisplay(unitPayment)})`], amount: unitPayment }, { message: [`1/2`, `($${fmtPriceForDisplay(totalPrice / 2)})`], amount: (totalPrice / 2) }];
    suggested2 = suggested2.filter(x => x.amount < remainingPayment);
    suggested2.push({ message: [`$${fmtPriceForDisplay(remainingPayment)}`, `The rest`], amount: remainingPayment });

    const suggestedUnique: SuggestedContribution = [];
    for (const s of suggested2) {
        if (!suggestedUnique.some(x => x.amount === s.amount)) {
            suggestedUnique.push(s);
        }
    }

    return suggestedUnique;
}

// TODO: This should be a function that takes in a bill and returns a list of 
// contributions for each itemGroupId (if its an appetizer)
export function generateSuggestedAppetizerContributions(bill: PresentedBillV2): { [itemGroupId: string]: SuggestedContribution } {
    const contrs: { [itemGroupId: string]: SuggestedContribution } = {};

    for (const [itemGroupId, item] of Object.entries(bill.items)) {
        contrs[itemGroupId] = generateSuggestedContribution(item, bill.numDiners ?? 4);
    }
    return contrs;
}