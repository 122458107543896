import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { TextField, ButtonGroup, Typography, Box, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { BillEditableItem } from '../business_component_shared/Types';
import { Add, Remove, DeleteOutline } from '@mui/icons-material'
import useWindowDimensions, { validateKeyPressForMoneyFields } from '../business_component_shared/helpers';

export const INPUT_BACKGROUND_COLOR = '#f7f7f7';

function isQInvalid(q: number | undefined) {
    return (q === undefined || Number.isNaN(q) || q === 0);
}

function isDInvalid(d: string | undefined) {
    return (d === undefined || d === '');
}

function isPInvalid(p: number | undefined) {
    return (p === undefined || Number.isNaN(p) || p === 0);
}

export function changeDialogStatusMessage(status: 'add_item' | 'remove_item'): string {
    switch (status) {
        case 'add_item':
            return 'Added an item'
        case 'remove_item':
            return 'Removed an item'
    }
}

function DeleteButton(props: { onClick: () => void }) {
    return (
        <IconButton
            sx={{ backgroundColor: INPUT_BACKGROUND_COLOR, color: 'gray', border: 'none', padding: '4px', marginRight: '0px' }}
            size='small'
            onClick={props.onClick}
        >
            <DeleteOutline></DeleteOutline>
        </IconButton>
    )
}


export function isLineItemInvalid(lineItem: BillEditableItem) {
    return isQInvalid(lineItem.quantity) || isDInvalid(lineItem.desc) || isPInvalid(lineItem.cost)
}

export function warnQuantity(lineItem: BillEditableItem) {
    return (lineItem.quantity === undefined || Number.isNaN(lineItem.quantity ) || lineItem.quantity >= 10);
}

/**
 * A bill item that can be edited
 * 
 * TODO: James -- stop editing the line item properties
 * and instead use a function to call with an updated line item
 */
export function EditableItem(props: { tmpItemId: string, lineItem: BillEditableItem, removeItem: (itemId: string) => void, onUpdateItem?: () => void }) {
    const theme = useTheme();
    const { onUpdateItem } = props;
    const [q, setQ] = useState(props.lineItem.quantity ?? 0);
    const [d, setD] = useState(props.lineItem.desc)
    const [p, setP] = useState(props.lineItem.cost);
    const { width } = useWindowDimensions();

    const TEXT_INPUT_FONT_SIZE = width < 550 ? 12 : 15;

    // propogate item updates to parent
    useEffect(
        () => {
            props.lineItem.cost = p;
            props.lineItem.quantity = q;
            props.lineItem.desc = d;
            onUpdateItem?.();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [q, d, p, onUpdateItem]
    )

    function incrementQ() {
        setQ(Math.min(q + 1, 99));
        onUpdateItem?.();
    }

    function decrementQ() {
        setQ(q - 1 < 0 ? 0 : q - 1);
        onUpdateItem?.();
    }

    function updateQ(newQ: string) {
        let _newQ = Number(newQ);
        if (Number.isNaN(_newQ)) {
            _newQ = 0;
        }
        _newQ = Math.max(Math.min(_newQ, 99), 0);
        setQ(_newQ);
        onUpdateItem?.();
    }

    const QuantityInput2 = <ButtonGroup
        fullWidth
        sx={{
            border: isQInvalid(q) ? '1px solid ' + theme.palette.error.main : '1px solid #CACACA',
            alignItems: 'center',
            backgroundColor: INPUT_BACKGROUND_COLOR,
        }}
    >
        <IconButton
            sx={{ backgroundColor: INPUT_BACKGROUND_COLOR, color: 'gray', border: 'none', padding: '2px', marginLeft: '0px' }}
            size='small'
            onClick={
                () => {
                    if (q === 0) {
                        props.removeItem(props.tmpItemId);
                    }
                    else {
                        decrementQ();
                    }
                }
            }
        >
            {q === 0 ? <Remove onClick={() => props.removeItem(props.tmpItemId)}></Remove> : <Remove onClick={decrementQ}></Remove>}
        </IconButton>
        <Box
            sx={{
                textAlignLast: 'center',
            }}
        >
            <TextField
                sx={{
                    backgroundColor: INPUT_BACKGROUND_COLOR,
                    border: 'none', '& fieldset': { border: 'none' },
                    marginX: 0,
                    paddingX: 0,
                }}
                // Attempting to turn off ios predictive text
                autoCorrect='off'
                autoComplete='off'
                onChange={(event) => {
                    updateQ(event.target.value)
                }}
                inputProps={{
                    sx: { fontSize: TEXT_INPUT_FONT_SIZE, paddingX: 0 },
                }}
                value={q}
                size='small'
            />
        </Box>
        <IconButton
            size='small'
            sx={{ backgroundColor: INPUT_BACKGROUND_COLOR, color: 'gray', border: 'none', padding: '2px', marginRight: '0px' }}
            onClick={incrementQ}><Add onClick={incrementQ}></Add>
        </IconButton>
    </ButtonGroup >
    const DescInput = <TextField
        fullWidth
        size='small'
        sx={{ backgroundColor: INPUT_BACKGROUND_COLOR }}
        error={isDInvalid(d)}
        defaultValue={props.lineItem.desc}
        onChange={(event) => {
            props.lineItem.desc = event.target.value;
            setD(event.target.value);
        }}
        inputProps={{
            sx: { fontSize: TEXT_INPUT_FONT_SIZE, paddingLeft: 1 },
        }}
    />

    const PriceInput = <TextField
        fullWidth
        size='small'
        sx={{ backgroundColor: INPUT_BACKGROUND_COLOR }}
        error={isPInvalid(p)}
        defaultValue={props.lineItem.cost}
        // type='number'
        onChange={(event) => {
            const newP = Number(event.target.value)
            props.lineItem.cost = newP;
            setP(newP);
        }}
        InputProps={{
            startAdornment: '$',
            sx: { paddingLeft: 1.5 },
        }}
        inputProps={{
            sx: { fontSize: TEXT_INPUT_FONT_SIZE },
        }}
        onKeyPress={validateKeyPressForMoneyFields}
    />


    return (
        <EditableBillRow
            setSplit={(isSplit) => { props.lineItem.isAppetizer = isSplit }}
            desc={DescInput}
            price={PriceInput}
            quantity={QuantityInput2}
            deleteButton={<DeleteButton onClick={() => { if (window.confirm('Are you sure you want to delete this item?')) props.removeItem(props.tmpItemId) }}></DeleteButton>}
        />
    )
}


export function EditableBillRow(props: { setSplit: (isSplit: boolean) => void, desc?: React.ReactNode, price?: React.ReactNode, quantity?: React.ReactNode, deleteButton?: React.ReactNode }) {
    return (
        <React.Fragment>
            <Grid xs={3} sm={2.5} md={3} >
                {props.quantity}
            </Grid>
            <Grid xs={5.5} sm={7} md={7}>
                {props.desc}
            </Grid>
            <Grid xs={3} sm={2.5} md={2}>
                {props.price}
            </Grid>
            <Grid xs={3} sm={3} md={3}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    {props.deleteButton}
                    <Typography ml={1} mr={2}>Remove</Typography>
                    {/* <Switch aria-label='Split this item' size='small' onChange={event => props.setSplit(event.target.checked)} />
                    <Typography ml={1}>Appetizer</Typography> */}
                </Box>
            </Grid>
        </React.Fragment>
    )

}