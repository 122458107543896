import EXIF from 'exif-js';

import { addEvent } from '../backend/events_tracker';
import { as } from '../backend/helpers';


export type ImgData = { orientation: number, width: number, height: number }

const emptyImgData: ImgData = { orientation: 0, width: 600, height: 900 };

export function getOrientation(img: Blob, orientationCallback: (imgData: ImgData) => void) {
    try {
        addEvent('start: getOrientation');
        // @ts-expect-error
        EXIF.getData(img, function () {
            // @ts-expect-error
            var orientation = EXIF.getTag(this, 'Orientation');

            const htmlImgObject = new Image();
            htmlImgObject.src = URL.createObjectURL(img);

            htmlImgObject.onload = function () {
                console.log(EXIF.getAllTags(this));

                let width = htmlImgObject.width;
                let height = htmlImgObject.height;
                // alert('width ' + width + ' height ' + height);
                orientation = orientation || 1 // if orentation undefined return 1 
                // alert('orientation ' + orientation);
                const imgD = as<ImgData>({ orientation: orientation as number, width: width, height: height });

                addEvent('end: getOrientation');
                orientationCallback(imgD);
            }
        });
    } catch (e) {
        orientationCallback(emptyImgData)
    }
}
