import { SxProps } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";

export function GridArrangement(props: { arrangement: Array<{ xsSize: number, smSize?: number }> }) {
    const { arrangement } = props;
    return (nodes: Array<React.ReactNode>) => (
        <Grid2
            container
            xs={12}
            sx={{ minHeight: '30px' }}
            justifyContent="center"
            alignItems="center"
        >
            {arrangement.map((item, index) => <Grid2 xs={item.xsSize} sm={item.smSize} key={index}>{nodes[index]}</Grid2>)}
        </Grid2>
    )
}

export function ABArrangementBase(props: { children: [React.ReactNode, React.ReactNode], a: number, b: number, sxProps?: SxProps, smA?: number, smB?: number }) {
    return (
        <Grid2
            container
            xs={12}
            sx={{ minHeight: '30px', ...props.sxProps }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid2 xs={props.a} sm={props.smA ?? props.a}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'left'
                }}>
                    {props.children[0]}
                </div>
            </Grid2>
            <Grid2 xs={1} sm={1}>{' '}</Grid2>
            <Grid2 xs={props.b} sm={props.smB ?? props.b}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'right'
                }}>
                    {props.children[1]}
                </div>
            </Grid2>
        </Grid2>
    )
}

