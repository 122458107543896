// Logic for uploading an image to firebase storage and getting the ocr text 
// from the image back from firebase firestore, then calls parsers

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { addEvent, flushEvents } from "../backend/events_tracker";
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { firestore } from '../backend/firebase-config'
import { BillEditable } from "../../business_component_shared/Types";
import { getOrientation } from "./orientation";
import { structureText, unstructuredReceiptLinesToBill } from "./ocr_and_parsing";
import { uuidv4 } from "@firebase/util";

const emptyBill: BillEditable = { items: { 0: { quantity: 1 } }, timestamp: Date.now(), isBirthday: false }

const storage = getStorage();

const isDev = ['development', 'test'].includes(process.env.NODE_ENV);
const storageFolder = isDev ? 'dev_images' : 'prod_images';

export const testBill: BillEditable = {
    items: {
        '0': {
            quantity: 2,
            desc: 'Edamame',
            cost: 5.99,
            isAppetizer: true,
        },
        '1': {
            quantity: 1,
            desc: 'Chicken Teriyaki',
            cost: 12.99,
            isAppetizer: false,
        },
        '2': {
            quantity: 1,
            desc: 'Sushi Platter',
            cost: 12.99,
            isAppetizer: false,
        },
        '3': {
            quantity: 1,
            desc: 'Karage Chicken',
            cost: 7.99,
            isAppetizer: true,
        },
        '4': {
            quantity: 1,
            desc: 'Sake',
            cost: 7.99,
            isAppetizer: true,
        },
        '5': {
            quantity: 1,
            desc: 'Rice Bowl',
            cost: 9.99,
            isAppetizer: false,
        }
    },
    timestamp: Date.now(),
    restaurantName: 'Sushi House',
    isBirthday: true,
    numDiners: 3,
    taxAmount: 3.27,
    tipPercent: 20, // lol ---- this gets read as a percent
    tipAmount: 20,
}


async function uploadImage(image: Blob | File, id: string) {
    const storageRef = ref(storage, storageFolder + '/' + id);
    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, image).then((snapshot) => {
        addEvent('uploaded image to firebase storage');
        console.log('Uploaded a blob or file!');
        getDownloadURL(snapshot.ref).then((url) => {
            // @ts-expect-error
            window.imageUrl = url;
            addEvent('get firebase storage download URL');
            console.log('File available at', url);
            console.log("Uploaded image:", console.time())
            setDoc(doc(firestore, "photos", id), {
                url: url,
                bucket: storageRef.bucket,
                fullPath: storageRef.fullPath,
                date: new Date()
            }).then(
                () => {
                    addEvent('uploaded image URL to firestore')
                }
            );
        });
    })
}

const NO_OP = false;

export const handleImageChangeAfterAuthWithContext = (
    context: {
        setLoadingHasBegun: (loadingHasBegun: boolean) => void,
        setLoadingIsFinished: (loadingIsFinished: boolean) => void,
        setLoadingText: (loadingText: string) => void,
        setBill: (bill: BillEditable) => void,
    }
) => {
    return async function (
        image: File | undefined
    ) {
        const { setLoadingHasBegun, setLoadingText, setBill, setLoadingIsFinished } = context;

        if (NO_OP) {

            setLoadingHasBegun(true);
            setLoadingText('Nothing will happen... this is NO_OP mode');
            // @ts-expect-error
            window.imageUrl = 'https://firebasestorage.googleapis.com/v0/b/bill-split-foo.appspot.com/o/dev_images%2F60068568-f07e-4db2-ad0d-958ac8bf6ed5?alt=media&token=84c212d2-8f6c-4cf1-a3a7-b9941c02ece9'
            // Set timeout here and mock the bill setting with a fake bill
            setTimeout(
                () => {
                    setLoadingText('NO_OP mode. Sample bill has been set!');
                    setBill(testBill);
                    setLoadingIsFinished(true);
                }
                , 3000)
            return;
        }
        if (image) {
            setLoadingHasBegun(true);
            const uniqueId = uuidv4();
            // @ts-expect-error Hack to pass billId to uploadImage
            window.billId = uniqueId;
            addEvent('generated uuid for file', { uuid: uniqueId });
            setLoadingText('Getting image orientation...');
            getOrientation(image, (imgD) => {
                var text: string[] = [];
                setLoadingText('Reading your receipt...');
                onSnapshot(doc(firestore, "photos", uniqueId), (doc) => {
                    addEvent('got doc from firestore', { docMeta: doc.metadata });
                    const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                    console.log("Source data " + source + ":", doc.data());
                    if (source == "Server") {
                        const data = doc.data();
                        console.log("New ocr", doc.data());
                        if (data?.textAnnotations != null) {
                            setLoadingText('Parsing your receipt...');
                            console.log("Uploaded image returned ocr:", console.timeEnd())
                            console.log("Starting text parsing:", console.time())
                            text = JSON.parse(data.textAnnotations);
                            console.log("ocr text:", data.textAnnotations);
                            let sText = structureText(text, imgD);
                            addEvent('finished structured text', { textLength: sText.length });

                            console.log("sText:", sText);
                            let parsed = unstructuredReceiptLinesToBill(sText);
                            addEvent('finished parsing', { itemsLength: parsed.items.length });

                            console.log("parsed", parsed);
                            console.log("Finsished text parsing:", console.timeEnd())
                            setBill(parsed);
                            setLoadingText('Finished parsing your receipt!');
                            setLoadingIsFinished(true);
                            addEvent('finished loading: setBill hook completed');
                            flushEvents();
                            return;
                        }
                    }
                });
            });
            uploadImage(image, uniqueId);
            // alert(orientation)
            // console.log("orientation", orientation)
            // setBill(emptyBill)
            // return
        }
        else {
            console.log('no image, could be entered manually');
            setLoadingHasBegun(true);
            setLoadingIsFinished(true);
            // This is when the manual entry redirects to edit
            setBill(emptyBill);
        }
    }
};
