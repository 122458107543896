import { Typography } from "@mui/material";
import { PresentedBillV2Item } from "../../../business_component_shared/Types";
import { quantityUnpaid } from "../../../business_logic/pay_bill_v2_actions";

export function ItemDesc(props: { item: PresentedBillV2Item, context: 'cart' | 'notcart' | 'birthday' }) {
    const qUnpaid = quantityUnpaid(props.item);
    const qBirthday = props.item.birthdayQuantity ?? 0;

    const sx = (props.context === 'birthday' && qBirthday === 0) || (qUnpaid === 0 && props.context === 'notcart') ? { color: 'gray' } : {};
    return <Typography sx={sx}>{props.item.desc}</Typography>
}