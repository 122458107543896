import React, { useEffect, useState } from 'react';
import './App.css';
import Main from './components/Main';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { firestore } from '../src/business_logic/backend/firebase-config';
import { collection, doc, setDoc, getDocs, query } from "firebase/firestore";
import { BugReportButton } from './components/BugReport';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }

  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    neutral_light: PaletteOptions['primary'];
    banana: PaletteOptions['primary'];
    white: PaletteOptions['primary'];
    royal_blue: PaletteOptions['primary'];
    pretty_purple: PaletteOptions['primary'];
    cashapp: PaletteOptions['primary'];

  }

  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    banana: true;
    neutral_light: true;
    white: true;
    neutral: true;
    pretty_purple: true;
  }
}

const bananaSplitTheme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#de9bd9',
      darker: '#c979c4',
      contrastText: '#ffffff',

    },
    neutral: {
      main: '#222222',
      contrastText: '#fff',
    },
    neutral_light: {
      main: '#fafafa',
      contrastText: '#222222',
    },
    // errorLight: {
    //   main: '#fce4ec',
    //   contrastText: '#c62828',
    // },
    banana: {
      main: '#fdd31d',
      contrastText: '#fff'
    },
    white: {
      main: '#fff',
      contrastText: '#222222',
    },
    royal_blue: {
      main: '#3f51b5',
      contrastText: '#fff',
    },
    pretty_purple: {
      main: '#5e54d0',
      contrastText: '#fff',
      darker: '#4c3fb8',
      light: '#6f65e8',
      dark: '#3f3aa8',
    },
    cashapp: {
      main: '#00D632',
      dark: '#00D632',
      darker: '#00D632',
      light: '#00D632',
      contrastText: '#fff',
    }
  },
  typography: {
    "fontFamily": `'Inter', sans-serif`,
    "fontSize": 11,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 550,
  },
});



/**
 * Applies to every page in our app
 */
function App() {
  return (
    <ThemeProvider theme={bananaSplitTheme}>
      <BrowserRouter>
        <Box>
          <div>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <Main />
            <BugReportButton />
          </div>
        </Box>

      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
