import { Box, Divider, SxProps, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import React from "react";


const FIRST_COL_WIDTH = 8;
const FIRST_NON_ITEM_WIDTH = 6;
const SECOND_COL_WIDTH = 3;
const THIRD_COL_WIDTH = 1;

export function DashedDivider(props: { sx?: SxProps }) {
    return <Grid xs={12} sx={props.sx}>
        <Divider sx={{ borderBottomWidth: 0.5, borderStyle: 'dashed', borderColor: 'black' }} />
    </Grid>;
}

export function SolidDivider(props: { sx?: SxProps }) {
    return <Grid xs={12} sx={props.sx}>
        <Divider sx={{ borderBottomWidth: 0.5, borderStyle: 'solid', borderColor: 'black' }} />
    </Grid>;
}


export function DashedDivierWithMargin() {
    return (
        <Grid xs={12}>
            <Divider sx={{ marginX: 2, marginTop: 1, borderBottomWidth: 0.5, borderStyle: 'dashed', borderColor: 'black' }} />
        </Grid>
    )
}

export function CenteredRowBox(props: { child: React.ReactNode }) {
    return (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {props.child}
    </Box>);
}

export function BillHeader() {
    return (
        <Grid
            container
            xs={12}
            style={{ minHeight: '30px' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid xs={FIRST_COL_WIDTH}>
                <Typography variant='body1' sx={{ textDecoration: 'underline' }}>ITEM</Typography>
            </Grid>
            <Grid xs={SECOND_COL_WIDTH} >
                <Typography variant='body1' sx={{ textDecoration: 'underline' }}>PRICE</Typography>
            </Grid>
            <Grid xs={THIRD_COL_WIDTH} >
                <Typography variant='body1' sx={{ textDecoration: 'underline' }}>PERSON</Typography>
            </Grid>
        </Grid>
    );
}

export function PayBillItemRow(props: { key: string, children?: React.ReactNode }) {
    return <Grid
        container
        spacing={2}
        xs={12}
        style={{ minHeight: '50px' }}
        justifyContent="center"
        alignItems="center"
        key={props.key + 'Grid'}
    >{props.children}</Grid>
}

/**
 * e.g. tax / tip / total
 */
export function ThreeColRow<T>(props: { name: string, value: T, formatValue: (a: T) => string }) {
    return (
        <Grid
            container
            xs={12}
            style={{ minHeight: '30px' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid xs={FIRST_NON_ITEM_WIDTH}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'left'
                }}>
                    <Typography variant='body2' >{props.name.toUpperCase()}</Typography>
                </div>
            </Grid>
            <Grid xs={SECOND_COL_WIDTH} ><Typography variant='body2'>{props.formatValue(props.value)}</Typography></Grid>
            <Grid xs={THIRD_COL_WIDTH} ><Typography variant='body2' sx={{ textDecoration: 'underline' }}></Typography></Grid>
        </Grid>
    )
}

export function SimpleRow(props: { children?: React.ReactNode }) {
    return (
        <Grid
            container
            xs={12}
            style={{ minHeight: '30px' }}
            justifyContent="center"
            alignItems="center"
        >
            <Grid xs={10}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'left'
                }}>
                    <Typography variant='body2' >{props.children}</Typography>
                </Box>
            </Grid>
        </Grid>

    )
}