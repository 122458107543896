import React, { useEffect, useState } from "react"
import { Box, Button, Fab, IconButton, Radio, TextField, Typography } from "@mui/material";
import { authentication } from '../business_logic/backend/firebase-config';
import { checkActionCode, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { User } from '../business_component_shared/Types';
import OtpInput from 'react-otp-input';
import { getOnKeyDownEnter } from "../business_component_shared/helpers";
import venmoPic from "../img/venmo_black.webp";
import cashappPic from '../img/cashapp_logo.svg';

// const isTest = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development';
const skipOtp = true;

type CurrentInput = 'name' | 'payment username' | 'phone number' | 'code' | 'select payment type'; // currently go in this order
export type StatefulPieces = {
    phoneNumber?: string,
    screen?: CurrentInput,
    name?: string,
    venmoUsername?: string,
    cashappUsername?: string,
    check?: string,
    paymentTypeChoice?: 'venmo' | 'cashapp' | 'zelle',
}
export function OTP(props: {
    setUser: (uid: string, a: User) => void,
    setPaymentUsername?: (venmoUsername?: string, cashappUsername?: string) => void,
    // I'm not sure the pieces for restore work
    statefulPiecesFromRestore?: StatefulPieces,
    setStatefulPiecesForRestore?: (a: StatefulPieces) => void
}) {
    const { setUser, setPaymentUsername, statefulPiecesFromRestore, setStatefulPiecesForRestore } = props;
    const sp = statefulPiecesFromRestore;
    const [phoneNumber, setPhoneNumber] = useState(sp?.phoneNumber ?? '');
    const [screen, setScreen] = useState<CurrentInput>(sp?.screen ?? 'name');
    const [otpCode, setOtpCode] = useState<string | undefined>();
    const [paymentTypeChoice, setPaymentTypeChoice] = useState<'venmo' | 'cashapp' | 'zelle' | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(sp?.name ?? getNameCookie() ?? undefined);
    const [check, setCheck] = useState<string | undefined>(sp?.check ?? undefined);
    const [venmoUsername, setVenmoUsername] = useState<string | undefined>(sp?.venmoUsername ?? getVenmoCookie() ?? undefined);
    const [cashappUsername, setCashappUsername] = useState<string | undefined>(sp?.cashappUsername ?? getCashappCookie() ?? undefined);


    useEffect(() => {
        setStatefulPiecesForRestore?.(
            {
                phoneNumber,
                // screen, // commented out because we want to start at the beginning if someone closes dialog
                name,
                venmoUsername,
                cashappUsername,
                paymentTypeChoice,
            }
        );
        // setCheckFromCookie(paymentTypeChoice)
    }, [phoneNumber, screen, name, venmoUsername, paymentTypeChoice, cashappUsername, setStatefulPiecesForRestore])

    function onFinishInputtingPayment() {
        if (venmoUsername?.toLowerCase().trim() == check?.toLowerCase().trim() || cashappUsername?.toLowerCase().trim() == check?.toLowerCase().trim()) {
            if (paymentTypeChoice === 'cashapp')
                setPaymentUsername?.(undefined, cashappUsername!!.toLowerCase().trim());
            else if (paymentTypeChoice === 'venmo')
                setPaymentUsername?.(venmoUsername!!.toLowerCase().trim(), undefined);
            // Set cookie on commit
            // 90 day retention
            document.cookie = "venmoUsername=".concat(venmoUsername === undefined ? '' : venmoUsername.toLowerCase().trim()).concat("; path=/; Max-Age=7776000;");
            document.cookie = "cashappUsername=".concat(cashappUsername === undefined ? '' : cashappUsername.toLowerCase().trim()).concat("; path=/; Max-Age=7776000;");
            console.log(cashappUsername)
            setScreenPhoneNumber();
        } else {
            console.log(venmoUsername, cashappUsername, check)
            alert("Usernames don't match")
        }
    }

    function setScreenPhoneNumber() {
        if (skipOtp) {
            // alert('skipping otp');
            setUser(
                name ?? 'foo bar', {
                name: name ?? 'foo bar',
            }
            );
        }
        else {
            setScreen('phone number');
        }
    }

    function onSentText() {
        setScreen('code')
    }

    function assignRecaptchaToWindow() {
        // @ts-expect-error Error here. Recaptcha verifier must have changed signatures.
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response: never) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        }, authentication);
    }

    function getNameCookie() {
        return document.cookie.replace(/(?:(?:^|.*;\s*)name\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    }

    function getVenmoCookie() {
        return document.cookie.replace(/(?:(?:^|.*;\s*)venmoUsername\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    }

    function getCashappCookie() {
        return document.cookie.replace(/(?:(?:^|.*;\s*)cashappUsername\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    }

    function setCheckFromCookie(paymentTypeChoice: 'venmo' | 'cashapp' | 'zelle' | undefined) {
        console.log(paymentTypeChoice)
        paymentTypeChoice === 'venmo' ? setCheck(getVenmoCookie()) : paymentTypeChoice === 'cashapp' ? setCheck(getCashappCookie()) : setCheck("")
    }

    const setOtpCodeWrapper = (code: string) => {
        setOtpCode(code);
        if (code.length === 6) {
            verifyOtp();
        }
    }

    const setNameEnteredWrapper = () => {
        if (name === undefined || name === '') {
            alert('Input a name.')
        }
        else if (name.length > 40) {
            alert('Input a shorter name')
        }
        else if (props.setPaymentUsername) {
            setScreen('select payment type');
        }
        else {
            setScreenPhoneNumber();
        }
    }

    const onKeyDownEnter = getOnKeyDownEnter<HTMLDivElement>;

    const requestOtp = () => {
        const _phoneNumber = phoneNumber.replace(/[^0-9]+/g, '');
        assignRecaptchaToWindow();
        signInWithPhoneNumber(authentication, '+1' + _phoneNumber, window.recaptchaVerifier)
            .then(confirmationResult => {
                window.confirmationResult = confirmationResult;
                onSentText();
            }).catch(error => {
                alert(error);
            })
    }

    const verifyOtp = () => {
        if (otpCode?.length === 6) {
            window.confirmationResult.confirm(otpCode).then((result) => {
                setUser(result.user.uid, { name: name ?? 'impossible' });
            }).catch(error => { alert(error) });
        }
    }

    return (
        <div>
            {screen === 'name' && <>
                <Box display='flex' flexDirection={'column'} alignItems='center' justifyContent={'center'} paddingTop={0}>
                    <Typography variant="h6" style={{ paddingBottom: '20px' }}>What's your name?</Typography>
                    <TextField
                        value={name ?? ''}
                        label='Name'
                        onChange={(event) => {
                            setName(event.target.value);
                            document.cookie = "name=".concat(event.target.value).concat("; path=/; Max-Age=7776000;");
                        }}
                        onKeyDown={onKeyDownEnter(setNameEnteredWrapper)}
                        InputProps={
                            {
                                endAdornment: <ArrowCircleUpIcon onClick={() => { setNameEnteredWrapper() }} style={{ transform: 'rotate(90deg)' }}></ArrowCircleUpIcon>
                            }
                        }
                    />
                </Box>
            </>}
            {
                screen === 'select payment type' &&
                <><Box display='flex' flexDirection={'column'} alignItems='center' justifyContent={'center'} paddingTop={0}>
                    <Typography variant="h6" style={{ paddingTop: '10px', paddingBottom: '20px', paddingLeft: '0px' }}>How should people pay you?</Typography>
                </Box><Box display='flex' flexDirection={'column'} alignItems='center' justifyContent={'center'} paddingTop={0}>
                        <Fab
                            onClick={() => {
                                setPaymentTypeChoice('venmo');
                                setScreen('payment username');
                                setCheckFromCookie('venmo');
                            }}
                            variant="extended"
                            sx={{ marginBottom: 1, padding: '30px', paddingTop: '35px', textTransform: 'none', flexDirection: 'column' }}
                            color='info'
                        >
                            <img src={venmoPic} style={{ maxWidth: '100px', filter: 'invert(1)' }}></img>
                        </Fab>
                        <Fab
                            onClick={() => {
                                setPaymentTypeChoice('cashapp');
                                setScreen('payment username');
                                setCheckFromCookie('cashapp');
                            }}
                            variant="extended"
                            // @ts-expect-error
                            color='cashapp'
                            sx={{ marginBottom: 1, paddingLeft: '11px', paddingRight: '20px', paddingTop: '32px', paddingBottom: '30px', textTransform: 'none', flexDirection: 'row' }}
                        >
                            <img src={cashappPic} style={{ width: '30px', paddingBottom: '5px' }}></img>
                            <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                                <Typography mb={1} variant="h6" style={{ paddingTop: '2px', paddingLeft: '5px' }} color='white' fontSize={'20px'} fontWeight={'bold'}>Cash App</Typography>
                            </Box>
                        </Fab>
                    </Box></>

            }
            {screen === 'payment username' && <div>
                <Typography variant="h6" style={{ paddingLeft: '10px', paddingBottom: '20px' }}>Enter your username</Typography>
                <Box display='flex' flexDirection='column' alignItems='left' justifyContent='center'>
                    <TextField
                        value={paymentTypeChoice === 'venmo' ? venmoUsername : paymentTypeChoice === 'cashapp' ? cashappUsername : venmoUsername}
                        label={
                            paymentTypeChoice === 'venmo' ? 'Venmo username' : paymentTypeChoice === 'cashapp' ? 'Cashapp username' : 'Username'
                        }
                        onChange={(event) => {
                            switch (paymentTypeChoice) {
                                case 'venmo':
                                    setVenmoUsername(event.target.value);
                                    break;
                                case 'cashapp':
                                    setCashappUsername(event.target.value);
                                    break;
                                default:
                                    break;
                            }
                        }}
                        sx={{
                            marginBottom: 2
                        }}
                    />

                    <TextField
                        // defaultValue={paymentTypeChoice === 'venmo' ? getVenmoCookie() : paymentTypeChoice === 'cashapp' ? getCashappCookie() : ""}
                        value={check}
                        label='Confirm username'
                        onChange={(event) => {
                            setCheck(event.target.value);
                        }}
                        onKeyDown={onKeyDownEnter(onFinishInputtingPayment)}
                        InputProps={
                            {
                                endAdornment: <ArrowCircleUpIcon onClick={onFinishInputtingPayment} style={{ transform: 'rotate(90deg)' }}></ArrowCircleUpIcon>
                            }
                        }
                    />
                </Box>

            </div>}
        </div >
    )
}