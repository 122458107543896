import { Typography } from "@mui/material";
import { PresentedBillV2Item } from "../../../business_component_shared/Types";

type types = 'cart' | 'unpaid' | 'notcart' | 'birthday' // | 'paid' -- don't show paid item prices

function fmtPriceForDisplay(price: number) {
    return '$' + price.toFixed(2);
}

function getUnpaidItemPrice(item: PresentedBillV2Item) {
    // const itemsPaid = (item.uidsPaid ?? []).length;
    // const itemsUnpaid = item.quantity - itemsPaid;
    return fmtPriceForDisplay(item.price) + '/ea';
}

function getPaidItemPrice(item: PresentedBillV2Item) {
    return fmtPriceForDisplay(item.price) + '/ea';
}

function getCartItemPrice(item: PresentedBillV2Item) {
    const numItemsInCart = item.quantityInCart ?? 0;
    return fmtPriceForDisplay(item.price * numItemsInCart);
}

function getBirthdayItemPrice(item: PresentedBillV2Item) {
    const numItemsBirthday = item.birthdayQuantity ?? 0;
    return fmtPriceForDisplay(item.price * numItemsBirthday);
}

function getNotCartItemPrice(item: PresentedBillV2Item) {
    const numItemsInCart = item.quantity - (item.quantityInCart ?? 0);
    return fmtPriceForDisplay(item.price * numItemsInCart);
}


function getItemPriceForType(item: PresentedBillV2Item, type: types): string {
    switch (type) {
        case 'cart':
            return getCartItemPrice(item);
        case 'unpaid':
            return getUnpaidItemPrice(item);
        case 'notcart':
            return getNotCartItemPrice(item);
        case 'birthday':
            return getBirthdayItemPrice(item);
    }
}

export function ItemPrice(props: { item: PresentedBillV2Item, type: types }) {
    // TODO: support other currencies
    return <Typography>
        {getItemPriceForType(props.item, props.type)}
    </Typography>
}