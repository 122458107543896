import { SxProps, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

// TODO: Refactor this into some style constant file
const INPUT_BACKGROUND_COLOR = '#f7f7f7';

export function RestaurantNameInput(props: { restaurantName: string | undefined, setRestaurantName: (numDiners: React.SetStateAction<string | undefined>) => void, sxProps?: SxProps }): [React.ReactNode, React.ReactNode] {
    const [value, setValue] = useState(String(props.restaurantName));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        props.setRestaurantName(event.target.value);
    };

    return [
        <Typography>Restaurant name</Typography>
        ,
        <div>
            <TextField
                size='small'
                sx={{ backgroundColor: INPUT_BACKGROUND_COLOR }}
                value={(value && value !== 'undefined') ? value : ''}
                onChange={handleChange}
                type='text'
                inputProps={{
                    style: { textAlign: 'right' },
                }}
            />
        </div>
    ];
}

export function StatefulRestaurantName(props: { defaultRestaurantName: string | undefined, persistRestaurantName: (numDiners: string | undefined) => void, sxProps?: SxProps }) {
    const [restaurantName, setRestaurantName] = useState(props.defaultRestaurantName);
    useEffect(() => {
        props.persistRestaurantName(restaurantName);
    }, [restaurantName]);
    return RestaurantNameInput({ restaurantName, setRestaurantName, sxProps: props.sxProps });
}
