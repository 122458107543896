import { unpartialDiff } from "./db_guards";
import { copyIndexSignature, deepCopy } from "./helpers";
import { Diff, Bill, ItemGroup, DBDiffs } from "../../business_component_shared/Types";
import { BIRTHDAY_UID } from "../../components/shopping_v2/item/ItemAvatars";

// Currently, this isn't in use, but it's a good idea to have because 
function _decrementUidFromItemGroup(itemGroup: ItemGroup, uidToChange: string, quantity: number): { status: 'failure', msg: string } | { status: 'success', result: ItemGroup } {
    if (uidToChange === BIRTHDAY_UID) {
        // alert('applying diff for birthday boy');
        if (!itemGroup.birthdayQuantity) {
            itemGroup.birthdayQuantity = 0;
        }
        else {
            itemGroup.birthdayQuantity -= quantity;
        }

        return { status: 'success', result: itemGroup }
    }
    if (!(uidToChange in itemGroup.payments)) {
        return { status: 'failure', msg: 'UID ' + uidToChange + ' has not paid for this item. Only ' + Object.keys(itemGroup.payments) + ' have.' }
    }

    itemGroup.payments = copyIndexSignature(itemGroup.payments);

    if (itemGroup.payments[uidToChange] <= quantity) {
        delete itemGroup.payments[uidToChange];
    }
    else {
        itemGroup.payments[uidToChange] -= quantity;
    }

    return { status: 'success', result: itemGroup };
}


// NOTE: Doesn't actually copy, for now
function _incrementUidFromItemGroup(itemGroup: ItemGroup, uidToChange: string, quantity: number): { status: 'success', result: ItemGroup } | { status: 'failure', msg: string } {
    const numberOfItems = itemGroup.quantity;

    // should this just use the quantityPaid function?
    const numberOfThingsPaid = Object.values(itemGroup.payments).length >= 1 ?
        Object.values(itemGroup.payments).reduce((acc, curr) => acc + curr) :
        0;

    if (quantity + numberOfThingsPaid <= numberOfItems) {
        // we can perform this change
        if (!(uidToChange in itemGroup.payments)) {
            itemGroup.payments[uidToChange] = 0;
        }
        itemGroup.payments[uidToChange] += quantity;

        return {
            status: 'success',
            result: itemGroup,
        }
    }
    else {
        return {
            status: 'failure',
            msg: 'Could not add self to item group, its too full',
        }
    }
}

/**
 * A pure function to apply a diff to a bill
 */
export function applyDiffToBill(
    bill: Bill,
    diff: Diff,
): { status: 'success', result: Bill } | { status: 'failure', msg: string } {
    // TODO: is it best to deepCopy the whole bill or just the parts that need to be copied?
    const copiedBill = deepCopy(bill);

    const uid = diff.uid;
    const itemGroupIdToChangeWithExtraInfo = diff.itemGroupId;

    const itemGroupIdToChange = itemGroupIdToChangeWithExtraInfo.split(' ')[0];

    if (!(itemGroupIdToChange in bill.itemGroups)) {
        return { status: 'failure', msg: 'itemGroupId ' + itemGroupIdToChange + ' does not exist in itemGroups ' + Object.keys(bill.itemGroups) }
    }
    let res;
    let q: number
    switch (diff.action) {
        // note: lots of boilerplate here
        case 'incrementUid':
        case 'incrementUidForAppetizer':
            if (diff.action === 'incrementUidForAppetizer') {
                q = diff.amount;
            }
            else {
                q = 1;
            }
            res = _incrementUidFromItemGroup(copiedBill.itemGroups[itemGroupIdToChange], uid, q)
            if (res.status === 'success') {
                copiedBill.itemGroups[itemGroupIdToChange] = res.result;
                return {
                    status: 'success',
                    result: copiedBill,
                }
            }
            else {
                return {
                    status: 'failure',
                    msg: res.msg,
                }
            }
        case 'decrementUid':
        case 'decrementUidForAppetizer':
            if (diff.action === 'decrementUidForAppetizer') {
                q = diff.amount;
            }
            else {
                q = 1;
            }

            res = _decrementUidFromItemGroup(copiedBill.itemGroups[itemGroupIdToChange], uid, q);
            if (res.status === 'success') {
                copiedBill.itemGroups[itemGroupIdToChange] = res.result;
                return {
                    status: 'success',
                    result: copiedBill,
                }
            }
            else {
                return {
                    status: 'failure',
                    msg: res.msg,
                }
            }
        case 'removeItemForUid':
            if (uid === BIRTHDAY_UID) {
                copiedBill.itemGroups[itemGroupIdToChange].birthdayQuantity = 0;
                return {
                    status: 'success',
                    result: copiedBill,
                };
            }
            try {
                delete copiedBill.itemGroups[itemGroupIdToChange].payments[uid];
                return {
                    status: 'success',
                    result: copiedBill,
                }
            }
            catch (e) {
                return {
                    status: 'failure',
                    msg: 'Could not zero out quantity for uid ' + uid + ' in item group ' + itemGroupIdToChange + ' because it does not exist',
                }
            }
        default:
            return { status: 'failure', msg: 'Unimplemented' };
    }
}

/**
 * Call on load to load diffs into bill
 */
export function applyDiffsFromDbToBill(bill: Bill, dbDiffs: DBDiffs): Bill {
    // Remove the invalid ones by filtering via unpartialDiff, may want to log them in the future
    const diffs = Object.entries(dbDiffs)
        .sort(([a, _], [b, __]) => a.localeCompare(b))
        .map(([_, b]) => b).filter(x => unpartialDiff(x)) as Diff[]

    let currentBill = bill;
    for (const diff of diffs) {
        const res = applyDiffToBill(currentBill, diff);
        if (res.status === 'failure') {
            console.error('Could not apply diff: ' + res.msg); // this could be a concurrency thing -- our app might not work w/o wifi
            continue;
        }
        else {
            currentBill = res.result;
        }
    }
    return currentBill;
}

type itemGroupId = string;
export function shoppingCartToDiffs(cart: itemGroupId[], timestamp: number, uid: string): Diff[] {
    return cart.map(itemGroupId => {
        return {
            action: 'incrementUid',
            itemGroupId,
            timestamp,
            submitterUid: uid,
            uid,
        }
    })
}