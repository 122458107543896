import '../App.css';
import { EditBill } from './EditableBill'
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'
import { uncompressUrlState } from '../business_logic/backend/url_state';
import { BillEditable } from '../business_component_shared/Types';
import { useEffect, useState } from 'react';
import { NonBirthdayShare } from './NonBirthdayShare';
import { PostShare } from './PostShareDialog';

// Hook
// function useLocalStorage(key: string, initialValue: unknown) {
//     // State to store our value
//     // Pass initial state function to useState so logic is only executed once
//     const [storedValue, setStoredValue] = useState(() => {
//         if (typeof window === "undefined") {
//             return initialValue;
//         }

//         try {
//             // Get from local storage by key
//             const item = window.localStorage.getItem(key);
//             // Parse stored json or if none return initialValue
//             return item ? JSON.parse(item) : initialValue;
//         } catch (error) {
//             // If error also return initialValue
//             console.log(error);
//             return initialValue;
//         }
//     });

//     // Return a wrapped version of useState's setter function that ...
//     // ... persists the new value to localStorage.
//     const setValue = (value: unknown) => {
//         try {
//             // Allow value to be a function so we have same API as useState
//             const valueToStore =
//                 value instanceof Function ? value(storedValue) : value;
//             // Save state
//             setStoredValue(valueToStore);
//             // Save to local storage
//             if (typeof window !== "undefined") {
//                 window.localStorage.setItem(key, JSON.stringify(valueToStore));
//             }
//         } catch (error) {
//             // A more advanced implementation would handle the error case
//             console.log(error);
//         }
//     };

//     return [storedValue, setValue];
// }

/**
 * Entry point into all bill uploading / editing / share UI
 */
function Edit() {
    const location = useLocation();
    const navigate = useNavigate();
    const image = location.state ? location.state.image : undefined;
    let bill: BillEditable;
    var dirtyBit = document.getElementById('page_is_dirty') as HTMLInputElement;

    if (dirtyBit.value == '1' && window.localStorage.getItem('bill') !== null && window.localStorage.getItem('bill') !== '') {
        bill = JSON.parse(window.localStorage.getItem('bill')!);
    }
    else if (location.state !== null && location.state.bill !== null) {
        bill = location.state.bill;
    }
    else {
        bill = { items: {}, timestamp: Date.now(), isBirthday: false };
    }

    const attr = image ? { paddingTop: '50px' } : {};

    const [nonBirthayShareDialogOpen, setNonBirthdayShareDialogOpen] = useState(false);

    // useEffect(() => {
    //     window.localStorage.setItem('bill', '');
    // })
    function onShare() {
        window.localStorage.setItem('bill', JSON.stringify(bill));
        // location.state.bill = bill;
        dirtyBit.value = '1';
        if (bill.isBirthday) {
            navigate('/share', {
                state: { bill: bill },
            });
        } else {
            setNonBirthdayShareDialogOpen(true);
        }
    }

    window.scrollTo(0, 0);

    const [shareUrl, setShareUrl] = useState<string | undefined>();

    return (
        <>
            {!shareUrl &&
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', ...attr }} className='foo'>
                        <div>
                            <EditBill bill={bill} image={image} onShare={onShare}></EditBill>
                        </div>
                    </Box>
                    <NonBirthdayShare bill={bill} open={nonBirthayShareDialogOpen} onClose={() => setNonBirthdayShareDialogOpen(false)} onComplete={setShareUrl} />
                </>
            }
            {shareUrl && <PostShare shareUrl={shareUrl}></PostShare>}

        </>
    );
}

export default Edit;
