import { PresentedBillV2 } from "../../business_component_shared/Types";
import { ItemsView, birthdayTotal, cartTotal } from "../../business_logic/pay_bill_v2_actions";

function generateVenmoDescription(bill: PresentedBillV2) {
    // const myItems = Object.values(bill.items)
    //     .filter(x => (x.quantityInCart ?? 0) > 0)
    //     .map(x => { return {desc: x.desc, amt: x.price * (x.quantityInCart ?? 0) }})
    //     .map(x => `${x.desc} ${CURRENCY_SYMBOL}${x.amt}`)
    //     .join('\n');
    const restaurantName = bill.restaurantName;
    let s = '';
    if (restaurantName) {
        s = `${restaurantName} X Banana Split 🍌`
    }
    else {
        s = `Banana Split 🍌`
    }

    return encodeURIComponent(encodeURIComponent(s));

}

/**
 * 
 */
export function openVenmoLink(bill: PresentedBillV2, cartItems: ItemsView) {
    window.open('https://www.venmo.com/' + bill.paymentOptions?.paymentUsername + '?txn=pay&note=BananaSplit' + '&amount=' + cartTotal(bill, cartItems).toFixed(2));
}