import { Box, Typography, unstable_useId } from '@mui/material';
import { User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { authentication } from '../business_logic/backend/firebase-config';
import { uuidv4 } from "@firebase/util";

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can 
    // provide a callbacks.signInSuccess function.
    // signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
}

const SHOULD_SKIP_AUTH = true;

export function SignInScreen(props: { callback: (user: User) => void }) {
    if (SHOULD_SKIP_AUTH) {
        const uid = uuidv4();
        props.callback({ uid: uid } as User)
    }

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = authentication.onAuthStateChanged(user => {
            if (user) {
                props.callback(user);
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='h5'>Sign in</Typography>
            </Box>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={authentication} />
        </React.Fragment>
    );
}

