import { BillEditable } from "../business_component_shared/Types";
import { finalizeEditableBillV2 } from "../business_logic/edit_side_actions";
import { shareBillV2 } from "../business_logic/share_side_actions";
import { PreShareDialog } from "./shopping_v2/PreShare";

export function NonBirthdayShare(props: { bill: BillEditable, open: boolean, onClose: () => void, onComplete: (shareUrl: string) => void }) {
    const shareBill = finalizeEditableBillV2(props.bill);

    return <PreShareDialog
        bill={shareBill}
        open={props.open}
        onCancel={props.onClose}
        onConfirm={(uid, user) => {
            shareBill.ownerUid = uid;
            shareBill.users[shareBill.ownerUid] = { name: user.name };

            shareBillV2(shareBill, (shareUrl) => {
                navigator.clipboard.writeText(shareUrl);
                window.history.replaceState({}, 'BananaSplit', shareUrl);
                props.onComplete(shareUrl)
            })

        }}
    />


}