import { Button, Fab, Typography } from "@mui/material";
import { BugBeetle } from "phosphor-react";
import { useEffect, useState } from "react";
import useWindowDimensions from "../business_component_shared/helpers";

const MOBILE_WIDTH = 600;

// A FAB with a bug icon that opens a Google form for reporting bugs
export function BugReportButton() {
    const { width } = useWindowDimensions();
    const [hasTimeoutPassed, setHasTimeoutPassed] = useState(false);
    useEffect(() => {
        setTimeout(
            () => {
                setHasTimeoutPassed(true);
            }, 5000
        )

    })

    // don't show the button
    return <>
    </>
    // return <Button
    //     variant='contained'
    //     color='white'
    //     sx={{ textTransform: 'none', position: 'fixed', bottom: 20, left: 20, zIndex: 500 }}
    //     onClick={() => window.open('https://forms.gle/uFyrR1mfEfeRFUnz9', '_blank')}
    // >
    //     <BugBeetle size={20} />
    //     {
    //         (width >= MOBILE_WIDTH || !hasTimeoutPassed) &&
    //         <Typography variant="body2" sx={{ ml: 1 }}>Report a bug</Typography>
    //     }

    // </Button>
}