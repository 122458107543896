import { Close, PersonPinSharp, PrivacyTipSharp, QuestionMark } from "@mui/icons-material";
import { Avatar, Badge, BadgeProps, Box, Popover, styled, SxProps, Typography } from "@mui/material";
import userEvent from "@testing-library/user-event";
import { User as UserIcon } from "phosphor-react";
import React, { useState } from "react";
import { displayNameToAvatar, fmtPriceForDisplay } from "../../../business_component_shared/helpers";
import { PresentedBillV2Item, User } from "../../../business_component_shared/Types";
import { colorize } from '../../../business_logic/pay_and_share_side_actions';

export const BIRTHDAY_UID = 'birthday_e5wewrfld52'
const CURRENCY_SYMBOL = '$';
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20,
}));

function ItemAvatar(props: {
    quantityEaten: number,
    user: User,
    item: PresentedBillV2Item,
    isCurrentUid: boolean,
    uid: string,
    onRemoveFromCart: () => void,
    isRemoveUsersMode?: boolean,
    onRemoveUserFromItem?: () => void,
    isShareMode?: boolean,
}) {

    const user = props.user;

    let avatar: React.ReactNode;
    if (user.name === 'You!') {
        avatar = <Typography fontSize={18}>🫵</Typography>
    }
    else if (props.uid === BIRTHDAY_UID) {
        avatar = <Typography fontSize={18}>🥳</Typography>
    }
    else {
        avatar = displayNameToAvatar(user.name);
    }

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const ref = React.useRef(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(ref.current);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);

    function handlePopoverClickOpen(event: React.MouseEvent<HTMLElement>) {
        handlePopoverOpen(event);
        setTimeout(() => {
            handlePopoverClose();
        }, 2000)
    }

    const isBirthdayMode = (props.isShareMode && props.uid === BIRTHDAY_UID);

    const quantityInCart = isBirthdayMode ? (props.item.birthdayQuantity ?? 0) : (props.item.quantityInCart ?? 0)

    const quantityEaten = props.uid === BIRTHDAY_UID ? (props.item.birthdayQuantity ?? 0) : props.quantityEaten;

    // Changed the quantity to >= 1 because it shows you when somebody ate just one
    const quantityBadgeCondition = quantityEaten >= 1 && Number.isInteger(quantityEaten) && !isBirthdayMode;
    const xBadgeCondition = (quantityInCart > 0 && props.isCurrentUid) || isBirthdayMode;

    let popoverText = '';
    if (xBadgeCondition && props.uid !== BIRTHDAY_UID) {
        popoverText = `${CURRENCY_SYMBOL}${fmtPriceForDisplay(quantityInCart * props.item.price)} on your tab`;
    }
    else if (xBadgeCondition && props.uid === BIRTHDAY_UID) {
        popoverText = `${CURRENCY_SYMBOL}${fmtPriceForDisplay(quantityInCart * props.item.price)} on the birthday tab`;
    }
    else if (props.uid === BIRTHDAY_UID) {
        popoverText = `${CURRENCY_SYMBOL}${fmtPriceForDisplay(quantityEaten * props.item.price)} on the birthday tab`;
    }
    else {
        popoverText = `${user.name} chipped in ${CURRENCY_SYMBOL}${fmtPriceForDisplay(quantityEaten * props.item.price)}`;
    }

    return <div ref={ref}>
        <Badge
            color={'default'}
            badgeContent={
                // TODO: Check that it is an integer / maybe display it diff if fraction
                // TODO: There's a chance for a user to come back and pay for an item a little more,
                // but this will not show that
                (!props.isCurrentUid && props.isRemoveUsersMode && <SmallAvatar
                    sx={{
                        bgcolor: '#d32f2f'
                    }}
                    onClick={
                        () => {
                            props.onRemoveUserFromItem?.();
                        }
                    }
                >
                    {
                        <Close fontSize='small'></Close>
                    }
                </SmallAvatar>
                ) ||
                (quantityBadgeCondition &&
                    <SmallAvatar
                        sx={{
                            bgcolor: '#444444'
                        }}
                    >
                        <Typography>{quantityEaten}</Typography>
                    </SmallAvatar>
                ) || (
                    xBadgeCondition &&
                    <SmallAvatar
                        sx={{
                            bgcolor: '#d32f2f'
                        }}
                        onClick={props.onRemoveFromCart}
                    >
                        {
                            (quantityInCart > 1 && Number.isInteger(quantityInCart)) ? <Typography>{quantityInCart}</Typography> : <Close fontSize='small'></Close>
                        }
                    </SmallAvatar>
                )

            }
        >
            <Avatar
                onMouseEnter={handlePopoverOpen}
                onClick={open ? handlePopoverClose : handlePopoverClickOpen}
                onMouseLeave={handlePopoverClose}
                sx={
                    {
                        bgcolor: colorize(user.name)
                    }
                }
            >
                {
                    avatar ?
                        <Typography>
                            {avatar}
                        </Typography> :
                        <QuestionMark></QuestionMark>
                }
            </Avatar>
        </Badge>
        <Popover
            id={"mouse-over-popover"}
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Typography variant='body2' sx={{ p: 1 }}>{popoverText}</Typography>
        </Popover>
    </div >
}

export function ItemAvatars(props: {
    item: PresentedBillV2Item,
    uidToUser: (uid: string) => User,
    onRemoveFromCart?: (uid: string) => void,
    uid?: string,
    isInSplitDialog?: boolean,
    isRemoveUsersMode?: boolean,
    onRemoveUserFromItem?: (uid: string) => void,
    isShareMode?: boolean,
}) {
    const uidsPaid = (Object.keys(props.item.uidsPaid ?? {}));

    const users = uidsPaid.map(uid => {
        return {
            uid,
            user: props.uidToUser(uid),
            isCurrentUid: false,
        }
    });

    if (!props.isInSplitDialog && (props.item.quantityInCart ?? 0) > 0) {
        users.push({
            uid: props.uid ?? 'You!',
            user: props.uidToUser(props.uid ?? 'You!'),
            isCurrentUid: true,
        });
    }

    if ((props.item.birthdayQuantity ?? 0) > 0) {
        users.push({
            uid: BIRTHDAY_UID,
            user: {
                name: 'Birthday'
            },
            isCurrentUid: false,
        });
    }

    return <Box display={'flex'}
        width='max-content'
        sx={{
            marginLeft: props.isInSplitDialog ? 0 : 'auto',
        }}
        // bgcolor='#ffffff'
        borderRadius={2} padding={0.5} border='0px solid black'>
        <Box flexDirection={props.isInSplitDialog ? 'row' : 'row-reverse'} display='flex'>
            {
                users.map(
                    ({ uid, user, isCurrentUid }) => (<Box paddingLeft='3px'>
                        <ItemAvatar
                            key={uid}
                            isRemoveUsersMode={props.isRemoveUsersMode}
                            item={props.item} quantityEaten={props.item.uidsPaid ? props.item.uidsPaid[uid] : 1}
                            user={user}
                            uid={uid}
                            isCurrentUid={isCurrentUid}
                            onRemoveFromCart={() => props.onRemoveFromCart?.(uid)}
                            onRemoveUserFromItem={() => {
                                props.onRemoveUserFromItem?.(uid);
                            }}
                            isShareMode={props.isShareMode}
                        ></ItemAvatar>
                    </Box>)
                )
            }
        </Box>
    </Box >
}