import { Dialog } from "@mui/material";
import { useState } from "react";
import { PresentedBillV2, User } from "../../business_component_shared/Types";
import { OTP, StatefulPieces } from "../OTP";

export function PreShareDialog(props: {
    bill: PresentedBillV2,
    open: boolean,
    onCancel: () => void,
    onConfirm: (uid: string, user: User) => void,

}) {
    const [otpState, setOtpState] = useState<StatefulPieces>({});

    return <Dialog
        PaperProps={{
            sx: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingY: 5,
                paddingX: 4,
            }
        }}
        onClose={() => { props.onCancel() }}
        open={props.open}>
        <OTP
            setUser={props.onConfirm}
            setPaymentUsername={(venmo, cashApp) => {
                if (venmo) {
                    props.bill.paymentOptions = {
                        paymentType: 'venmo',
                        paymentUsername: venmo,
                        paymentUrl: undefined
                    }
                }
                if (cashApp) {
                    props.bill.paymentOptions = {
                        paymentType: 'cashApp',
                        paymentUsername: cashApp,
                        paymentUrl: undefined
                    }
                }
            }}
            setStatefulPiecesForRestore={setOtpState}
            statefulPiecesFromRestore={otpState}
        ></OTP>
    </Dialog>
}