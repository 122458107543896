import '../../../App.css'
import { Typography } from "@mui/material";
import { PresentedBillV2Item } from "../../../business_component_shared/Types";
import { quantityInCart, quantityPaid, quantityUnpaid } from "../../../business_logic/pay_bill_v2_actions";
import toUnicodeFrac from 'fraction-unicode'
import { toFraction } from '../../../business_component_shared/helpers';

type types = 'unpaid' | 'paid' | 'cart' | 'notcart' | 'all' | 'birthday';

const MAX_NUM_DINERS = 50;

function itemQuantity(item: PresentedBillV2Item, type: types): number {
    switch (type) {
        case 'cart':
            return item.quantityInCart ?? 0;
        case 'paid':
            return quantityPaid(item);
        case 'unpaid':
            return item.quantity - quantityPaid(item) - (item.quantityInCart ?? 0);
        case 'notcart': // we prob dont need this
            return item.quantity - (item.quantityInCart ?? 0);
        case 'all':
            return item.quantity;
        case 'birthday':
            return item.birthdayQuantity ?? 0
    }
}

export function ItemQuantity(props: { item: PresentedBillV2Item, type: types }) {
    // const unpaidBeforeCart = props.item.quantity - quantityPaid(props.item);
    // const unpaidAfterCart = itemQuantity(props.item, props.type);

    // if (props.type === 'unpaid' && unpaidBeforeCart !== unpaidAfterCart) {

    //     return <Typography>
    //         <span className="strikethrough">{unpaidBeforeCart}</span>
    //         {unpaidAfterCart}
    //     </Typography>

    // }

    // @ts-expect-error
    const maxDenom: number = (window.numDiners ?? MAX_NUM_DINERS) * 2;

    const q = itemQuantity(props.item, props.type);

    const num = Math.floor(q);
    const decimal = q - num;

    let sx = quantityUnpaid(props.item) === 0 && props.type !== 'cart' ? { color: 'gray' } : {};
    if (props.type === 'birthday' && (props.item.birthdayQuantity ?? 0) > 0) {
        sx = {};
    }

    if (num === 0 && decimal !== 0) {
        // TODO: Figure out what to do with fractions in the cart. Until then
        // we are going to just return something empty here since it makes more
        // sense from a user perspective.
        // 
        // const frac = toFraction(decimal, maxDenom);
        // const unicodeFrac = toUnicodeFrac(frac.numerator, frac.denominator);
        // return <Typography fontFamily={'sans-serif'}>{unicodeFrac}</Typography>
        return <Typography sx={sx}>{''}</Typography>
    }
    else if (decimal !== 0) {
        // const frac = toFraction(decimal, maxDenom);
        // const unicodeFrac = toUnicodeFrac(frac.numerator, frac.denominator);
        // return <Typography fontFamily={'sans-serif'}>{String(num) + ' ' + unicodeFrac}</Typography>
        return <Typography sx={sx}>{''}</Typography>
    }
    else {
        return <Typography sx={sx}>{String(num)}</Typography>
    }


}