import { Box, Button, ButtonGroup, Dialog, DialogContent, IconButton, TextField, Tooltip, Typography, Select, MenuItem } from "@mui/material";
import { ChartPie, ChartPieSlice, Cookie } from "phosphor-react";
import { useState } from "react";
import { fmtPriceForDisplay, validateKeyPressForMoneyFields } from "../../../business_component_shared/helpers";
import { quantityPaid, quantityUnpaid } from "../../../business_logic/pay_bill_v2_actions";
import { AddAppetizerContext } from "./AddItem";
import { ItemAvatars } from "./ItemAvatars";
import { PresentedBillV2Item } from "../../../business_component_shared/Types";

const CURRENCY_SYMBOL = '$';

function generateNumberList(N: number, item: PresentedBillV2Item) {
    const numberList = [];

    for (let i = 2; i <= N; i++) {
        numberList.push(i);
    }

    const qUnpaid = quantityUnpaid(item);
    const remainingPayment = qUnpaid * item.price;

    return numberList.filter(x => {
        const totalPrice = item.price * item.quantity;
        const amountWouldPay = totalPrice / x;

        return amountWouldPay < remainingPayment;
    })
}

export function AddAppetizerDialog(props: {
    addDialogOpen: boolean,
    setAddDialogOpen: (open: boolean) => void,
    isShareMode?: boolean,
} & AddAppetizerContext) {
    const { addDialogOpen, setAddDialogOpen, addQuantityOfItemToShoppingCart: addQuantityOfItemToCart } = props;
    const costChippedIn = quantityPaid(props.item) * props.item.price;

    const suggestedContributions = props.payContext;

    const firstSuggestedContributions = suggestedContributions.slice(0, suggestedContributions.length - 1);
    const lastSuggestedContributions = [suggestedContributions[suggestedContributions.length - 1]];

    const [customAmount, setCustomAmount] = useState<undefined | number>(undefined);
    const isErrInCustomPrice = !!customAmount && (customAmount > (quantityUnpaid(props.item) * props.item.price));

    const [customAmountField, setCustomAmountField] = useState<string>('');

    const [screen, setScreen] = useState<'first' | 'appetizer' | 'fraction'>('first');


    const qUnpaid = quantityUnpaid(props.item);
    const remainingPayment = qUnpaid * props.item.price;
    const totalPrice = props.item.price * props.item.quantity;
    const amountWouldPayMinimum = totalPrice / (props.numDiners ?? 10);
    const shouldShow1OverX = amountWouldPayMinimum < remainingPayment;

    /**
     * If we can generate a valid fraction show it
     */
    const shouldShowFraction = generateNumberList(props.numDiners ?? 10, props.item).length > 0;

    return <Dialog
        open={addDialogOpen}
        onClose={() => { setAddDialogOpen(false); setScreen('first'); }}
    >
        {/* <DialogTitle></DialogTitle> */}
        <DialogContent>
            {
                screen === 'first' &&
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>{props.item.quantity} {props.item.desc} @ {CURRENCY_SYMBOL}{fmtPriceForDisplay(props.item.price)}/ea </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                        <ButtonGroup
                            fullWidth
                            sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', margin: 0, backgroundColor: 'white' }}
                            variant='contained'
                        >
                            {
                                /**
                                 * NOTE(James): If the quantity unpaid is a fraction we don't want to allow the user to add, it doesn't make sense
                                 * 
                                 * Maybe we should skip the 'first' screen entirely in this case
                                 */
                            }
                            {Number.isInteger(quantityUnpaid(props.item)) &&
                                <Button color='primary' onClick={() => {
                                    addQuantityOfItemToCart(1);
                                }} sx={{ paddingY: 2, paddingX: 2, textTransform: 'none', height: 60 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>{'+1'}</Typography>
                                        <Typography variant='body2'>{'Add one'}</Typography>
                                        {/* <UserPlus size={20} /> */}
                                    </Box>
                                </Button>
                            }

                            {shouldShowFraction &&
                                <Button color='primary' onClick={() => {
                                    setScreen('appetizer');
                                }} sx={{ paddingY: 2, paddingX: 2, textTransform: 'none', height: 60 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <ChartPieSlice fontSize={'20px'} />
                                        <Typography variant='body2'>{'Add a fraction'}</Typography>
                                        {/* <UserPlus size={20} /> */}
                                    </Box>
                                </Button>
                            }

                            {!shouldShowFraction &&
                                <Button color='primary' onClick={() =>
                                    addQuantityOfItemToCart(quantityUnpaid(props.item))
                                } sx={{ paddingY: 2, paddingX: 2, textTransform: 'none', height: 60 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <Cookie fontSize={'20px'} />
                                        <Typography variant='body2'>{'Add the rest'}</Typography>
                                        {/* <UserPlus size={20} /> */}
                                    </Box>
                                </Button>
                            }


                        </ButtonGroup>
                    </Box>
                </Box>

            }
            {
                screen === 'appetizer' &&
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>Splitting {props.item.quantity} {props.item.desc} @ {CURRENCY_SYMBOL}{fmtPriceForDisplay(props.item.price)}/ea </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                        {
                            costChippedIn > 0 && <>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Typography variant='body2'>The following diners chipped in {CURRENCY_SYMBOL}{fmtPriceForDisplay(costChippedIn)} total</Typography>
                                </Box>
                                <ItemAvatars item={props.item} uidToUser={props.uidToUser} uid={props.uid} isInSplitDialog={true} isShareMode={props.isShareMode} />
                            </>
                        }

                        <Box flexDirection='column' alignItems={'center'} justifyContent={'center'} minWidth='200px' mt={2}>
                            <Typography variant='body2'>Add a fraction</Typography>
                            <TextField
                                fullWidth
                                select
                                label="fraction"
                                // defaultValue={'2'}
                                InputProps={{
                                    inputProps: { min: 0, max: quantityUnpaid(props.item) * props.item.price, style: { WebkitAppearance: 'none', MozAppearance: 'none' } },
                                    endAdornment: <Button
                                        color='secondary'
                                        variant='contained'
                                        sx={{ textTransform: 'none' }}
                                        disabled={isErrInCustomPrice || !customAmount}
                                        onClick={() => {
                                            if (customAmount) addQuantityOfItemToCart(customAmount / (props.item.price));
                                            setScreen('first');
                                        }}

                                    >Add</Button>

                                }}
                                onChange={(event: any) => {
                                    const denom = event.target.value;
                                    const totalPrice = props.item.price * props.item.quantity;
                                    const amountWouldPay = totalPrice / denom;
                                    setCustomAmountField(fmtPriceForDisplay(amountWouldPay));
                                    setCustomAmount(amountWouldPay);

                                }}
                                sx={{ minWidth: '80px', mt: 1.5 }}
                            >
                                {generateNumberList(props.numDiners ?? 10, props.item).map(n => {
                                    const denom = n;
                                    const totalPrice = props.item.price * props.item.quantity;
                                    const amountWouldPay = totalPrice / denom;

                                    return <MenuItem key={n} value={n}>{`1/${n} ($${fmtPriceForDisplay(amountWouldPay)})`}</MenuItem>
                                })}
                            </TextField>
                        </Box>
                    </Box>
                </Box>

            }
        </DialogContent>
    </Dialog>

}