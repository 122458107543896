import '../App.css';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'
import { finalizeEditableBill, finalizeEditableBillV2 } from '../business_logic/edit_side_actions';
import { BillEditable, PresentedBill } from '../business_component_shared/Types';
import { compressUrlState, uncompressUrlState } from '../business_logic/backend/url_state';
import { assert } from 'console';
import { PresentedBillV2View } from './shopping_v2/PresentedBill';
import { useEffect, useState } from 'react';
import { Link, Pencil, Share } from 'phosphor-react';
import { PostShare } from './PostShareDialog';
import { ReceiptImageInPay } from './ReceiptImageInPay';
import BirthdayModeToggle from './shopping_v2/BirthdayModeToggle';


/**
 * Entry point into all bill uploading / editing / share UI
 */
function ShareBillPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const [shareUrl, setShareUrl] = useState<string | undefined>(undefined);

    const onBackButtonEvent = (e: Event) => {
        e.preventDefault();
        navigate('/edit', { state: { bill: bill } });
        // window.history.pushState(null, 'foo', window.location.pathname);
    }

    useEffect(() => {
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    let bill: BillEditable | undefined;
    if (location.state !== null) {
        bill = location.state.bill;
    }
    if (!bill) {
        // Unrecoverable -- redirect to home
        window.location.replace(window.location.origin);
        return <div>
            <p>An error occured. Redirecting you home!</p>
        </div>
    }

    // @ts-expect-error
    const imageUrl: string | undefined = window.imageUrl;

    window.scrollTo(0, 0);

    return (
        <Box>
            {!shareUrl && <Box>
                <PresentedBillV2View bill={finalizeEditableBillV2(bill)} type='share' currentUid={undefined} onComplete={(url) => setShareUrl(url)} imageUrl={imageUrl} setBill={(a) => {
                    // you don't need to anything here
                }}></PresentedBillV2View>
            </Box>}
            {shareUrl && <PostShare shareUrl={shareUrl}></PostShare>}
        </Box>
    );
}

export default ShareBillPage;
