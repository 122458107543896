// File of DB type guards

import { unpartial } from "./helpers";
import { Diff } from "../../business_component_shared/Types";

export function unpartialDiff(diff: Partial<Diff>): Diff | undefined {
    const _unpartialDiff = unpartial(diff, ['action', 'itemGroupId', 'submitterUid', 'timestamp', 'uid']);
    if ('errorMsg' in _unpartialDiff) {
        console.warn(_unpartialDiff.errorMsg);
        return;
    }

    else {
        return _unpartialDiff;
    }
}
