import { Box, Typography, TextField, IconButton, Snackbar, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Link, Share, QrCode as QRCodeIcon } from "phosphor-react";
import { useState } from "react";
import QRCode from "react-qr-code";

function canShare(): boolean {
    return !!navigator.share;
}
function navShare(shareUrl: string) {
    navigator.share?.({ url: shareUrl, text: `Split the bill with me on BananaSplit!`, title: 'Banana Split [Request]' });
}


export function PostShare(props: { shareUrl: string, additionalText?: string }) {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    window.onbeforeunload = function () { 
        alert("dev")
        window.scrollTo(0, 0); 
    }

    return <Box className='foo'>
        <Box>
            <Box display='flex' flexDirection={'row'} alignItems='center' justifyContent={'center'} paddingBottom={2}>
                <Typography variant='h6'>{props.additionalText ?? 'Share your Banana Split!'}</Typography>
            </Box>
            <Box display='flex' flexDirection={'row'} alignItems='center' justifyContent={'center'}>
                <TextField label='Share URL' defaultValue={props.shareUrl} inputProps={
                    { readOnly: true }
                }
                    fullWidth
                >
                </TextField>
                <IconButton onClick={() => {
                    navigator.clipboard.writeText(props.shareUrl).then(() => setSnackbarOpen(true))
                }}>
                    <Link></Link>
                </IconButton>
                {canShare() &&
                    <IconButton onClick={() => navShare(props.shareUrl)}>
                        <Share></Share>
                    </IconButton>
                }

            </Box>
            <Box mt={2} sx={{ p: 2, border: '1px solid #bbb', borderRadius: '5px' }}>
                <QRCode value={props.shareUrl} size={240} fgColor={'#222222'} />
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={1000}
                onClose={() => setSnackbarOpen(false)}
                color='green'
                message='Copied URL!'
            ></Snackbar>
        </Box>
    </Box >
}