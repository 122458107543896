import { Close } from "@mui/icons-material";
import { Button, IconButton, Snackbar } from "@mui/material";
import { X } from "phosphor-react";
import React from "react";
import { PresentedBillV2Item } from "../../business_component_shared/Types";

export type SnackbarValue = { msg: string, actionForUndo: () => void } | undefined;

export function SnackbarItemChange(props: {
    value: SnackbarValue,
    handleClose: () => void,
}) {
    return <React.Fragment>
        {props.value !== undefined &&
            <Snackbar
                sx={{ zIndex: 1000 }}
                open={props.value !== undefined}
                autoHideDuration={5000}
                onClose={props.handleClose}
                message={props.value.msg}
                action={
                    <React.Fragment>
                        <Button color="secondary" size="small" onClick={() => { props.handleClose(); props.value?.actionForUndo(); }}>
                            UNDO
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={props.handleClose}
                        >
                            <Close fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
            />
        }
    </React.Fragment>
}