import { FileUpload } from '@mui/icons-material';
import { Button, Divider, Typography, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { createRef, useEffect, useRef, useState } from 'react';
import '../App.css';
import { Navigate } from 'react-router-dom'
import { BillEditable } from '../business_component_shared/Types';
import { OnloadDialog } from './uploadbill/OnLoadingDialog';
import { SignInScreen } from './FirebaseLogin';

import { addEvent } from '../business_logic/backend/events_tracker';
import { handleImageChangeAfterAuthWithContext, testBill } from '../business_logic/ocr_and_parsing/upload_image';
import { compressImage } from '../business_logic/ocr_and_parsing/compression';
import { Camera, Upload } from 'phosphor-react';
import SwipeableViews from 'react-swipeable-views';

import phoneTakingFlic from '../img/holding_iphone.png'
import phoneSelect from '../img/phone_select.png'
import phoneSplit from '../img/phone_split.png'

import creditCard from '../img/cc.png'
import useWindowDimensions from '../business_component_shared/helpers';
import { NavigationDots } from './NavigationDots';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { QuestionAnswers } from './uploadbill/LoadingQuestions';
import { as } from '../business_logic/backend/helpers';


// Make it so you can't press back without a warning
// window.onbeforeunload = function () { return true; }

const fontColor = '#797ACC'

const USE_TEST_BILL = false;

const _emptyBill: BillEditable = { items: { '0': { quantity: 1 } }, timestamp: Date.now(), isBirthday: false }

const emptyBill = USE_TEST_BILL ? testBill : _emptyBill;

const swipeableViewsStyles = {
    slide: {
        width: 'min(90vw, 445px)',
        height: '80vh',
        maxHeight: '50vh',
        overflow: 'hidden',
        borderRadius: 20,
        borderWidth: 5,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '8px',
        marginTop: '8px',
        pointerEvents: ("none" as React.CSSProperties["pointerEvents"]),
        // boxShadow: '2px 2px 8px rgb(161, 165, 219)',
        // backgroundColor: '#afbced32'
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    slide1: {
        overflow: 'hidden',
    },
    slide2: {
        overflow: 'hidden',
    },
    slide3: {
        overflow: 'hidden',
    },
};


/**
 * Component to prompt uploading of the bill
*/
export function UploadBill(props: {}) {
    const [bill, setBill] = useState<BillEditable>();
    const [image, setImage] = useState<File>();

    const [loadingHasBegun, setLoadingHasBegun] = useState<boolean>(false);
    const [loadingIsFinished, setLoadingIsFinished] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>("Loading...");

    const [authHasBegun, setAuthHasBegun] = useState<boolean>(false);
    const [authIsFinished, setAuthIsFinished] = useState<boolean>(false);
    const [isManualReceiptEntry, setIsManualReceiptEntry] = useState<boolean>(false);

    const [shouldNavigateToEdit, setShouldNavigateToEdit] = useState<boolean>(false);

    // Answers to questions asked on the loading screen
    const [myAnswers, setMyAnswers] = useState<QuestionAnswers>({});


    const { width } = useWindowDimensions();

    const myRef = useRef();

    useEffect(() => {
        if (myRef.current) {
            // disableBodyScroll(myRef.current);
        }
    }, [myRef])

    window.scrollTo(0, 0);

    const [swipeIndex, setSwipeIndex] = useState<number>(0);

    useEffect(() => {
        if (image && authIsFinished) {
            _handleImageChangeAfterAuth(image);
        }
    }, [authIsFinished, image])

    // mock this to fake the bill uploading
    const _handleImageChangeAfterAuth = handleImageChangeAfterAuthWithContext({
        setLoadingHasBegun,
        setLoadingIsFinished,
        setLoadingText,
        setBill,
    })

    const onUpload = (event: any) => {
        // This is where we could compress the image
        // Image is to be referenced in handleImageChangeAfterAuth

        setAuthHasBegun(true);

        setLoadingText("Compressing image...");
        // @ts-expect-error
        compressImage(event?.target?.files?.[0]).then(x => { setImage(x) });
    }

    if (isManualReceiptEntry && !authIsFinished) {
        return <SignInScreen callback={(user) => { addEvent('signed in', { uid: user.uid }); setBill(emptyBill); setShouldNavigateToEdit(true); setAuthIsFinished(true); clearAllBodyScrollLocks(); }} />
    }

    if (!isManualReceiptEntry && authHasBegun && !authIsFinished) {
        return <SignInScreen callback={(user) => { addEvent('signed in', { uid: user.uid }); setAuthIsFinished(true); clearAllBodyScrollLocks(); }} />
    }

    const IMAGE_BOX_WIDTH_HEIGHT = 'min(35vw, 400px)';
    const IMAGE_IMAGE_WIDTH_HEIGHT = 'min(30vw, 350px)'

    return <Box sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column', overflowX: 'hidden'
    }} ref={myRef}>
        < OnloadDialog loadingHasBegun={loadingHasBegun} loadingIsFinished={loadingIsFinished} loadingText={loadingText} triggerClose={(a) => {
            if (a) {
                setMyAnswers(a);
            }
            setShouldNavigateToEdit(true);
        }} />
        {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column' }}>
            <Typography variant='h6'>
                Get started with Banana Split
            </Typography>
            <Typography variant='body1'>
                You take a picture of an itemized check receipt.
                We will help you generate a link to send to your fellow diners.
                They pick the items they ordered and Venmo you back with tax and tip!
            </Typography>
        </Box> */}
        {/* <Divider sx={{ borderBottomWidth: 0.5, borderColor: 'black', width: '100%', my: 1 }} /> */}
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} >
            <Typography variant='h6' fontSize={24} fontFamily={'Candola'} lineHeight={'24px'} fontWeight={'regular'} marginTop={6} color={'#343459'}>
                Split the bill!
            </Typography>
            <Typography variant='h6' fontSize={24} fontFamily={'Candola'} lineHeight={'24px'} fontWeight={'regular'} marginTop={0} color={'#343459'}>
                The <span style={{ color: '#B67ACB' }}>easiest</span> way to share a meal.
            </Typography>

            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} mt={2}>
                <Box sx={{
                    width: 'min(85vw,230px)',
                    height: '4em',
                    mt: 1,
                    // height: 'max(13vh, 133px)',
                    // m: 1,
                    // border: 1,
                    // borderRadius: '16px',
                    background: 'linear-gradient(97.67deg, rgba(182, 122, 203, 0.6) 26.71%, rgba(132, 116, 199, 0.6) 46.53%, rgba(126, 131, 212, 0.6) 65.18%, rgba(135, 146, 225, 0.6) 82.66%)',
                    borderRadius: '100px',
                    borderColor: 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Button
                        variant="text"
                        component="label"
                        sx={{
                            color: 'text.primary',
                            textTransform: 'none',
                            height: '100%',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                            <input
                                type="file"
                                accept="image/jpeg"
                                onChange={onUpload}
                                hidden
                            />
                            <Camera size={'25px'} color='#fffaff'></Camera>
                            <Typography ml={2} fontSize={14} color='#fffaff' fontWeight={600}>Upload a receipt</Typography>
                        </Box>
                    </Button>
                </Box>
                <Button
                    sx={{
                        color: 'text.primary',
                        textTransform: 'none',
                        fontWeight: 500,
                        textDecoration: 'underline'
                    }}
                    onClick={() => setIsManualReceiptEntry(true)}>
                    <Typography fontSize={11} color={fontColor}>Enter bill manually</Typography>
                </Button>
            </Box>


            {/* <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} marginTop={1}>
                <SwipeableViews enableMouseEvents style={{ width: 'min(100vw, 460px)' }} onChangeIndex={(index, _) => {
                    setSwipeIndex(index);
                }}>
                    <div style={Object.assign({}, swipeableViewsStyles.slide, swipeableViewsStyles.slide1)}>
                        <Typography color={fontColor} variant='h6' sx={{ pointerEvents: 'none', marginBottom: 2, marginTop: 2 }} fontSize={20}>
                            Take a picture of your receipt, and select your items
                        </Typography>
                        <img src={phoneTakingFlic} style={{ pointerEvents: 'none', width: '100%', objectFit: 'contain' }} />
                    </div>
                    <div style={Object.assign({}, swipeableViewsStyles.slide, swipeableViewsStyles.slide2)}>
                        <Typography color={fontColor} variant='h6' sx={{ pointerEvents: 'none', marginBottom: 2, marginTop: 2, mx: 2 }} fontSize={20}>
                            We’ll generate a link to share with your fellow diners
                        </Typography>
                        <img src={phoneTakingFlic} style={{ pointerEvents: 'none', width: '100%', objectFit: 'contain' }} />
                    </div>
                    <div style={Object.assign({}, swipeableViewsStyles.slide, swipeableViewsStyles.slide3)}>
                        <Typography color={fontColor} variant='h6' sx={{ pointerEvents: 'none', marginBottom: 2, marginTop: 2, mx: 2 }} fontSize={20}>
                            Your friends select their items, and Venmo you back with tax and tip!
                        </Typography>
                        <img src={phoneTakingFlic} style={{ pointerEvents: 'none', width: '100%', objectFit: 'contain' }} />
                    </div>
                </SwipeableViews>
            </Box> */}

            {/* </Box>

        <Box > */}
            {/* <NavigationDots numberOfDots={3} currentIndex={swipeIndex} sx={{ mt: 2 }} /> */}

            <Box display={'flex'} flexDirection={'column'} mt={4} overflow='visible'>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'} overflow='visible'>
                    <Box display={'flex'} flexDirection={'column'} maxWidth={'35vw'} textAlign={'center'} ml={1} mr={2} overflow='visible'>
                        <Box width={IMAGE_BOX_WIDTH_HEIGHT} height={IMAGE_BOX_WIDTH_HEIGHT} sx={{ bgcolor: '#f9f9f9', overflow: 'hidden' }} borderRadius={'20px'} boxShadow={'0px 0px 3px 3px #f9f9f9, 0px 0px 5px 5px #f9f9f9'}>
                            <img src={creditCard} style={{ pointerEvents: 'none', maxWidth: IMAGE_IMAGE_WIDTH_HEIGHT, objectFit: 'contain' }} />

                        </Box>
                        <Typography fontFamily={'Candola'} fontSize={'20px'} color={'#B67ACB'} mt={1.5}>Pay for dinner</Typography>
                        <Typography fontSize={'11px'} fontWeight={'300'}>
                            Be sure to ask for your receipt!
                        </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} maxWidth={'35vw'} ml={2} mr={1}>
                        <Box width={IMAGE_BOX_WIDTH_HEIGHT} height={IMAGE_BOX_WIDTH_HEIGHT} sx={{ bgcolor: '#f9f9f9', textAlign: 'right', overflow: 'hidden' }} borderRadius={'20px'} boxShadow={'0px 0px 3px 3px #f9f9f9, 0px 0px 5px 5px #f9f9f9'}>
                            <img src={phoneTakingFlic} style={{ pointerEvents: 'none', maxWidth: IMAGE_IMAGE_WIDTH_HEIGHT, objectFit: 'contain' }} />
                        </Box>
                        <Typography fontFamily={'Candola'} fontSize={'20px'} color={'#B67ACB'} mt={1.5}>Snap a pic</Typography>
                        <Typography fontSize={'11px'} fontWeight={'300'}>
                            Upload it above! Our computer vision will process your bill
                        </Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'} mt={4}>
                    <Box display={'flex'} flexDirection={'column'} maxWidth={'35vw'} mr={2} ml={1}>
                        <Box width={IMAGE_BOX_WIDTH_HEIGHT} height={IMAGE_BOX_WIDTH_HEIGHT} sx={{ bgcolor: '#f9f9f9', overflow: 'hidden' }} borderRadius={'20px'} boxShadow={'0px 0px 3px 3px #f9f9f9, 0px 0px 5px 5px #f9f9f9'}>
                            <img src={phoneSelect} style={{ pointerEvents: 'none', maxWidth: IMAGE_IMAGE_WIDTH_HEIGHT, objectFit: 'contain', maxHeight: '37vw' }} />
                        </Box>
                        <Typography fontFamily={'Candola'} fontSize={'20px'} color={'#B67ACB'} mt={1.5}>Select your items</Typography>
                        <Typography fontSize={'11px'} fontWeight={'300'}>
                            Select what you ate
                        </Typography>

                    </Box>
                    <Box display={'flex'} flexDirection={'column'} maxWidth={'35vw'} ml={2} mr={1}>

                        <Box width={IMAGE_BOX_WIDTH_HEIGHT} height={IMAGE_BOX_WIDTH_HEIGHT} sx={{ bgcolor: '#f9f9f9', overflow: 'hidden' }} borderRadius={'20px'} boxShadow={'0px 0px 3px 3px #f9f9f9, 0px 0px 5px 5px #f9f9f9'}>
                            <img src={phoneSplit} style={{ pointerEvents: 'none', maxWidth: IMAGE_IMAGE_WIDTH_HEIGHT, objectFit: 'contain', maxHeight: '40vw' }} />
                        </Box>

                        <Typography fontFamily={'Candola'} fontSize={'20px'} color={'#B67ACB'} mt={1.5}>Share a link</Typography>
                        <Typography fontSize={'11px'} fontWeight={'300'}>
                            Your friends select their items and Venmo you back
                        </Typography>


                    </Box>
                </Box>
            </Box>

        </Box>
        {
            shouldNavigateToEdit &&
            <Navigate
                to={{
                    pathname: '/edit'
                }}
                state={{ bill: { ...bill, tipAmount: myAnswers.tipAmount ?? bill?.tipAmount, tipPercent: myAnswers.tipPercent ?? bill?.tipPercent, numDiners: myAnswers.numDiners ?? bill?.numDiners, isBirthday: !!myAnswers.isBirthday || bill?.isBirthday }, image: image }}
            />
        }
    </Box >

}
