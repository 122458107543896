import { postBillToDb } from "./backend/db_access";
import { shoppingCartToDiffs } from "./backend/diffing";
import { Bill, BillEditable, ItemGroup, PresentedBill, PresentedBillV2, URLState } from "../business_component_shared/Types";
import { compressUrlState } from './backend/url_state';
import { getDiffsFromBill, PresentedBillV2ToBill } from "./pay_bill_v2_actions";

type itemGroupId = string;

function billSubtotal(presentedBill: PresentedBill) {
    return Object.values(presentedBill.items).map(item => item.price).reduce(
        (acc, curr) => acc + curr, 0
    );

}


export function presentedBillBackToEditableBill(presentedBill: PresentedBill): BillEditable {
    const st = billSubtotal(presentedBill);
    const editableBill: BillEditable = {
        items: {},
        timestamp: presentedBill.timestamp,
        taxAmount: presentedBill.taxRate * st,
        tipAmount: presentedBill.tipRate * st,
        ownerUid: presentedBill.ownerUid,
        isBirthday: !!presentedBill.isBirthday,

    }
    for (const [iid, item] of Object.entries(presentedBill.items)) {
        if (!(item.itemGroupId in editableBill.items)) {
            editableBill.items[item.itemGroupId] = {
                cost: item.price,
                desc: item.desc,
                quantity: 0,
            }
        }
        const formerQ = editableBill.items[item.itemGroupId].quantity!;
        editableBill.items[item.itemGroupId] = {
            quantity: formerQ + 1,
            cost: item.price,
            desc: item.desc,
        }
    }

    return editableBill;
}

export function presentedBillToBill(presentedBill: PresentedBill): Bill {
    const taxAmount = presentedBill.taxRate * billSubtotal(presentedBill);
    const tipAmount = presentedBill.tipRate * billSubtotal(presentedBill);
    const timestamp = presentedBill.timestamp;
    const itemGroups: { [itemGroupId: string]: ItemGroup } = {};
    for (const item of Object.values(presentedBill.items)) {
        if (!(item.itemGroupId in itemGroups)) {
            itemGroups[item.itemGroupId] = {
                desc: item.desc,
                payments: {},
                price: item.price,
                quantity: 1,
            }
        }
        else {
            itemGroups[item.itemGroupId].quantity += 1;
        }
    }

    return { taxAmount, tipAmount, timestamp, itemGroups };

}

/**
 * Retrieve a share URL with info compressed in the state, 
 * and also set the window to it
 */
export function deprecated_toShareUrl(bill: Bill, billId: string) {
    const state: URLState = {
        bill,
        billId
    }

    const compressedState = compressUrlState(state);

    const url = window.location.origin + '/payv2#' + compressedState;

    return url;
}

/**
 * When sharing, post bill to database
 */
export function shareBill(presentedBill: PresentedBill, cart: itemGroupId[], setShareUrl: (shareUrl: string) => void) {
    const ownerUid = presentedBill.ownerUid;
    const paymentOptions = presentedBill.paymentOptions;

    const billForDb = presentedBillToBill(presentedBill);


    if (ownerUid && paymentOptions) {
        postBillToDb(
            { ownerUid, paymentOptions, bill: billForDb }, shoppingCartToDiffs(cart, Date.now(), ownerUid), (billId) => {
                const billForUrl = presentedBillToBill(presentedBill);
                const shareUrl = window.location.origin + '/payv2/' + billId;

                setShareUrl(shareUrl);
            }
        )
    }
    else {
        console.error('owner uid and payment options must be defined')
    }
}


// TODO BIRTHDAY
export function shareBillV2(presentedBill: PresentedBillV2, setShareUrl: (shareUrl: string) => void) {
    const ownerUid = presentedBill.ownerUid;
    const paymentOptions = presentedBill.paymentOptions;


    if (ownerUid && paymentOptions) {
        const diffs = getDiffsFromBill(presentedBill, ownerUid, ownerUid);

        const billForDb = PresentedBillV2ToBill(presentedBill);

        postBillToDb(
            { ownerUid, paymentOptions, bill: billForDb }, diffs, (billId) => {
                const shareUrl = window.location.origin + '/payv2/' + billId;
                setShareUrl(shareUrl);
            }
        )
    }
}