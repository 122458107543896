import Compressor from 'compressorjs';

export const compressImage = (file: any) => {
    console.log('old pre-compressed size: ', file?.size);

    return new Promise((resolve) => {
        new Compressor(file, {
            quality: 0.6,
            success: (compressedImage) => {
                console.log('new compressed size: ', compressedImage.size);
                resolve(new File([compressedImage], ('name' in compressedImage && compressedImage.name) || 'Name not in blob', { type: compressedImage.type }));
            },
            error: (err) => {
                console.log(err.message);
                alert('An error occured in compressing.')
            },
        });
    });
};