import { Bill, DBBillAttributes, Diff, PresentedBillV2, PresentedBillV2Item, PresentedBillV2Items, URLState, User } from "../business_component_shared/Types";
import { as, mergeMaps } from "./backend/helpers";
import { startPayAndListenForBillChangesGeneric } from "./pay_side_actions";
import { CartV2 } from "../business_component_shared/Types";
import { postDiffsToBill } from "./backend/db_access";
import { diff } from "just-diff";

export type ItemsView = Set<string>

function arrSum(a: number[]) {
    let sum = 0;
    for (const elem of a) {
        sum += elem;
    }
    return sum;
}

export function quantityInCart(billItem: PresentedBillV2Item) {
    return billItem.quantityInCart ?? 0;
}

export function quantityPaid(billItem: PresentedBillV2Item) {
    if (billItem.uidsPaid === undefined) {
        return billItem.birthdayQuantity ?? 0
    }
    else {
        return arrSum(Object.values(billItem.uidsPaid)) + (billItem.birthdayQuantity ?? 0);
    }
}

export function quantityUnpaid(billItem: PresentedBillV2Item) {
    return billItem.quantity - quantityPaid(billItem) - quantityInCart(billItem);
}

function quantityNotMe(billItem: PresentedBillV2Item) {
    return billItem.quantity - quantityInCart(billItem);
}

export function billSubtotal(bill: PresentedBillV2) {
    let total = 0;
    for (const item of Object.values(bill.items)) {
        const q = item.quantity;
        const p = item.price;
        total += p * q;
    }
    return total;
}

function _subtotal(billItems: PresentedBillV2Items, subsetOfItems: ItemsView, quantityAccessor: (a: PresentedBillV2Item) => number) {
    let total = 0;
    for (const itemGroupId of Array.from(subsetOfItems)) {
        const q = quantityAccessor(billItems[itemGroupId]);
        const p = billItems[itemGroupId].price;

        total += p * q;
    }

    return total;
}

export function cartSubtotal(bill: PresentedBillV2, cartItems: ItemsView) {
    return _subtotal(bill.items, cartItems, quantityInCart);
}

export function everyoneElseSubtotal(bill: PresentedBillV2, otherItems: ItemsView) {
    return _subtotal(bill.items, otherItems, quantityNotMe)
}

function _taxOrTipAmount(bill: PresentedBillV2, viewSubtotal: number, type: 'tax' | 'tip'): number {
    const subtotal = billSubtotal(bill);

    const cartContributionRatio = viewSubtotal / subtotal;

    switch (type) {
        case 'tax':
            return bill.taxAmount * cartContributionRatio;
        case 'tip':
            return bill.tipAmount * cartContributionRatio;
    }
}

export function cartTaxOrTipAmount(bill: PresentedBillV2, cartItems: ItemsView, type: 'tax' | 'tip'): number {
    const cart_subtotal = cartSubtotal(bill, cartItems);

    return _taxOrTipAmount(bill, cart_subtotal, type);
}

export function everyoneElseTaxOrTipAmount(bill: PresentedBillV2, everyoneElseItems: ItemsView, type: 'tax' | 'tip'): number {
    const item_subtotal = everyoneElseSubtotal(bill, everyoneElseItems);

    return _taxOrTipAmount(bill, item_subtotal, type);
}

export function birthdayTotal(bill: PresentedBillV2, me: boolean) {
    const numDiners = bill.numDiners!

    const multiplier = me ? 1 : (numDiners - 1);

    const birthdayTotal = _subtotal(bill.items, new Set(Object.keys(bill.items)), (item) => item.birthdayQuantity ?? 0);

    const birthdayTotalPerDinerWithoutTaxTip = birthdayTotal / numDiners;

    const birthdayTotalWithoutTaxTip = birthdayTotalPerDinerWithoutTaxTip * multiplier;

    const taxAmount = _taxOrTipAmount(bill, birthdayTotalWithoutTaxTip, 'tax');
    const tipAmount = _taxOrTipAmount(bill, birthdayTotalWithoutTaxTip, 'tip');

    return birthdayTotalWithoutTaxTip + taxAmount + tipAmount;
}


export function cartTotal(bill: PresentedBillV2, cartItems: ItemsView) {
    return cartSubtotal(bill, cartItems) + cartTaxOrTipAmount(bill, cartItems, 'tax') + cartTaxOrTipAmount(bill, cartItems, 'tip') + birthdayTotal(bill, true);
}


export function everyoneElseTotal(bill: PresentedBillV2, everyoneElseItems: ItemsView) {
    return everyoneElseSubtotal(bill, everyoneElseItems) + cartTaxOrTipAmount(bill, everyoneElseItems, 'tax') + cartTaxOrTipAmount(bill, everyoneElseItems, 'tip') + birthdayTotal(bill, false);
}


// export function addItemToCart(billItems: PresentedBillV2Items, itemGroupId: string): void {
//     const item = billItems[itemGroupId];
//     if (!item.quantityInCart) {
//         item.quantityInCart = 0;
//     }
//     item.quantityInCart += 1;
// }


// export function removeItemFromCart(billItems: PresentedBillV2Items, itemGroupId: string): void {
//     const item = billItems[itemGroupId];
//     if (!item.quantityInCart) {
//         throw Error('Item should be in cartt');
//     }
//     item.quantityInCart -= 1;
// }

function initItemsView(billItems: PresentedBillV2Items, conditionForViewInclusion: (a: PresentedBillV2Item) => boolean): ItemsView {
    const viewItems = new Set<string>();
    for (const [itemGroupId, item] of Object.entries(billItems)) {
        if (conditionForViewInclusion(item)) {
            viewItems.add(itemGroupId);
        }
    }
    return viewItems;
}

export function initUnpaidItemsView(billItems: PresentedBillV2Items): ItemsView {
    return initItemsView(billItems, (item) => quantityUnpaid(item) > 0);
}

export function initPaidItemsView(billItems: PresentedBillV2Items): ItemsView {
    return initItemsView(billItems, (item) => quantityPaid(item) > 0);
}

export function initBirthdayItemsView(billItems: PresentedBillV2Items): ItemsView {
    return initItemsView(billItems, (item) => (item.birthdayQuantity ?? 0) > 0);
}

export function initCartItemsView(billItems: PresentedBillV2Items): ItemsView {
    return initItemsView(billItems, (item) => quantityInCart(item) > 0);
}

export function notItemsView(billItems: PresentedBillV2Items): ItemsView {
    return initItemsView(billItems, (item) => quantityInCart(item) + quantityPaid(item) < item.quantity)
}

export function addItemToBirthdayItems(
    allItems: PresentedBillV2Items,
    itemGroupId: string,
    quantityToAddToBirthday: number,
    unpaidItemsView: ItemsView,
    birthdayItemsView: ItemsView,
) {
    const itemRef = allItems[itemGroupId];
    const qUnpaid = quantityUnpaid(itemRef);
    if (qUnpaid < quantityToAddToBirthday) {
        const errorMsg = 'Impossible, quantity unpaid is '
            + qUnpaid + ' but quantity to add to birthday is '
            + quantityToAddToBirthday;
        alert(errorMsg);
        throw Error(errorMsg);
    }

    if (!itemRef.birthdayQuantity) {
        itemRef.birthdayQuantity = 0;
        birthdayItemsView.add(itemGroupId);
    }

    itemRef.birthdayQuantity += quantityToAddToBirthday;

    const newQUnpaid = qUnpaid - quantityToAddToBirthday;

    if (newQUnpaid === 0) {
        unpaidItemsView.delete(itemGroupId);
    }

    return { birthdayItemsView, unpaidItemsView }
}

// Add item to cart
export function unpaidToCart(
    allItems: PresentedBillV2Items,
    itemGroupId: string,
    quantityToAddToCart: number,
    unpaidItemsView: ItemsView,
    cartItemsView: ItemsView,
): { cartItemsView: ItemsView, unpaidItemsView: ItemsView } {
    const itemRef = allItems[itemGroupId];
    const qUnpaid = quantityUnpaid(itemRef);
    if (qUnpaid < quantityToAddToCart) {
        const errorMsg = 'Impossible, quantity unpaid is '
            + qUnpaid + ' but quantity to add to cart is '
            + quantityToAddToCart;
        alert(errorMsg);
        throw Error(errorMsg);
    }

    if (!itemRef.quantityInCart) {
        itemRef.quantityInCart = 0;
        cartItemsView.add(itemGroupId);
    }

    itemRef.quantityInCart += quantityToAddToCart;

    const newQUnpaid = qUnpaid - quantityToAddToCart;

    if (newQUnpaid === 0) {
        unpaidItemsView.delete(itemGroupId);
    }

    return { cartItemsView, unpaidItemsView };
}

export function removeItemFromBirthdayItems(
    allItems: PresentedBillV2Items,
    itemGroupId: string,
    quantityToRemoveFromBirthday: number,
    unpaidItemsView: ItemsView,
    birthdayItemsView: ItemsView
) {
    const itemRef = allItems[itemGroupId];
    const qBirthday = itemRef.birthdayQuantity ?? 0;
    if (quantityToRemoveFromBirthday > qBirthday) {
        const errorMsg = 'Quantity to remove from birthday ' + quantityToRemoveFromBirthday +
            ' is greater than quantity in birthday' + qBirthday;
        alert(errorMsg);
        throw Error(errorMsg);
    }

    const oldQuantityUnpaid = quantityUnpaid(itemRef);

    const newQuantityInBirthday = qBirthday - quantityToRemoveFromBirthday;

    itemRef.birthdayQuantity = newQuantityInBirthday;

    if (newQuantityInBirthday === 0) {
        birthdayItemsView.delete(itemGroupId);
    }

    const newQuantityUnpaid = quantityUnpaid(itemRef);

    if (oldQuantityUnpaid === 0 && newQuantityUnpaid > 0) {
        unpaidItemsView.add(itemGroupId);
    }

    return { birthdayItemsView, unpaidItemsView }
}
// New: Just remove the whole item from the cart on removal
export function cartToUnpaid(
    allItems: PresentedBillV2Items,
    itemGroupId: string,
    quantityToRemoveFromCart: number,
    unpaidItemsView: ItemsView,
    cartItemsView: ItemsView
): { cartItemsView: ItemsView, unpaidItemsView: ItemsView } {
    const itemRef = allItems[itemGroupId];
    const quantityInCart = itemRef.quantityInCart ?? 0;
    if (quantityToRemoveFromCart > quantityInCart) {
        const errorMsg = 'Quantity to remove from cart ' + quantityToRemoveFromCart +
            ' is greater than quantity in cart' + quantityInCart;
        alert(errorMsg);
        throw Error(errorMsg);
    }

    const oldQuantityUnpaid = quantityUnpaid(itemRef);

    const newQuantityInCart = quantityInCart - quantityToRemoveFromCart;
    itemRef.quantityInCart = newQuantityInCart;
    if (newQuantityInCart === 0) {
        cartItemsView.delete(itemGroupId);
    }

    const newQuantityUnpaid = quantityUnpaid(itemRef);

    if (oldQuantityUnpaid === 0 && newQuantityUnpaid > 0) {
        unpaidItemsView.add(itemGroupId);
    }

    return { cartItemsView, unpaidItemsView };
}

// add another function like this for birthday mode
export function cartToItemGroupIds(allItems: PresentedBillV2Items) {
    const cart: Array<string> = [];
    for (const [itemGroupId, item] of Object.entries(allItems)) {
        const q = item.quantityInCart ?? 0;
        for (let i = 0; i < q; i++) {
            cart.push(itemGroupId);
        }
    }
    return cart;
}

export function calculateAdjustedPrice(bill: PresentedBillV2): PresentedBillV2 {
    const tipRate = bill.tipAmount / billSubtotal(bill);
    const taxRate = bill.taxAmount / billSubtotal(bill);
    const priceMultiplier = 1 + ((tipRate) + (taxRate));

    return {
        ...bill,
        items: mergeMaps<PresentedBillV2Item>(Object.entries(bill.items ?? {})
            .map(([itemId, item]) => {
                return {
                    [itemId]: as<PresentedBillV2Item>({
                        ...item, adjustedPrice: item.price * priceMultiplier
                    })
                }
            })
        ),
    }

}

export function PresentedBillV2ToBill(bill: PresentedBillV2): Bill {
    const result: Bill = {
        itemGroups: {},
        taxAmount: bill.taxAmount,
        tipAmount: bill.tipAmount,
        timestamp: bill.timestamp,
        numDiners: bill.numDiners,
        restaurantName: bill.restaurantName,
    }

    for (const [groupId, itemGroup] of Object.entries(bill.items)) {
        result.itemGroups[groupId] = {
            desc: itemGroup.desc,
            payments: itemGroup.uidsPaid ?? {},
            price: itemGroup.price,
            quantity: itemGroup.quantity,
            isAppetizer: itemGroup.isAppetizer,
            birthdayQuantity: itemGroup.birthdayQuantity,
        }
    }

    return result;
}

export function billToPresentedBillV2(bill: Bill, attr: DBBillAttributes, users: { [uid: string]: User }) {
    const taxAmount = bill.taxAmount;
    const tipAmount = bill.tipAmount;
    const ownerUid = attr.ownerUid;
    const paymentOptions = attr.paymentOptions;
    const timestamp = bill.timestamp;
    const numDiners = bill.numDiners;
    const restaurantName = bill.restaurantName;

    console.log('retrieved bill from db', bill);

    const presentedBill: PresentedBillV2 = {
        items: {},
        users,
        taxAmount,
        tipAmount,
        timestamp,
        ownerUid,
        paymentOptions,
        numDiners,
        restaurantName
    };

    for (const [groupId, itemGroup] of Object.entries(bill.itemGroups).sort(([a, x], [b, y]) => a <= b ? -1 : 1)) {
        if (!itemGroup || !groupId) {
            // NOTE (James): I'm not sure why this happens sometimes but just skip so we don't have an error
            continue;
        }
        const paymentsCopy: {
            [uid: string]: number;
        } = {};

        for (const [key, val] of Object.entries(itemGroup.payments ?? {})) {
            paymentsCopy[key] = val;
        }

        presentedBill.items[groupId] = {
            desc: itemGroup.desc,
            price: itemGroup.price,
            quantity: itemGroup.quantity,
            quantityInCart: 0,
            // TODO BIRTHDAY: set quantity in birthday mode cart to 0 as well
            uidsPaid: paymentsCopy,
            isAppetizer: itemGroup.isAppetizer,
            birthdayQuantity: itemGroup.birthdayQuantity,
        }
    }

    const presBillWithAdjPrice = calculateAdjustedPrice(presentedBill);

    return presBillWithAdjPrice;
}

export function startPayAndListenForBillChangesV2(uid: string, billId: string, setPresentedBillV2: (a: PresentedBillV2) => void) {
    startPayAndListenForBillChangesGeneric(uid, billId, setPresentedBillV2, billToPresentedBillV2);
}

function generateCartV2(bill: PresentedBillV2) {
    const cart: CartV2 = {};
    for (const [itemGroupId, item] of Object.entries(bill.items)) {
        // TODO BIRTHDAY: need to do the same thing as this for birthday mode
        const q = item.quantityInCart ?? 0;
        if (q > item.quantity) {
            throw Error(`Quantity in cart ${q} is greater than quantity ${item.quantity} for item ${item.desc}`);
        }
        if (q > 0) {
            cart[itemGroupId] = {
                amount: q,
                isAppetizer: Boolean(item.isAppetizer)
            }
        }
    }
    return cart;
}

function shoppingCartToDiffs(cart: CartV2, timestamp: number, uidFrom: string, submitterUid: string, remove?: boolean) {
    const diffs: Array<Diff> = [];
    // Eventually all diffs should be of the appetizer style with an amount attached
    for (const [itemGroupId, item] of Object.entries(cart)) {
        // note: there was a bug here
        // everything is an appetizer now so wy use the other one
        if (true) {
            diffs.push({
                itemGroupId,
                timestamp,
                submitterUid: uidFrom,
                uid: uidFrom,
                amount: item.amount,
                action: remove ? 'decrementUidForAppetizer' : 'incrementUidForAppetizer'
            })
        }
        else {
            // For each item in the cart, add a diff for each item in the cart. This is silly and should be fixed to make it simpler.
            for (let i = 0; i < item.amount; i++) {
                diffs.push({
                    itemGroupId,
                    timestamp,
                    submitterUid: uidFrom,
                    uid: uidFrom,
                    action: remove ? 'decrementUid' : 'incrementUid'
                })
            }
        }
    }
    return diffs;
}


// TODO BIRTHDAY: could probably configure this to work for birthday mode as well
// by changing uidFrom to birthdayModeUid e.g. 'birthday09234235'
// 
// or maybe execute twice with uidFrom = birthdayModeUid and uidFrom = uid
//
// this gets called from share_side_actions
export function getDiffsFromBill(bill: PresentedBillV2, uidFrom: string, submitterUid: string, remove?: boolean) {
    const cart = generateCartV2(bill);
    const diffs = shoppingCartToDiffs(cart, Date.now(), uidFrom, submitterUid, remove);
    return diffs;
}

/**
 * Post a shopping cart (tab) to the database and call success() if successful, 
 * failure(msg) if not.
 * 
 * If submitterUid is not provided, it will be set to uidFrom.
 */
export function postShoppingCartV2ToDb(billId: string, bill: PresentedBillV2, uidFrom: string, success?: (cartForUndo: Diff[]) => void, failure?: (msg: string) => void, submitterUid?: string, remove?: boolean) {
    const diffs = getDiffsFromBill(bill, uidFrom, submitterUid ?? uidFrom, remove);
    const _diffs = [...diffs];
    console.log('found diffs:', diffs);
    postDiffsToBill(billId, diffs, (failedItems) => {
        if (failedItems.length === 0)
            success?.(_diffs);
        else {
            failure?.('One or more items failed to post to the database. Try again.')
        }
    })
}

export function postRawDiffsToBillForUndo(billId: string, diffs: Diff[], success?: () => void, failure?: (msg: string) => void) {
    for (const myDiff of diffs) {
        let newAction;
        switch (myDiff.action) {
            case 'incrementUid':
                newAction = 'decrementUid';
                break;
            case 'decrementUid':
                newAction = 'incrementUid';
                break;
            case 'incrementUidForAppetizer':
                newAction = 'decrementUidForAppetizer';
                break;
            case 'decrementUidForAppetizer':
                newAction = 'incrementUidForAppetizer';
                break;
            default:
                throw Error(`Unknown action ${myDiff.action}`);
        }

        // @ts-expect-error
        myDiff.action = newAction;
    }
    postDiffsToBill(billId, diffs, (failedItems) => {
        if (failedItems.length === 0)
            success?.();
        else {
            failure?.('One or more items failed to post to the database. Try again.')
        }
    });
}
