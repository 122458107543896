import mike from '../img/mike.jpeg';
import buzz from '../img/buzz.jpeg';
import ann from '../img/ann.jpeg';
import '../App.css';

import { OTP } from "./OTP";
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { PresentedBillV2 } from "../business_component_shared/Types";
import { startPayAndListenForBillChangesV2 } from "../business_logic/pay_bill_v2_actions";
import { PresentedBillV2View } from "./shopping_v2/PresentedBill";
import { getImageFromStorage } from '../business_logic/backend/image_storage';
import { ReceiptImageInPay } from './ReceiptImageInPay';
import { Pencil } from 'phosphor-react';

/**
 * Change newBill with the quantityInCart's from prevBill
 */
function mergeNewAndPrevBill(uid: string, newBill: PresentedBillV2, prevBill: PresentedBillV2 | undefined) {
    if (!prevBill) {
        return newBill;
    }
    for (let itemGroupId in newBill.items) {
        if (prevBill.items[itemGroupId]) {
            newBill.items[itemGroupId].quantityInCart = prevBill.items[itemGroupId].quantityInCart;
        }
    }
    return newBill;
}

/**
 * Directed here when loading billsplit.com/pay/payId
 */
export function PayV2() {
    const [uid, setUid] = useState<string | undefined>(undefined);
    const [presentedBillV2, setPresentedBillV2] = useState<PresentedBillV2 | undefined>(undefined);

    const [shareUrl, setShareUrl] = useState<string | undefined>(undefined);

    const { billId } = useParams();

    if (billId === undefined) {
        throw Error('Bill id must be defined')
    }

    function onSetUid(uid: string) {
        setUid(uid);
        startPayAndListenForBillChangesV2(uid, billId!, (newBill) => setPresentedBillV2(prevBill => mergeNewAndPrevBill(uid, newBill, prevBill)));
        getImageFromStorage(
            billId!,
            (url) => {
                setImageUrl(url);
            }
        )
    }

    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    const [isRemoveUsersMode, setIsRemoveUsersMode] = useState(false);

    return <>
        <Box>
            {
                presentedBillV2 !== undefined && uid !== undefined && !shareUrl &&
                <Box>
                    <Box position='relative' top='10px' left='10px' maxHeight='0px'>
                        {
                            isRemoveUsersMode && <Button
                                sx={{
                                    marginLeft: 'auto',
                                    borderRadius: 3,
                                    paddingY: 1,
                                    paddingX: 1,
                                    zIndex: 200,
                                }}
                                variant='contained'
                                size='small'
                                color='secondary'
                                onClick={() => { setIsRemoveUsersMode(false); }}
                            >
                                <Box display='flex' alignItems={'center'} justifyContent='center' sx={{ textTransform: 'none' }}>
                                    <Typography fontSize={15} fontWeight={700} sx={{
                                        textDecoration: 'underline'
                                    }}>Done</Typography>
                                </Box>

                            </Button>
                        }
                    </Box>
                </Box>
            }
            {uid === undefined &&
                // TODO: also save the user's name
                <Box className='foo'>
                    <Box>
                        <OTP setUser={(uid, user) => onSetUid(uid)}></OTP>
                    </Box>
                </Box>
            }
            {
                uid !== undefined && presentedBillV2 === undefined && <Box className='foo'><Box><Typography>Loading...</Typography></Box></Box>
            }
            {presentedBillV2 !== undefined && uid !== undefined &&
                <React.Fragment>
                    {!shareUrl && <PresentedBillV2View bill={presentedBillV2} currentUid={uid} type='pay' onComplete={(url) => setShareUrl(url)} isRemoveUsersMode={isRemoveUsersMode} setIsRemoveUsersMode={setIsRemoveUsersMode} imageUrl={imageUrl} setBill={setPresentedBillV2}></PresentedBillV2View>}
                    {shareUrl &&
                        <Box className='foo'>
                            <Box>
                                <Box display='flex' flexDirection={'row'} alignItems='center' justifyContent={'center'} paddingBottom={2}>
                                    <Typography variant='h6'>Thank you for using Banana Split!</Typography>
                                </Box>
                                <Typography align="center">Follow us on Twitter <span style={{ textDecoration: 'underline' }}>@BananaSplitDin</span> </Typography>
                                <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' mt={2}>
                                    <Typography variant='body2' sx={{ textDecoration: 'underline' }}>Created by</Typography>
                                    <Box display='flex' alignItems='center' justifyContent='center' flexDirection='row' mt={1}>
                                        <Box mx={1} display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                            <img src={mike} alt='Mike' width='80px' height='80px' style={{ borderRadius: '20px' }}></img>
                                            <Typography variant='body2'>Mike</Typography>
                                        </Box>
                                        <Box mx={1} display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                            <img src={buzz} alt='James' width='80px' height='80px' style={{ borderRadius: '20px' }}></img>
                                            <Typography variant='body2'>James</Typography>
                                        </Box>
                                        <Box mx={1} display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                            <img src={ann} alt='James' width='80px' height='80px' style={{ borderRadius: '20px' }}></img>
                                            <Typography variant='body2'>Ann</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        // <PostShare shareUrl={shareUrl} additionalText={'Thank you for using Banana Split!'}></PostShare>
                    }
                </React.Fragment>
            }
        </Box>
    </>
}