import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { useEffect } from "react";
import logo from '../../img/bsplitlogo.png';
import { LoadingQuestions, QuestionAnswers } from "./LoadingQuestions";

/** Dialog to display when image is being uploaded / processed */
export function OnloadDialog(props: { loadingHasBegun: boolean, loadingIsFinished: boolean, loadingText: string, triggerClose: (a?: QuestionAnswers) => void }) {
    const { loadingHasBegun, loadingIsFinished, triggerClose } = props;

    // Note: Remove me if you don't want to
    // auto-redirect when loading is finished
    // useEffect(() => {
    //     if (loadingIsFinished) props.triggerClose();
    // }, [loadingIsFinished, triggerClose])

    function onClose(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | undefined) {
        if (reason === "backdropClick" || reason === 'escapeKeyDown')
            return;
        if (loadingIsFinished) props.triggerClose();
    }


    return (
        <Dialog open={loadingHasBegun} onClose={(event, reason) => onClose(event, reason)}>
            <DialogContent>
                <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' textAlign='center'>
                    <img src={logo} alt='Banana Split' width='40px' />
                    <Typography color='#797ACC'>{props.loadingText}</Typography>
                    <LoadingQuestions onFinish={(a) => { props.triggerClose(a) }} isDoneLoading={props.loadingIsFinished} />
                </Box>
            </DialogContent>
        </Dialog>
    )
}