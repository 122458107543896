// pay side interactions with db / parsing link data

import { applyDiffsFromDbToBill, shoppingCartToDiffs } from "./backend/diffing";
import { getBillAttrFromDb, getUserFromDb, listenForDiffEvents, postDiffsToBill } from "./backend/db_access";
import { Bill, DBBillAttributes, DBDiffs, PresentedBill, PresentedBillItem, URLState, User } from "../business_component_shared/Types";
import { uncompressUrlState } from "./backend/url_state";
import { as, mergeMaps, unpartial } from "./backend/helpers";
import { useParams } from "react-router-dom";

export function urlStateFromUrlHash(): URLState | {
    errorMsg: string;
} {
    let hash: string;

    hash = window.location.hash

    if (hash[0] === '#') {
        hash = hash.substring(1);
    }

    const uncompressed = uncompressUrlState<URLState>(hash);

    return uncompressed;
}

function billSubtotalOfBill(bill: Bill) {
    let subtotal = 0;
    for (const itemGroup of Object.values(bill.itemGroups)) {
        subtotal += itemGroup.price * itemGroup.quantity;
    }
    return subtotal;
}

export function billToPresentedBill(bill: Bill, attr: DBBillAttributes, users: { [uid: string]: User }): PresentedBill {
    const taxRate = bill.taxAmount / billSubtotalOfBill(bill);
    const tipRate = bill.tipAmount / billSubtotalOfBill(bill);
    const ownerUid = attr.ownerUid;
    const paymentOptions = attr.paymentOptions;
    const timestamp = bill.timestamp;
    const presentedBill: PresentedBill = {
        taxRate,
        tipRate,
        ownerUid,
        paymentOptions,
        timestamp,
        items: {},
        users
    };
    for (const [groupId, itemGroup] of Object.entries(bill.itemGroups)) {
        const uidStack: string[] = [];
        Object.entries(itemGroup.payments).forEach(
            ([uid, num]) => {
                for (let i = 0; i < num; i++) {
                    uidStack.push(uid);
                }
            }
        )

        for (let i = 0; i < itemGroup.quantity; i++) {
            const internalItemId = groupId + ' ' + String(i);
            const nextUid = uidStack.pop();
            presentedBill.items[internalItemId] = {
                desc: itemGroup.desc,
                price: itemGroup.price,
                itemGroupId: groupId,
            }

            if (nextUid)
                presentedBill.items[internalItemId].uidPaid = nextUid;

        }
    }
    return presentedBill;
}

export function startPayAndListenForBillChanges(uid: string, billId: string, setPresentedBill: (a: PresentedBill) => void) {
    startPayAndListenForBillChangesGeneric(uid, billId, setPresentedBill, billToPresentedBill);
}

/**
 * 
 * Turns the URL + db attr into a bill, and then updates that bill with changes when needed!
 *
 * Call inside a useEffect hook to listen for bill changes.
 * May need to gate this to auth'd user. 
 * Should probably clean exit from this 
 * function after a while and force user to refresh
 * 
 */
export function startPayAndListenForBillChangesGeneric<T>(uid: string, billId: string, setPresentedBill: (a: T) => void, billToPresentedBillGeneric: (bill: Bill, attr: DBBillAttributes, users: { [uid: string]: User }) => T) {
    const users: { [uid: string]: User } = {};

    console.info('STEP: Listening to bill changes')

    getBillAttrFromDb(billId, (attr) => {
        // TODO: Will also need an error callback
        let bill = attr.bill;

        for (const [_, itemGroup] of Object.entries(bill.itemGroups)) {
            if (!itemGroup.payments) {
                itemGroup.payments = {};
            }
        }

        console.log('db bill', bill);
        setPresentedBill(billToPresentedBillGeneric(bill, attr, users));

        console.info('Step: Listening for diff events');

        listenForDiffEvents(billId, (timestampKey, diff) => {
            // This is called when a diff event comes in
            const x: DBDiffs = {};
            x[timestampKey] = diff;

            if (diff.uid && !(diff.uid in users)) {
                getUserFromDb(diff.uid,
                    (user) => {
                        const _user = unpartial(user, ['name'])
                        if ('name' in _user) {
                            users[diff.uid!] = _user;
                            billToPresentedBillGeneric(bill, attr, users)
                        }
                    }
                )
            }

            // console.info('Applying diff to bill ', diff.action, ' for item ', diff.itemGroupId, ' with quantity ', 'amount' in diff ? diff.amount : ('1' + ' (implied)'));
            // alert(['Applying diff to bill ', diff.action, ' for item ', diff.itemGroupId, ' with quantity ', 'amount' in diff ? diff.amount : ('1' + ' (implied)')].join(' '))

            if (diff.uid !== uid) {
                bill = applyDiffsFromDbToBill(bill, x);
            } else {
                // skip curent diff cause its the current user
            }

            // console.error(Object.keys(bill.itemGroups));

            setPresentedBill(billToPresentedBillGeneric(bill, attr, users));
        })
    })

}

/**
 * TODO: Probably here if cart is valid
 */
type itemGroupId = string;
export function postShoppingCartToDb(billId: string, cart: itemGroupId[], uidFrom: string, success?: () => void, failure?: (msg: string) => void) {
    const diffs = shoppingCartToDiffs(cart, Date.now(), uidFrom);
    postDiffsToBill(billId, diffs, (failedItems) => {
        if (failedItems.length === 0)
            success?.()
        else {
            failure?.('One or more items failed to post to the database. Try again.')
        }
    })

}

function billSubtotal(bill: PresentedBill): number {
    if (Object.values(bill.items).length >= 1) {
        return Object.values(bill.items ?? {}).map(x => x.price).reduce((acc, v) => acc + v);
    } else {
        // avoid error: "Uncaught TypeError: reduce of empty array with no initial value" 
        return 0;
    }
}

export function billTotal(bill: PresentedBill): number {
    const subtotal = billSubtotal(bill);
    return subtotal + (bill.tipRate * subtotal) + (bill.taxRate * subtotal);
}


export function calculateAdjustedPrice(bill: PresentedBill): PresentedBill {
    const priceMultiplier = 1 + ((bill.tipRate ?? 0) + (bill.taxRate ?? 0));

    return {
        ...bill,
        items: mergeMaps<PresentedBillItem>(Object.entries(bill.items ?? {})
            .map(([itemId, item]) => {
                return {
                    [itemId]: as<PresentedBillItem>({
                        ...item, adjustedPrice: item.price * priceMultiplier
                    })
                }
            })
        ),
    }

}