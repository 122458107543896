import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

export function FirstLoadShare(
    props: {
        isDialogOpen: boolean,
        onClose: () => void,
        type: 'share' | 'pay',
        cardholder: string
    }
) {
    let message: string;
    switch (props.type) {
        case 'share':
            message = "Is it someone's birthday? Assign assign their items now, and they'll be split between everyone!"
            break;
        case 'pay':
        default:
            message = `Select your food, and easily pay ${props.cardholder}!`
            break;
    }

    let title: string;
    switch (props.type) {
        case 'share':
            title = "Welcome to Birthday Mode! 🥳"
            break;
        case 'pay':
        default:
            title = `Welcome to Banana Split! 😁`
            break;
    }


    return <Dialog open={props.isDialogOpen} onClose={props.onClose}
        PaperProps={{
            sx: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: 'max(300px, 40%)',
            }
        }}
    >
        <DialogContent sx={{ marginBottom: -1 }}>
            <Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography fontWeight={600} fontSize={16}>{title}</Typography>
                </Box>
                <Box>
                    <Typography>{message}</Typography>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions sx={{ marginLeft: 'auto', marginRight: 1 }}>
            <Button variant='contained' sx={{ textTransform: 'none', marginBottom: 1 }} onClick={props.onClose}>
                <Typography>Let's go!</Typography>
            </Button>
        </DialogActions>


    </Dialog>
}