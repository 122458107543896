import { Box } from "@mui/material";
import { PresentedBillV2 } from "../../business_component_shared/Types";
import { PresentedBillV2View } from "./PresentedBill";

const presBillV2: PresentedBillV2 = {
    ownerUid: 'Michael A',
    paymentOptions: {
        paymentType: 'venmo',
        paymentUsername: 'Michael-Aspinwall',
        paymentUrl: undefined
    },
    taxAmount: 2.25,
    tipAmount: 2.50,
    timestamp: 4000,
    numDiners: 2,
    items: {
        'foo0': {
            adjustedPrice: 1.2,
            desc: 'A yummy yummy foo',
            price: 1,
            quantity: 2,
            uidsPaid: { 'James B': 1 },
            quantityInCart: 1,
        },
        'foo1': {
            adjustedPrice: 1.2,
            desc: 'Bean Steak Burrito Special',
            price: 1,
            quantity: 2,
            uidsPaid: {},
            quantityInCart: 0,
        },
        'foo2': {
            adjustedPrice: 1.3,
            desc: 'Salad',
            price: 1.1,
            quantity: 3,
            uidsPaid: { 'Michael A': 1, 'Ann G': 1 },
            quantityInCart: 1,
        },
        'foo3': {
            adjustedPrice: 1.3,
            desc: 'Fries',
            price: 2.75,
            quantity: 2,
            uidsPaid: { 'Ann G': 0.5 },
            quantityInCart: 0,
            isAppetizer: true,
        }
    },
    users: {
        'Michael A': { name: 'Mike Aspinwall' },
        'James B': { name: 'James Buzaid' },
    }
}

export function V2Example() {
    return (
        <Box className='foo'>
            <Box>
                <PresentedBillV2View
                    bill={presBillV2}
                    currentUid='James B'
                    type='share'
                    setBill={(a) => { }}
                ></PresentedBillV2View>
            </Box>
        </Box>
    )
}