// TODO: This is a bit of a mess. I think we should have a single ItemRow component that takes a type and then
// renders the appropriate children. This would make it easier to add new types and to add new children to
// existing types.

import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Divider, IconButton } from "@mui/material";
import { UserMinus, UserPlus } from "phosphor-react";
import React, { useState } from "react";
import useWindowDimensions, { displayNameToAvatar } from "../../../business_component_shared/helpers";
import { PresentedBillV2Item, User } from "../../../business_component_shared/Types";
import { ItemAvatars } from "./ItemAvatars";
import { ItemDesc } from "./ItemDesc";
import { ItemPrice } from "./ItemPrice";
import { ItemQuantity } from "./ItemQuantity";
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import { SnackbarItemChange } from '../UpdateSnackbars';
import { AddAppetizer, AddItem, AddItemContext } from './AddItem';
import { DashedDivider } from '../Components';

type baseItemRowTypes = 'cart' | 'unpaid' | 'paid' | 'notcart' | 'all' | 'birthday';

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     color: theme.palette.text.secondary,
// }));

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

function BaseItemRow(props: { item: PresentedBillV2Item, type: baseItemRowTypes }) {
    let itemDescType: "cart" | "notcart" | "birthday";
    switch (props.type) {
        case 'cart':
            itemDescType = 'cart';
            break;
        case 'birthday':
            itemDescType = 'birthday';
            break;
        default:
            itemDescType = 'notcart';
            break;
    }

    return <React.Fragment>
        <Grid xs={1} sm={1}>
            <ItemQuantity item={props.item} type={props.type} />
        </Grid>
        <Grid sm xs >
            <ItemDesc item={props.item} context={itemDescType} />
        </Grid>
    </React.Fragment>
}

// Formerly just UnpaidItemRow
export function UnpaidOrPaidItemRow(props: AddItemContext & {
    uidToUser: (uid: string) => User,
    removeItemFromShoppingCart: (uid: string) => void,
    isRemoveUsersMode?: boolean,
    onRemoveUserFromItem: (uid: string) => void,
    isBirthdayMode: boolean,
    isShareMode?: boolean,
    numDiners?: number,
}) {
    // NOTE: We are defaulting everything to an appetizer
    // const isAppetizer = !!props.item.isAppetizer;
    const isAppetizer = true;

    const type = isAppetizer ? 'appetizer' : 'regular';

    const { width } = useWindowDimensions();

    const itemAvatars = <ItemAvatars
        isRemoveUsersMode={props.isRemoveUsersMode}
        item={props.item}
        uidToUser={props.uidToUser}
        uid={props.uid}
        onRemoveFromCart={props.removeItemFromShoppingCart}
        onRemoveUserFromItem={(uid) => {
            props.onRemoveUserFromItem(uid);
        }}
        isShareMode={props.isShareMode}
    />


    return <React.Fragment>
        <Box>
            <Grid
                container
                // alignItems='center'
                spacing={4}
                minWidth={'100%'}
                width={'100%'}
                style={{ gap: 15 }}
                xs={12}
            >

                <BaseItemRow item={props.item} type={'all'} />
                {
                    width > 900 && <Grid xs={1} sm={3} md={3}>
                        {itemAvatars}
                    </Grid>

                }
                <Grid xs={4} sm={3}>
                    {type === 'regular' && <AddItem isDisabled={props.isRemoveUsersMode} item={props.item} addQuantityOfItemToShoppingCart={props.addQuantityOfItemToShoppingCart} payContext={props.payContext} isBirthdayMode={props.isBirthdayMode} />}
                    {type === 'appetizer' && <AddAppetizer isDisabled={props.isRemoveUsersMode} item={props.item} addQuantityOfItemToShoppingCart={props.addQuantityOfItemToShoppingCart} payContext={props.payContext} uidToUser={props.uidToUser} isBirthdayMode={props.isBirthdayMode} isShareMode={props.isShareMode} numDiners={props.numDiners} />}
                </Grid>
            </Grid>
            {
                width <= 900 && <Grid
                    container
                    // alignItems='center'
                    spacing={4}
                    minWidth={'100%'}
                    width={'100%'}
                    style={{ gap: 15 }}
                    xs={12}
                >
                    <Grid xs={12} sm={12}>
                        {itemAvatars}
                    </Grid>
                </Grid>
            }
        </Box>
        {
            // TODO: Let's get a better divider here, and honeslty maybe make
            // the main divider too a little better
        }
        <DashedDivider sx={{ my: 1 }} />
    </React.Fragment>
}
export function CartPreviewItemRow(props: { item: PresentedBillV2Item, type: 'cart' | 'notcart' }) {
    return <React.Fragment>
        <BaseItemRow item={props.item} type={props.type} />
        <Grid xs={3} sm={3}>
            <ItemPrice item={props.item} type={props.type} />
        </Grid>
    </React.Fragment>
}

// TODO: Pass in a component to render for the remove button since it also depends somewhat
// on if the item is an appetizer or not
export function CartItemRow(props: { item: PresentedBillV2Item, removeQuantityOfItemToShoppingCart: (q: number) => void }) {
    return <React.Fragment>
        <BaseItemRow item={props.item} type={'cart'} />
        <Grid xs={3} sm={3}>
            <ItemPrice item={props.item} type={'cart'} />
        </Grid>
        <Grid xs={1} sm={1}>
            <IconButton onClick={() => props.removeQuantityOfItemToShoppingCart(props.item.quantityInCart ?? 0)} sx={{ padding: '5px', margin: 0 }}>
                <UserMinus />
            </IconButton>
        </Grid>
    </React.Fragment>
}

export function BirthdayItemRow(props: { item: PresentedBillV2Item, removeQuantityOfItemToBirthday: (q: number) => void }) {
    return <React.Fragment>
        <BaseItemRow item={props.item} type={'birthday'} />
        <Grid xs={3} sm={3}>
            <ItemPrice item={props.item} type={'birthday'} />
        </Grid>
        <Grid xs={1} sm={1}>
            <IconButton onClick={() => props.removeQuantityOfItemToBirthday(props.item.birthdayQuantity ?? 0)} sx={{ padding: '5px', margin: 0 }}>
                <UserMinus />
            </IconButton>
        </Grid>
    </React.Fragment>
}


// export function PaidItemRow(props: { item: PresentedBillV2Item, uidToUser: (uid: string) => User }) {
//     return <React.Fragment>
//         <BaseItemRow item={props.item} type={'paid'} />
//         <Grid marginLeft='auto'>
//             <ItemAvatars item={props.item} uidToUser={props.uidToUser} />
//         </Grid>
//     </React.Fragment>
// }